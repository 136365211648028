import { Button, Select, Transfer } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
// import { getContactName } from "../campaigns/sendTemplates/utils/getContactName";
// import { Tag } from "../../tag/Tag";
import { updateEnvioMasivo } from "../../../../helpers/fetchData/fetchEnviosMasivos";
import { ContactFilters } from "../../contactFilters/ContactFilters";

export const EnviosSubscriptions = ({
  drawerData,
  setDrawerData,
  setEnviosMasivos,
}) => {
  const { contactos, etiquetas, messageApi, instance } =
    useContext(GlobalContext);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    clients: [],
    contacts: [],
    tags: [],
    excludedClients: [],
    excludedContacts: [],
    excludedTags: [],
  });

  // const onChangeTransfer = (newTargetKeys, direction, moveKeys) => {
  //   setTargetKeys(newTargetKeys);
  // };

  // const renderItem = (item) => {
  //   return {
  //     label: item.fullName,
  //     value: item._id,
  //   };
  // };

  const guardarSuscripciones = () => {
    setSelectedTag(null);

    let data = {
      ...drawerData.item,
      contacts: targetKeys,
      filters: filters,
    };

    setLoading(true);
    updateEnvioMasivo(instance?.userData?.apiKey, drawerData.item?._id, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Suscripciones guardadas correctamente");
          setEnviosMasivos((prevState) => {
            return prevState?.map((element) => {
              if (element._id === drawerData.item._id) {
                return { ...element, contacts: targetKeys, filters: filters };
              } else {
                return element;
              }
            });
          });
          setDrawerData({ open: false, item: null });
        } else {
          messageApi.error("Ocurrió un error al guardar suscripciones");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al guardar suscripciones");
      })
      .finally(() => setLoading(false));
  };

  // const formattedContacts = useMemo(() => {
  //   if (!contactos || contactos.length < 1) {
  //     return [];
  //   }

  //   let formatted = contactos.map((element) => {
  //     element.fullName = getContactName(element.name);

  //     return element;
  //   });

  //   if (selectedTag) {
  //     formatted = formatted.filter((contacto) => {
  //       if (contacto?.tags && contacto.tags?.includes(selectedTag)) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //   }

  //   return formatted;
  // }, [contactos, selectedTag]);

  // const tagsOptions = useMemo(() => {
  //   if (etiquetas?.length < 1) return [];

  //   return etiquetas.map((element) => {
  //     return {
  //       value: element._id,
  //       label: (
  //         <span
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             height: "100%",
  //             position: "relative",
  //           }}
  //         >
  //           <Tag hex={element.color} nombre={element.name} />
  //         </span>
  //       ),
  //     };
  //   });
  // }, [etiquetas]);

  useEffect(() => {
    if (drawerData.item?.filters) {
      setFilters(drawerData.item.filters);
    }
  }, [drawerData]);

  return (
    <div className="form-nodos" style={{ gap: 16 }}>
      <p style={{ fontWeight: 600, fontSize: 16 }}>{drawerData.item?.name}</p>
      {/* <div className="asignar-header-wrapper">
        <p style={{ fontWeight: 600 }}>Administrar suscripciones</p>
      </div> */}
      <ContactFilters filters={filters} setFilters={setFilters} />
      {/* <Select
        options={tagsOptions || []}
        value={selectedTag}
        onChange={(v) => setSelectedTag(v)}
        placeholder="Filtre contactos por sus etiquetas"
        allowClear
      />
      <Transfer
        style={{ width: "100%" }}
        dataSource={formattedContacts || []}
        targetKeys={targetKeys}
        filterOption={filterOption}
        onChange={onChangeTransfer}
        render={renderItem}
        titles={["Contactos", "Seleccionados"]}
        showSelectAll
        listStyle={{
          width: "calc(50% - 20px)",
          height: "calc(100vh - 220px)",
          minHeight: "400px",
        }}
        rowKey={(record) => record._id}
        showSearch={true}
        // pagination={{ pageSize: 15, simple: false }}
        // showSelectAll={true}
        locale={{
          itemUnit: "",
          itemsUnit: "",
          searchPlaceholder: "Buscar",
        }}
      /> */}
      <span className="form-custom-footer" style={{ marginBottom: 12 }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            className="btn-guardar"
            size="large"
            loading={loading}
            onClick={guardarSuscripciones}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() =>
              setDrawerData({
                open: false,
                item: null,
              })
            }
          >
            Cancelar
          </Button>
        </div>
      </span>
    </div>
  );
};
