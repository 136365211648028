import { Tooltip } from 'antd';
import { BoxInfo } from '../../../../box-info/BoxInfo';
import { ImWhatsapp } from 'react-icons/im';
import { FiEdit3 } from 'react-icons/fi';

export const DefaultBot = ({ flowData, setShowDrawer }) => {
  return (
    <div className='DefaultBot'>
      <div className='CardBot'>
        <div className='card-bot-first-container default-bot-first-container'>
          <div className='card-bot-first-row default-bot-first-row'>
            <div className='horizontal gap8' style={{ cursor: 'pointer' }}>
              <ImWhatsapp
                style={{ color: 'var(--whatsapp-color)' }}
                size={20}
              />
              <span className='card-bot-name default-bot-name'>
                {flowData?.name}
              </span>
            </div>

            <Tooltip title='Cambiar'>
              <FiEdit3
                style={{ cursor: 'pointer' }}
                size={20}
                onClick={() => setShowDrawer(true)}
              />
            </Tooltip>
          </div>

          <p className='card-bot-description'>{flowData?.description}</p>
        </div>
      </div>
      <BoxInfo
        message={
          'Este bot se ejecutará en caso de que se inicie una conversación con Whatsapp sin utilizar alguna de las palabras claves.'
        }
      />
    </div>
  );
};
