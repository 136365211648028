import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { BoxInfo } from "../../../../box-info/BoxInfo";
import { Button } from "antd";
import { enableDisableCloudStorage } from "../../../../../../helpers/fetchData/fetchChats";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const CloudStorage = () => {
  const { application, messageApi, instance, setApplication } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const handleEnableDisable = (value) => {
    setLoading(true);

    enableDisableCloudStorage(instance?.userData?.apiKey, { value })
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Almacenamiento dedicado actualizado");
          setApplication((prevState) => {
            return [{ ...prevState?.[0], cloudStorageEnabled: value }];
          });
        } else {
          messageApi.error(
            "Ocurrió un error al actualizar el almacenamiento dedicado"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error(
          "Ocurrió un error al actualizar el almacenamiento dedicado"
        );
      })
      .finally(() => setLoading(false));
  };

  if (application?.[0]?.cloudStorageEnabled) {
    return (
      <div className="columna gap16" style={{ maxWidth: 600 }}>
        <span className="horizontal-between">
          <b>Está habilitado el almacenamiento dedicado.</b>
          <Button
            className="btn-oscuro"
            type="primary"
            onClick={() => handleEnableDisable(false)}
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Deshabilitar
          </Button>
        </span>
        <div style={{ width: "100%" }}>
          <BoxInfo
            message={[
              "Los archivos del chat (audios, imágenes, videos y documentos) se almacenan de manera permanente desde el momento en que esta opción fue habilitada.",
            ]}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="columna gap16" style={{ maxWidth: 600 }}>
      <span className="horizontal-between">
        <b>No se ha habilitado el almacenamiento dedicado.</b>
        <Button
          className="btn-oscuro"
          type="primary"
          onClick={() => handleEnableDisable(true)}
          //loading={loading}
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          Habilitar
        </Button>
      </span>
      <div style={{ width: "100%" }}>
        <BoxInfo
          message={[
            "Si necesita o prefiere mantener los archivos (audios, imágenes, videos y documentos) que se comunican en los chats, recomendamos habilitar el almacenamiento dedicado.",
            "De lo contrario los archivos serán descartados por Meta pasados los 30 días y no podrán ser recuperados.",
            "Tenga en cuenta que el costo de almacenamiento varía según su uso.",
          ]}
        />
      </div>
    </div>
  );
};
