import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar
} from 'recharts';
import { COLORES_PIE } from '../columns';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { datePickerFormat } from '../utils';

export const ButtonsBarGraph = ({ data, datePickerType }) => {
  const [formattedData, setFormattedData] = useState([]);

  const [barsFormat, setBarsFormat] = useState({});

  // const formatter = (value, name) => {
  //   return [value, name];
  // };

  useEffect(() => {
    if (data.length > 0) {
      let outputData = {};

      let format = datePickerFormat.date;

      switch (datePickerType) {
        case 'month':
          format = datePickerFormat.week;
          break;
        case 'week':
          format = datePickerFormat.date;
          break;
        case 'date':
          format = datePickerFormat.hour;
          break;
        default:
          break;
      }

      data.forEach((button) => {
        button.interactions?.forEach((interaction) => {
          const fecha = dayjs(interaction.timestamp * 1000).format(format);

          if (!outputData[fecha]) {
            outputData[fecha] = [];
          }

          outputData[fecha].push({
            button: button.button
          });
        });
      });

      const finalOutput = Object.keys(outputData).map((button) => {
        let values = outputData[button];

        let conteo = {};

        values.forEach((valor) => {
          if (!conteo[valor.button]) {
            conteo[valor.button] = [];
          }

          conteo[valor.button].push(valor.button);
        });

        const resultado = {};

        Object.keys(conteo).forEach((key) => {
          resultado[key] = conteo[key]?.length;
        });

        return { fecha: button, ...resultado };
      });

      setFormattedData(finalOutput);

      let barsFormat = { ...finalOutput[0] };
      delete barsFormat.fecha;

      setBarsFormat(barsFormat);
    }
  }, [data]);

  return (
    <ResponsiveContainer width='99%' height='100%'>
      <ComposedChart
        data={formattedData || []}
        margin={{
          top: 24,
          right: 24,
          bottom: 12,
          left: 24
        }}
      >
        <CartesianGrid stroke='#f5f5f5' />
        <XAxis
          dataKey='fecha'
          // tickFormatter={(label) => customXAxis(label)}
          style={{ fontWeight: '600', fontSize: 13 }}
        />
        <YAxis
          tickFormatter={(v) =>
            `${Number(v)?.toLocaleString('de-DE', {
              maximumFractionDigits: 0
            })}`
          }
          style={{ fontWeight: '600', fontSize: 13 }}
        />
        <Tooltip
          labelClassName='custom-tooltip-label'
          wrapperClassName='custom-tooltip-wrapper'
          // formatter={formatter}
        />
        {Object.keys(barsFormat || {})?.map((barra, index) => {
          return (
            <Bar
              dataKey={barra}
              barSize={40}
              fill={COLORES_PIE[index]}
              key={barra}
            />
          );
        })}
        <Legend
          iconSize={10}
          payload={Object.keys(barsFormat || {})?.map((barra, index) => {
            return {
              value: barra,
              id: barra,
              color: COLORES_PIE[index],
              type: 'rect'
            };
          })}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
