import { Button, Result } from "antd";
import { useContext, useState } from "react";
import { AiOutlineApi } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const SinInstancia = () => {
  const { messageApi, setInstance, workspaces, setWorkspaces } =
    useContext(GlobalContext);
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);

  const sendMail = () => {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/draftWorkspace`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email: user.email },
    };

    setLoading(true);
    axios(config)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Enviamos un mail con el código de validación");
          setWorkspaces((prevState) => {
            return {
              ...prevState,
              draft: { ...res?.data?.data },
            };
          });
          setInstance({
            status: "to be approved",
            ...res.data?.data,
          });
        } else {
          messageApi.error("No se pudo validar el código");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al registrar un workspace");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Result
        style={{ padding: 8 }}
        icon={<AiOutlineApi size={56} color="var(--primary-color)" />}
        title={
          workspaces?.instance?.length > 0
            ? "¿Desea crear otro workspace?"
            : "No existe workspace creado"
        }
        subTitle={
          workspaces?.instance?.length > 0
            ? ""
            : "Es necesario crear uno para utilizar la plataforma"
        }
        extra={[
          <div
            className="horizontal gap8"
            style={{ justifyContent: "center" }}
            key={0}
          >
            <Button
              type="primary"
              key="console"
              className="btn-agregar"
              onClick={() => sendMail()}
              loading={loading}
            >
              {!loading && <FiPlus color="white" size={18} />}
              Workspace
            </Button>
            <Button onClick={() => setInstance({})}>Volver</Button>
          </div>,
        ]}
      />
    </div>
  );
};
