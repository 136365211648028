import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Button, Popover, Tooltip } from "antd";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { deleteToBlacklist } from "../../../../../helpers/fetchData/fetchBlacklist";
import { getContactName } from "../../campaigns/sendTemplates/utils/getContactName";

export const DesbloquearContacto = ({ item }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Tooltip title="Desbloquear">
      <span>
        <Popover
          open={showPopover}
          content={
            <PopoverDesbloquear item={item} setShowPopover={setShowPopover} />
          }
        >
          <span>
            <IoMdCheckmarkCircleOutline
              className="icono-acciones"
              onClick={() => setShowPopover(true)}
            />
          </span>
        </Popover>
      </span>
    </Tooltip>
  );
};

const PopoverDesbloquear = ({ item, setShowPopover }) => {
  const { messageApi, instance, setContactos } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  function desbloquear() {
    deleteToBlacklist(
      item,
      instance,
      messageApi,
      setContactos,
      setLoading,
      setShowPopover
    );
  }

  return (
    <span className="columna" style={{ maxWidth: 280 }}>
      <p>{`¿Está seguro de desbloquear a ${getContactName(item.name)}?`}</p>
      <span className="horizontal">
        <Button
          type="primary"
          className="btn-borrar"
          onClick={desbloquear}
          loading={loading}
        >
          Confirmar
        </Button>
        <Button
          type="secondary"
          className="btn-cancelar"
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </span>
    </span>
  );
};
