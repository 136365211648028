import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //Descomentar lo de abajo
  <Auth0Provider
    cacheLocation='localstorage'
    useRefreshTokens={true}
    domain={process.env.REACT_APP_DOMAIN}
    clientId={process.env.REACT_APP_CLIENTID}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
  >
    <App />
  </Auth0Provider>
);
