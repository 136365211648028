import { Button, Form, Image, Input, Modal, Select, Spin, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { getBucketResources } from "../../../../../../helpers/fetchData/fetchBucketResources";
import { updateProduct } from "../../../../../../helpers/fetchData/fetchProducts";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { FiPlus } from "react-icons/fi";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { NewImagen } from "./NewImagen";
import { actualizarUpdateProduct } from "../catalog/utils/actualizarListas";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import {
  getCurrencyByList,
  handlePriceByList,
} from "../catalog/utils/getCurrencyByList";
import { UNIDADES_MEDIDA } from "../../../../../../helpers/constantes";
import { config } from "dotenv";

export const UpdateProduct = () => {
  const { instance, configDrawerContent, setConfigDrawerContent, messageApi } =
    useContext(GlobalContext);
  const { catalogDetail, setCatalogDetail, recursos, setRecursos } =
    useContext(SettingsContext);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingImagenes, setLoadingImagenes] = useState(false);

  const [switchImagenPropia, setSwitchImagenPropia] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [switchPrice, setSwitchPrice] = useState(true);

  const [selectedListId, setSelectedListId] = useState("sin lista");

  const [pricesByList, setPricesByList] = useState([]);

  const [nuevaImagen, setNuevaImagen] = useState({
    visible: false,
    addedNew: false,
    data: null,
  });

  const onFinish = (v) => {
    if (switchImagenPropia && !selectedImage) {
      return messageApi.info("Es necesario que seleccione una imagen");
    }

    v.image_link = v.image_link?.trim();

    if (!switchImagenPropia && !v.image_link) {
      return messageApi.info("Es necesario que ingrese una URL para la imagen");
    }

    v.price = Number(v.price);
    if (Number.isNaN(v.price)) {
      return messageApi.info("Ingrese un precio válido");
    }

    v.price = v.price.toFixed(2).replace(".", ",");

    let image_link = "";
    let imageId = null;

    if (switchImagenPropia) {
      image_link = recursos?.find(
        (element) => element._id === selectedImage
      )?.image_url;

      imageId = selectedImage;
    } else {
      image_link = v.image_link || "";
    }

    if (!switchPrice) {
      if (!v.unit) {
        return messageApi.info(
          "Es necesario que seleccione una unidad de medida"
        );
      }
    }

    const { _id, id } = configDrawerContent.item || {};

    let data = {
      id: id,
      sku: v.sku,
      title: v.name,
      description: v.description,
      availability: v.availability,
      condition: v.condition,
      price: v.price,
      imageId: imageId,
      image_link: image_link,
      brand: v.brand,
      unit: v.unit,
      pricesByList:
        pricesByList.map((element) => {
          return {
            listId: element.listId,
            price: Number(element.price)?.toFixed(2).replace(".", ","),
          };
        }) || [],
    };

    let productData = {
      productId: _id,
      product: data,
    };

    setLoading(true);

    updateProduct(instance?.userData?.apiKey, productData)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Se actualizó un artículo correctamente");

          setCatalogDetail((prevState) => {
            let obj = { ...prevState };

            let products = obj.products.map((producto) => {
              if (producto._id === _id) {
                return {
                  _id: _id,
                  ...data,
                };
              } else {
                return producto;
              }
            });

            obj.products = products;

            return obj;
          });

          setConfigDrawerContent({ visible: false, content: "", item: null });
        } else {
          messageApi.error("Ocurrió un error al actualizar un artículo");
        }
      })
      .catch((e) => {
        messageApi.error("Ocurrió un error al actualizar un artículo");
      })
      .finally(() => setLoading(false));
  };

  const handleImagesLoading = () => {
    setLoadingImagenes(true);
    getBucketResources(instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          setRecursos(res.data);
        } else {
          messageApi.error("Ocurrió un error al cargar imágenes");
        }
      })
      .catch((error) => messageApi.error("Ocurrió un error al cargar imágenes"))
      .finally(() => setLoadingImagenes(false));
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      if (recursos.length < 1) {
        handleImagesLoading();
      }
    }
  }, [instance]);

  useEffect(() => {
    if (nuevaImagen?.addedNew) {
      setRecursos((prevState) => [...prevState, nuevaImagen.data]);
      setSelectedImage(nuevaImagen.data?._id);
    }
  }, [nuevaImagen]);

  useEffect(() => {
    if (configDrawerContent?.item) {
      const { item } = configDrawerContent;

      if (item.imageId == null && item.image_link) {
        setSwitchImagenPropia(false);
      }

      if (item.unit) {
        setSwitchPrice(false);
      }

      form.setFieldsValue({
        sku: item.sku,
        name: item.title,
        description: item.description,
        condition: item.condition,
        price: item.price
          ? item.price.trim()?.split(" ")?.[0]?.replace(",", ".")
          : "",
        brand: item.brand,
        image_link: item.image_link,
        availability: item.availability,
        unit: item.unit || "",
      });

      setSelectedListId(item.listId ? item.listId : "sin lista");

      setSelectedImage(item.imageId || null);

      const listas = catalogDetail.lists.filter((element) =>
        element.products.includes(item.id)
      );

      const savedPricesLists = item.pricesByList;

      setPricesByList(
        listas.map((element) => {
          const price =
            savedPricesLists?.find((lista) => {
              if (lista.listId === element._id) {
                return true;
              }

              return false;
            })?.price || "0";

          return {
            listId: element._id,
            name: element.name,
            price: parseFloat(price),
          };
        })
      );
    }
  }, [configDrawerContent, recursos]);

  return (
    <Form
      form={form}
      name="newProduct"
      layout="vertical"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Editar producto</p>
      <Form.Item
        name="sku"
        label="SKU"
        rules={[{ required: true, message: "El campo es obligatorio" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        label="Nombre"
        rules={[{ required: true, message: "El campo es obligatorio" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Descripción">
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
      <span className="horizontal">
        <Form.Item
          name="availability"
          style={{ width: "50%" }}
          label="Disponibilidad"
          rules={[{ required: true, message: "El campo es obligatorio" }]}
        >
          <Select
            options={[
              { value: "in stock", label: "En stock" },
              { value: "out of stock", label: "Sin stock" },
              { value: "discontinued", label: "Discontinuado" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="condition"
          style={{ width: "50%" }}
          label="Condición"
          rules={[{ required: true, message: "El campo es obligatorio" }]}
        >
          <Select
            options={[
              { value: "new", label: "Nuevo" },
              { value: "refurbished", label: "Reacondicionado" },
              { value: "used", label: "Usado" },
            ]}
          />
        </Form.Item>
      </span>
      {switchImagenPropia ? (
        <Form.Item
          name="imageId"
          label={
            <span className="horizontal">
              <p>Imagen</p>
              <Switch
                checkedChildren="Propia"
                unCheckedChildren="Externa"
                checked={switchImagenPropia}
                onChange={(v) =>
                  setSwitchImagenPropia((prevState) => !prevState)
                }
                style={{width: "fit-content"}}
              />
            </span>
          }
          // rules={[{ required: true, message: "El campo es obligatorio" }]}
        >
          {loadingImagenes ? (
            <span style={{ display: "flex" }}>
              <Spin
                style={{ marginInline: "auto" }}
                indicator={<LoadingIcon size="large" />}
              />
            </span>
          ) : (
            <span className="horizontal">
              <Select
                size="large"
                options={
                  recursos?.length > 0
                    ? recursos.map((element) => {
                        return {
                          value: element._id,
                          label: (
                            <span className="horizontal">
                              <Image
                                width={38}
                                height={38}
                                src={element.image_url}
                                preview={false}
                              />
                              <p>{element.name}</p>
                            </span>
                          ),
                        };
                      })
                    : []
                }
                value={selectedImage}
                onChange={(v) => setSelectedImage(v)}
                showSearch
                filterOption={(input, option) =>
                  (
                    recursos.find((element) => option.value === element._id)
                      ?.name ?? ""
                  )
                    ?.toLowerCase()
                    ?.includes(input.toLowerCase())
                }
              />
              <Button
                type="primary"
                size="large"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 15px",
                }}
                onClick={() =>
                  setNuevaImagen({ visible: true, addedNew: false, data: null })
                }
              >
                <FiPlus size={18} />
              </Button>
            </span>
          )}
        </Form.Item>
      ) : (
        <Form.Item
          label={
            <span className="horizontal">
              <p>Imagen</p>
              <Switch
                checkedChildren="Propia"
                unCheckedChildren="Externa"
                checked={switchImagenPropia}
                onChange={(v) =>
                  setSwitchImagenPropia((prevState) => !prevState)
                }
                style={{width: "fit-content"}}
              />
            </span>
          }
          name="image_link"
        >
          <Input placeholder="Ingrese una URL" />
        </Form.Item>
      )}
      {!switchPrice ? (
        <span className="horizontal">
          <Form.Item
            name="price"
            label={
              <span className="horizontal">
                <p>Precio base</p>
                <Switch
                  checkedChildren="Por embase"
                  unCheckedChildren="Por unidad"
                  checked={switchPrice}
                  onChange={(v) => setSwitchPrice((prevState) => !prevState)}
                  style={{width: "fit-content"}}
                />
              </span>
            }
            style={{ width: "calc(60% - 4px)" }}
            rules={[{ required: true, message: "El campo es obligatorio" }]}
          >
            <Input
              type="number"
              addonAfter={getCurrencyByList(catalogDetail, selectedListId)}
            />
          </Form.Item>
          <Form.Item
            name="unit"
            label="Unidad de medida"
            style={{ width: "calc(40% - 4px)" }}
            initialValue={"l"}
            // rules={[{ required: true, message: "El campo es obligatorio" }]}
          >
            <Select options={UNIDADES_MEDIDA} />
          </Form.Item>
        </span>
      ) : (
        <span className="horizontal">
          <Form.Item
            name="price"
            label={
              <span className="horizontal">
                <p>Precio base</p>
                <Switch
                  checkedChildren="Por embase"
                  unCheckedChildren="Por unidad"
                  checked={switchPrice}
                  onChange={(v) => setSwitchPrice((prevState) => !prevState)}
                  style={{width: "fit-content"}}
                />
              </span>
            }
            style={{ width: "calc(60% - 4px)" }}
            rules={[{ required: true, message: "El campo es obligatorio" }]}
          >
            <Input
              type="number"
              addonAfter={getCurrencyByList(catalogDetail, selectedListId)}
            />
          </Form.Item>
        </span>
      )}
      {pricesByList?.length > 0 && (
        <Form.Item label="Precios por lista">
          {pricesByList.map((row, index) => {
            return (
              <span className="horizontal" key={index}>
                <span
                  style={{
                    width: "calc(60% - 4px)",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <p>{row.name}</p>
                </span>
                <Input
                  style={{ width: "calc(40% - 4px)" }}
                  type="number"
                  value={row.price}
                  onChange={(v) =>
                    handlePriceByList(setPricesByList, index, v.target.value)
                  }
                />
              </span>
            );
          })}
        </Form.Item>
      )}
      <Form.Item
        name="brand"
        label="Marca"
        rules={[{ required: true, message: "El campo es obligatorio" }]}
      >
        <Input />
      </Form.Item>
      <Modal
        open={nuevaImagen.visible}
        footer={null}
        width={450}
        destroyOnClose
        closeIcon={
          <CloseOutlined
            onClick={() =>
              setNuevaImagen({ visible: false, addedNew: false, data: null })
            }
          />
        }
      >
        <NewImagen modal={true} setNuevaImagen={setNuevaImagen} />
      </Modal>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setConfigDrawerContent({
                visible: false,
                content: "",
                item: null,
              });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
