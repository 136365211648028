import { Button, Popover, Radio, Spin, Tag, Tooltip } from "antd";
import { useContext, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import { TbTrash } from "react-icons/tb";
import { RiListRadio } from "react-icons/ri";
import dayjs from "dayjs";
import { BsFiletypePdf } from "react-icons/bs";
import {
  downloadPDF,
  getPDFPresupuesto,
} from "../../../helpers/fetchData/getPDFPresupuesto";
import { GlobalContext } from "../../context/GlobalContext";
import { updateBudgetStatus } from "../helpers/sendPresupuesto";
import { accessDataFormat } from "../../../helpers/access";
import { useAccess } from "../../../hooks/useAccess";
import LoadingIcon from '../../ui/Loader/LoadingIcon';

const PresupuestosAcciones = ({
  item,
  setPresupuestoDrawer,
  setPresupuestos,
}) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);

  const [showStatusChange, setShowStatusChange] = useState(false);

  const [status, setStatus] = useState(item.status?.toLowerCase() || "pending");

  const [loading, setLoading] = useState(false);

  const [loadingPDF, setLoadingPDF] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState(false);

  const isAllowed = useAccess();

  const handlePDFPresupuesto = () => {
    setLoadingPDF(true);

    getPDFPresupuesto(item)
      .then((res) => {
        try {
          if (!res.data) {
            messageApi.error("Ocurrió un error al crear el pdf");
            return;
          }
          downloadPDF(res.data);
        } catch (error) {
          messageApi.error("Ocurrió un error al crear el pdf");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoadingPDF(false));
  };

  const handleEditPresupuesto = () => {
    setPresupuestoDrawer({
      visible: true,
      stage: "home",
      edit: true,
      data: item,
    });
  };

  const handleStatusChange = () => {
    if (instance?.userData?.apiKey) {
      const data = {
        budgetId: item._id,
        newStatus: status,
      };

      setLoadingStatus(true);

      updateBudgetStatus(instance.userData.apiKey, data)
        .then((res) => {
          console.log(res);

          if (res?.status === 200) {
            setPresupuestos((prevState) => {
              let array = [...prevState];
              let index = prevState.findIndex(
                (element) => element._id === item._id
              );

              if (index >= 0) {
                array[index].status = data.newStatus;
              }

              return array;
            });
            setShowStatusChange(false);
          } else {
            messageApi.error(
              "Ocurrió un error al actualizar estado del presupuesto"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error(
            "Ocurrió un error al actualizar estado del presupuesto"
          );
        })
        .finally(() => setLoadingStatus(false));
    } else {
      messageApi.error("No hay apiKey");
    }
  };

  return (
    isAllowed(accessDataFormat("budgets", "manage")) && (
      <span
        className="horizontal gap8"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Tooltip title="Editar">
          <span>
            <FiEdit3
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => handleEditPresupuesto()}
            />
          </span>
        </Tooltip>
        <Tooltip title="Exportar PDF">
          <span>
            {loadingPDF ? (
              <LoadingIcon size="default" />
            ) : (
              <BsFiletypePdf
                color="#cc4b4c"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => handlePDFPresupuesto()}
              />
            )}
          </span>
        </Tooltip>
        <span>
          <Popover
            open={showStatusChange}
            content={
              <div className="columna">
                <b>Selecciona un estado</b>
                <span style={{ marginBottom: 12 }}>
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={[
                      { value: "pending", label: "Pendiente" },
                      { value: "approved", label: "Aprobado" },
                      { value: "unapproved", label: "No aprobado" },
                    ]}
                    value={status}
                    onChange={(v) => setStatus(v.target.value)}
                  />
                </span>
                <span className="horizontal">
                  <Button
                    type="primary"
                    className="btn-guardar"
                    onClick={() => handleStatusChange()}
                    //loading={loadingStatus}
                    icon={loadingStatus ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                    style={{ opacity: loadingStatus ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    type="secondary"
                    className="btn-cancelar"
                    onClick={() => setShowStatusChange(false)}
                  >
                    Cancelar
                  </Button>
                </span>
              </div>
            }
          >
            <span>
              <Tooltip title="Cambiar estado">
                <span>
                  <RiListRadio
                    size={16}
                    onClick={() => setShowStatusChange(true)}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Tooltip>
            </span>
          </Popover>
        </span>
        <span>
          <Popover
            open={showPopover}
            content={
              <div className="columna">
                <p>¿Está seguro de borrar esta órden?</p>
                <span className="horizontal">
                  <Button
                    type="primary"
                    className="btn-borrar"
                    // onClick={() => handleDeleteResource()}
                    //loading={loading}
                    icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                    style={{ opacity: loading ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    type="secondary"
                    className="btn-cancelar"
                    onClick={() => setShowPopover(false)}
                  >
                    Cancelar
                  </Button>
                </span>
              </div>
            }
          >
            <span>
              <Tooltip title="Eliminar">
                <span>
                  <TbTrash
                    size={16}
                    onClick={() => setShowPopover(true)}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Tooltip>
            </span>
          </Popover>
        </span>
      </span>
    )
  );
};

const getTotalPresupuesto = (item) => {
  if (item.payload?.length > 0) {
    const total = item.payload.reduce((total, item) => {
      let precio = parseFloat(item.item.price.replace(",", "."));

      if (Number.isNaN(precio) || !item.count) {
        return total;
      } else {
        return total + precio * item.count;
      }
    }, 0);

    return `${total.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} USD`;
  }

  return "0,00 USD";
};

const getStatusData = (name) => {
  switch (name?.toLowerCase()) {
    case "approved":
      return {
        name: "Aprobado",
        color: "green",
      };
    case "unapproved":
      return {
        name: "No aprobado",
        color: "volcano",
      };
    default:
      return {
        name: "Pendiente",
        color: "geekblue",
      };
  }
};

const getDate = (timestamp) => {
  if (timestamp) {
    return dayjs(timestamp, "x")?.format("DD/MM/YYYY");
  }
};

export const presupuestosColumns = (setPresupuestoDrawer, setPresupuestos) => {
  return [
    {
      title: "Cliente",
      dataIndex: "cli_nombre",
      key: "cli_nombre",
    },
    {
      title: "Teléfono",
      dataIndex: "phonenumber",
      key: "phonenumber",
      align: "center",
      width: 140,
    },
    {
      title: "Creación",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 140,
      render: (data) => getDate(data),
    },
    {
      title: "Vencimiento",
      dataIndex: "vencimiento",
      key: "vencimiento",
      align: "center",
      width: 140,
      render: (data) => getDate(data),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 140,
      render: (data, item) => {
        let status = getStatusData(data);

        return <Tag color={status.color}>{status.name}</Tag>;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      width: 180,
      render: (data, item) => getTotalPresupuesto(item),
    },
    {
      title: "...",
      align: "center",
      width: 140,
      dataIndex: "",
      key: "",
      render: (data, item) => (
        <PresupuestosAcciones
          item={item}
          setPresupuestoDrawer={setPresupuestoDrawer}
          setPresupuestos={setPresupuestos}
        />
      ),
    },
  ];
};
