import { Button, DatePicker, Form, Input, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../context/GlobalContext';
import LoadingIcon from '../../../../Loader/LoadingIcon';
import dayjs from 'dayjs';
import {
  getContactByEmail,
  upsertUserContact
} from '../../../../../../helpers/fetchData/fetchUsers';

export const EditUser = ({ roles, setDrawerData, drawerData, setUsuarios }) => {
  const { instance, messageApi, setContactos } = useContext(GlobalContext);

  const [selectedRol, setSelectedRol] = useState(null);
  const [loadingEditar, setLoadingEditar] = useState(false);
  const [loadingDataContacto, setLoadingDataContacto] = useState(true);

  const [contactData, setContactData] = useState(null);

  const [form] = Form.useForm();

  const actualizarLista = (data) => {
    setContactos((prevState) => {
      let array = [...prevState];

      const index = array.findIndex((contacto) => contacto._id === data._id);

      if (index >= 0) {
        array[index] = { ...array[index], ...data };
      } else {
        array.push({ ...data });
      }

      return array;
    });

    setDrawerData({ open: false, data: null });
  };

  const handleEdit = (v) => {
    if (!selectedRol) {
      messageApi.info('Es necesario que seleccione un rol');
      return;
    }

    let data = {
      addresses: [
        {
          country: v.country?.trim() || '',
          state: v.state?.trim() || '',
          city: v.city?.trim() || '',
          street: v.street?.trim() || '',
          zip: v.zip?.trim() || '',
          type: v.type || 'home'
        }
      ],
      birthday: v.birthday ? dayjs(v.birthday).format('DD/MM/YYYY') : '',
      emails: [{ email: drawerData?.data?.email?.trim() || '', type: 'WORK' }],
      name: {
        first_name: v.first_name?.trim() || '',
        last_name: v.last_name?.trim() || '',
        middle_name: v.middle_name?.trim() || ''
      },
      org: {
        company: v.company?.trim() || '',
        department: v.department?.trim() || '',
        title: v.title?.trim() || ''
      },
      phones: [{ type: 'WORK', phone: v.phone?.trim() || '' }],
      tags: contactData?.tags || [],
      userId: drawerData.data?._id,
      roleId: selectedRol
    };

    setLoadingEditar(true);
    upsertUserContact(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success('Usuario actualizado correctamente');
          setUsuarios((prevState) => {
            let array = prevState.map((usuario) => {
              if (usuario._id === drawerData.data?._id) {
                usuario.association = {
                  roleId: selectedRol,
                  name: roles.find((rol) => rol._id === selectedRol)?.name
                };
              }

              return usuario;
            });

            return array;
          });
          actualizarLista({
            ...contactData,
            _id: res.data?.upsertedId || contactData._id,
            ...data
          });
          setDrawerData({ open: false, data: null });
        } else {
          messageApi.error('Ocurrió un error al editar un contacto');
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error('Ocurrió un error al editar un contacto');
      })
      .finally(() => setLoadingEditar(false));
  };

  useEffect(() => {
    if (drawerData.data?.association?.roleId) {
      setSelectedRol(drawerData.data.association.roleId);
    }
  }, [drawerData]);

  useEffect(() => {
    if (instance?.userData?.apiKey && drawerData?.data?.email) {
      setLoadingDataContacto(true);
      getContactByEmail(
        instance?.userData?.apiKey,
        drawerData?.data?.email?.trim()
      )
        .then((res) => {
          if (res?.status === 200) {
            let item = res.data;

            if (item) {
              // Si está guardado como contacto;
              setContactData(item);

              form.setFieldsValue({
                first_name: item.name?.first_name || '',
                middle_name: item.name?.middle_name || '',
                last_name: item.name?.last_name || '',
                birthday: item.birthday
                  ? dayjs(item.birthday, 'DD/MM/YYYY')
                  : undefined,
                company: item.org?.company || '',
                department: item.org?.department || '',
                title: item.org?.title || '',
                phone: item.phones?.[0]?.phone || '',
                email: drawerData?.data?.email
              });

              if (item.addresses?.[0]) {
                let direccion = item.addresses[0];
                form.setFieldsValue({
                  country: direccion.country || '',
                  state: direccion.state || '',
                  city: direccion.city || '',
                  street: direccion.street || '',
                  type: direccion.type || 'HOME',
                  zip: direccion.zip || ''
                });
              }
            } else {
              messageApi.info('El usuario no tiene datos de contacto');

              form.setFieldsValue({
                email: drawerData?.data?.email
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error('Ocurrió un error al obtener info del usuario');
        })
        .finally(() => setLoadingDataContacto(false));
    }
  }, [drawerData, instance]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formText'
      requiredMark={false}
      onFinish={handleEdit}
      className='form-nodos'
    >
      <b style={{ marginBottom: 16 }}>Editar usuario</b>
      {loadingDataContacto ? (
        <div style={{ margin: 50, display: 'grid', placeItems: 'center' }}>
          <LoadingIcon />
        </div>
      ) : (
        <>
          <Form.Item label='Seleccione un rol'>
            <Select
              options={roles?.map((rol) => {
                return {
                  label: rol.name,
                  value: rol._id
                };
              })}
              onChange={(v) => setSelectedRol(v)}
              value={selectedRol}
            />
          </Form.Item>
          <span className='horizontal gap8'>
            <Form.Item
              label='Nombre'
              name='first_name'
              className='half-50'
              rules={[
                {
                  required: true,
                  message: 'Campo requerido'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Segundo nombre'
              name='middle_name'
              className='half-50'
            >
              <Input />
            </Form.Item>
          </span>
          <span className='horizontal gap8'>
            <Form.Item
              label='Apellido'
              name='last_name'
              className='half-50'
              rules={[
                {
                  required: true,
                  message: 'Campo requerido'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Fecha de nacimiento'
              name='birthday'
              className='half-50'
              initialValue={null}
            >
              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Form.Item>
          </span>
          <Form.Item
            label='Teléfono'
            name='phone'
            rules={[
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'No se permiten espacios ni caracteres especiales'
              },
              {
                min: 11,
                max: 12,
                message:
                  'El número debe tener 11-12 dígitos. Por ejemplo: 543535123456'
              },
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input placeholder='543535362409' />
          </Form.Item>
          <Form.Item label='Email' name='email'>
            <Input disabled />
          </Form.Item>
          <Form.Item label='Organización' name='company'>
            <Input />
          </Form.Item>
          <span className='horizontal gap8'>
            <Form.Item
              label='Departamento'
              name='department'
              className='half-50'
            >
              <Input />
            </Form.Item>
            <Form.Item label='Cargo' name='title' className='half-50'>
              <Input />
            </Form.Item>
          </span>
          <span className='horizontal gap8'>
            <Form.Item label='País' name='country' className='half-50'>
              <Input />
            </Form.Item>
            <Form.Item
              label='Estado o provincia'
              name='state'
              className='half-50'
            >
              <Input />
            </Form.Item>
          </span>
          <span className='horizontal gap8'>
            <Form.Item label='Localidad' name='city' className='half-50'>
              <Input />
            </Form.Item>
            <Form.Item label='Dirección' name='street' className='half-50'>
              <Input />
            </Form.Item>
          </span>
          <span className='horizontal gap8'>
            <Form.Item label='Código postal' name='zip' className='half-50'>
              <Input />
            </Form.Item>
            <Form.Item
              label='Tipo de dirección'
              name='type'
              initialValue='home'
              className='half-50'
            >
              <Select
                options={[
                  { label: 'Casa', value: 'home' },
                  { label: 'Trabajo', value: 'work' }
                ]}
              />
            </Form.Item>
          </span>
        </>
      )}
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            //loading={loadingEditar}
            icon={
              loadingEditar ? (
                <LoadingIcon size='small' color='#FFFFFF' />
              ) : null
            }
            style={{ opacity: loadingEditar ? 0.65 : 1 }}
            // onClick={handleEdit}
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setDrawerData({ open: false, data: null });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
