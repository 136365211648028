import { Button, Image, Popover, Tooltip } from "antd";
import { FiCopy, FiEdit3, FiShoppingCart } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { TbTrash } from "react-icons/tb";
import { deleteProduct } from "../../../../../../../helpers/fetchData/fetchCatalog";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { SettingsContext } from "../../../../../../context/SettingsContext";
import { deleteBucketResources } from "../../../../../../../helpers/fetchData/fetchBucketResources";
import { getCurrencyByListForTable } from "./getCurrencyByList";
import { actualizarDeleteProduct } from "./actualizarListas";
import { deleteStatus } from "../../../../../../../helpers/fetchData/fetchBudgetStatuses";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

const AccionesArticulos = ({ item, setModal, listId }) => {
  const { setConfigDrawerContent, instance, messageApi } =
    useContext(GlobalContext);

  const { setCatalogDetail } = useContext(SettingsContext);

  const [showPopover, setShowPopover] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    setLoading(true);
    deleteProduct(instance?.userData?.apiKey, id)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Producto eliminado correctamente");
          setShowPopover(false);

          actualizarDeleteProduct(setCatalogDetail, id);
        } else {
          messageApi.error("Ocurrió un error al eliminar un producto");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al eliminar un producto");
      })
      .finally(() => setLoading(false));
  };

  return (
    <span className="horizontal">
      <Tooltip title="Ver detalle">
        <AiOutlineEye
          size={16}
          style={{ cursor: "pointer" }}
          onClick={() => setModal({ visible: true, data: item })}
        />
      </Tooltip>
      <Tooltip title="Editar">
        <FiEdit3
          size={16}
          style={{ cursor: "pointer" }}
          onClick={() =>
            setConfigDrawerContent({
              visible: true,
              content: "updateProduct",
              item: item,
            })
          }
        />
      </Tooltip>

      <Popover
        open={showPopover}
        content={
          <span className="columna" style={{ maxWidth: 280 }}>
            <p>{`¿Está seguro de eliminar ${item.title}?`}</p>
            <span className="horizontal">
              <Button
                type="primary"
                className="btn-borrar"
                onClick={() => handleDelete(item._id)}
                //loading={loading}
                icon={
                  loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
                }
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                type="secondary"
                className="btn-cancelar"
                onClick={() => setShowPopover(false)}
              >
                Cancelar
              </Button>
            </span>
          </span>
        }
      >
        <Tooltip title="Eliminar">
          <TbTrash
            size={16}
            style={{ cursor: "pointer" }}
            onClick={() => setShowPopover(true)}
          />
        </Tooltip>
      </Popover>
    </span>
  );
};

export const articulosColumnsSimple = () => {
  return [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (data) => (
        <p style={{ fontWeight: 600, color: "var(--primary-color)" }}>{data}</p>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      render: (data) => {
        if (data.length > 75) {
          return data.substring(0, 74) + "...";
        }
        return data;
      },
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 120,
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "brand",
    },
  ];
};

const isValid = (item) => {
  if (!item.price || !item.id || !item.title) {
    return "#ff6961";
  }
  return "var(--primary-color)";
};

const getPrice = (item, selectedListId, currency) => {
  let data = "";

  if (selectedListId) {
    if (item?.pricesByList) {
      const lista = item.pricesByList.find(
        (list) => list.listId === selectedListId
      );

      if (lista) {
        data = lista?.price;
      }
    }
  } else {
    data = item.price;
  }

  return data.toString()?.replace(".", ",").toLocaleString("de-DE");
};

export const articulosColumns = (setModal, catalogDetail, selectedListId) => {
  let currency = getCurrencyByListForTable(catalogDetail, selectedListId);

  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (data) => <p style={{ fontWeight: 600 }}>{data}</p>,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (data, item) => (
        <p style={{ fontWeight: 600, color: isValid(item) }}>{data}</p>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 120,
      render: (data, item) => getPrice(item, selectedListId, currency),
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "...",
      dataIndex: "",
      key: "",
      width: 80,
      align: "center",
      render: (data, item) => (
        <AccionesArticulos
          item={item}
          setModal={setModal}
          listId={selectedListId}
        />
      ),
    },
  ];
};

const AccionesEstados = ({ item }) => {
  const { setConfigDrawerContent, instance, messageApi } =
    useContext(GlobalContext);

  const { setCatalogDetail } = useContext(SettingsContext);

  const [showPopover, setShowPopover] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    setLoading(true);
    deleteStatus(instance?.userData?.apiKey, id)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Estado eliminado correctamente");
          setCatalogDetail((prevState) => {
            let obj = { ...prevState };

            let array = obj.statuses?.filter(
              (element) => element._id !== item._id
            );

            obj.statuses = array;

            return obj;
          });
          setShowPopover(false);
        } else {
          messageApi.error("Ocurrió un error al eliminar un estado");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al eliminar un estado");
      })
      .finally(() => setLoading(false));
  };

  return (
    <span
      className="horizontal"
      style={{ marginInline: "auto", width: "fit-content" }}
    >
      {/* <Tooltip title="Ver detalle">
        <AiOutlineEye
          size={16}
          style={{ cursor: "pointer" }}
          onClick={() => setModal({ visible: true, data: item })}
        />
      </Tooltip> */}
      <Tooltip title="Editar">
        <FiEdit3
          size={16}
          style={{ cursor: "pointer" }}
          onClick={() =>
            setConfigDrawerContent({
              visible: true,
              content: "editStatus",
              item: item,
            })
          }
        />
      </Tooltip>

      <Popover
        open={showPopover}
        content={
          <span className="columna" style={{ maxWidth: 280 }}>
            <p>{`¿Está seguro de eliminar ${item.name}?`}</p>
            <span className="horizontal">
              <Button
                type="primary"
                className="btn-borrar"
                onClick={() => handleDelete(item._id)}
                //loading={loading}
                icon={
                  loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
                }
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                type="secondary"
                className="btn-cancelar"
                onClick={() => setShowPopover(false)}
              >
                Cancelar
              </Button>
            </span>
          </span>
        }
      >
        <Tooltip title="Eliminar">
          <TbTrash
            size={16}
            style={{ cursor: "pointer" }}
            onClick={() => setShowPopover(true)}
          />
        </Tooltip>
      </Popover>
    </span>
  );
};

export const columnasEstados = [
  { title: "Nombre", dataIndex: "name", key: "name" },
  { title: "Descripción", dataIndex: "description", key: "description" },
  {
    title: "...",
    dataIndex: "",
    key: "",
    align: "center",
    width: 100,
    render: (data, item) => {
      return <AccionesEstados item={item} />;
    },
  },
];

const AccionesListas = ({ item }) => {
  const { application, instance, setConfigDrawerContent } =
    useContext(GlobalContext);

  const { catalogDetail } = useContext(SettingsContext);

  const [showPopover, setShowPopover] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <span
      className="horizontal"
      style={{ width: "fit-content", marginInline: "auto" }}
    >
      <Tooltip title="Editar">
        <FiEdit3
          size={16}
          onClick={() =>
            setConfigDrawerContent({
              visible: true,
              content: "editList",
              item: item,
            })
          }
          style={{ cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip title="Administrar productos">
        <FiShoppingCart
          size={16}
          onClick={() =>
            setConfigDrawerContent({
              visible: true,
              content: "manageListProducts",
              item: item,
            })
          }
          style={{ cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip title="Eliminar">
        <TbTrash size={16} style={{ cursor: "pointer" }} />
      </Tooltip>
    </span>
  );
};

export const listasColumns = (setConfigDrawerContent, catalogDetail) => {
  return [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (data) => (
        <p style={{ fontWeight: 600, color: "var(--primary-color)" }}>{data}</p>
      ),
    },
    {
      title: "Divisa",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "...",
      dataIndex: "",
      key: "",
      align: "center",
      width: 120,
      render: (data, item) => (
        <AccionesListas item={item} catalogDetail={catalogDetail} />
      ),
    },
  ];
};

const RecursosAcciones = ({ item, setRecursos }) => {
  const [showPopover, setShowPopover] = useState(false);
  const { instance, messageApi, setConfigDrawerContent } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const handleDeleteResource = () => {
    setLoading(true);
    deleteBucketResources(instance?.userData?.apiKey, item._id, messageApi)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Recurso eliminado correctamente");
          setRecursos((prevState) => {
            let array = [...prevState];

            let indice = array.findIndex((imagen) => imagen._id === item._id);

            if (indice > -1) {
              array.splice(indice, 1);
            }

            return array;
          });

          setShowPopover(false);
        } else {
          messageApi.error("Ocurrió un error al eliminar un recurso");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al eliminar un recurso");
      })
      .finally(() => setLoading(false));
  };

  return (
    <span className="horizontal">
      <Tooltip title="Copiar URL imagen">
        <FiCopy
          size={16}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(item.image_url);
            messageApi.info(`Texto copiado: "${item.image_url}"`);
          }}
        />
      </Tooltip>
      <Tooltip title="Editar">
        <FiEdit3 size={16} style={{ cursor: "pointer" }} />
      </Tooltip>
      <Popover
        open={showPopover}
        content={
          <div className="columna">
            <p>¿Está seguro de borrar este recurso?</p>
            <span className="horizontal">
              <Button
                type="primary"
                className="btn-borrar"
                onClick={() => handleDeleteResource()}
                //loading={loading}
                icon={
                  loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
                }
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                type="secondary"
                className="btn-cancelar"
                onClick={() => setShowPopover(false)}
              >
                Cancelar
              </Button>
            </span>
          </div>
        }
      >
        <Tooltip title="Eliminar">
          <TbTrash
            size={16}
            onClick={() => setShowPopover(true)}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      </Popover>
    </span>
  );
};

export const recursosColumns = (setRecursos) => {
  return [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (data) => <p style={{ fontWeight: 600 }}>{data}</p>,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Imagen",
      dataIndex: "image_url",
      key: "image_url",
      render: (data) => {
        return (
          <Popover
            content={
              <Image src={data} width={250} height={250} preview={false} />
            }
          >
            <Image src={data} width={40} height={40} preview={false} />
          </Popover>
        );
      },
    },
    {
      title: "...",
      dataIndex: "",
      key: "",
      align: "center",
      width: 80,
      render: (data, item) => (
        <RecursosAcciones item={item} setRecursos={setRecursos} />
      ),
    },
  ];
};
