import { useContext, useEffect } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { TbTrash } from "react-icons/tb";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { MdOutlineHttp } from "react-icons/md";
import { Tag } from "antd";
import { renderHTMLTag } from "../utils/httpRequest";
import { getNodeName } from "../utils/getNodeName";

export const HttpRequestNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(node.id);
  }, [node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div className="answer-node">
          <MdOutlineHttp style={{ color: "var(--dark-color)" }} size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node?.data?.label}</span>
        </div>
      </div>
      <div className="request-content">
        <Tag color="green">{node.data.method}</Tag>
        <span className="request-content-info">
          <p className="text-wrap" id={`${node.id}-http-url`}>
            {renderHTMLTag(node.data.url, `${node.id}-http-url`)}
          </p>
        </span>
      </div>
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
        {node?.data?.fallback && (
          <span className="linea-handle">
            <p>Fallback</p>
            <Handle
              type="source"
              className="custom-handle fallback"
              position={Position.Right}
              id="fallback"
              isConnectable={checkValidConnection(edges, node.id, "fallback")}
            />
          </span>
        )}
      </span>
      <div className="path-id" style={{ marginTop: "24px" }}>
        {node?.id}
      </div>
    </div>
  );
};
