import axios from 'axios';

export function getVisibleColumns(
  instance,
  messageApi,
  setVisibleColumns,
  setLoading = null
) {
  if (setLoading) {
    setLoading(true);
  }

  if (instance && instance.userData?.apiKey) {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/visibleColumns/getVisibleColumns`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    axios(config)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data?.[0]?.columns) {
            setVisibleColumns(res.data[0].columns);
          } else {
            setVisibleColumns([]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error('Error al cargar las columnas visibles');
      })
      .finally(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
  } else {
  }
}

export function updateVisibleColumns(
  data,
  instance,
  messageApi,
  setLoading = null
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_PRODUCCION}/visibleColumns/updateVisibleColumns`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data: data
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        messageApi.success('Visibilidad de campos actualizada');
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error('Error al actualizar la visibilidad de los campos');
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}
