import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { Button, Col, Form, Input, Row } from "antd";
import axios from "axios";
import { actualizarLista } from "../../../../../../helpers/utilidadesPlusContent";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const NewTag = () => {
  const {
    setSideDrawerConfig,
    instance,
    etiquetas,
    messageApi,
    contactos,
    setEtiquetas,
  } = useContext(GlobalContext);

  const [formEtiqueta] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const nuevaEtiqueta = (v) => {
    let data = {
      name: v.name.toUpperCase(),
      description: v.description,
      color: v.color,
      instanceId: instance?._id,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/tags/createTag`,
      data: data,
      headers: {
        Authorization: `${instance?.userData?.apiKey}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(false);

    axios(config)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Nueva etiqueta agregada");
          if (res.data?.tag?._id) {
            actualizarLista(
              "etiquetas",
              res.data.tag,
              setEtiquetas,
              etiquetas,
              contactos,
              res.data?.tag?._id
            );
          }
          setSideDrawerConfig({
            visible: false,
            content: "",
          });
        } else {
          messageApi.error("Ocurrió un error al crear etiqueta");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al crear etiqueta");
        if (error?.response?.data?.message) {
          messageApi.error(error?.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={formEtiqueta}
      requiredMark="optional"
      name="newApplication"
      style={{ padding: "24px" }}
      layout="vertical"
      onFinish={(v) => nuevaEtiqueta(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Nueva etiqueta</p>
      <Row style={{ justifyContent: "space-between" }}>
        <Col span={16}>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Input size="large" maxLength={15} showCount />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            label="Color"
            name="color"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Input type="color" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Descripción" name="description">
        <Input size="large" />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() =>
              setSideDrawerConfig({
                visible: false,
                content: "",
              })
            }
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
