import { Button, Form, Input, Spin } from 'antd';
import { encode } from 'js-base64';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { registerApp } from '../../../../../../../helpers/registerApp';
import { getProfile } from '../../../../../../../helpers/fetchData/fetchProfile';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const CrearApp = () => {
  const { form } = Form.useForm();

  const [showSpin, setShowSpin] = useState(false);

  const {
    setAppStage,
    instance,
    application,
    setApplication,
    setWhatsappProfile,
    messageApi
  } = useContext(GlobalContext);

  const onFinishApp = (v) => {
    setShowSpin(true);
    let data = JSON.stringify({
      appName: v.appName,
      appId: v.appId,
      wabaId: v.wabaId,
      token: v.token,
      phoneNumber: v.phoneNumber,
      phoneNumberId: v.phoneNumberId,
      businessId: v.businessId,
      webhookToken: v.webhookToken
    });

    const encoded = encode(data);

    registerApp(instance?.userData?.apiKey, encoded)
      .then((res) => {
        if (res?.status === 200) {
          setApplication([res.data]);
        } else {
          messageApi.error('La aplicación no pudo ser registrada');
        }
      })
      .catch((error) => {
        messageApi.error('La aplicación no pudo ser registrada');
      })
      .finally(() => setShowSpin(false));
  };

  const setearPerfil = (application) => {
    if (application.length > 0 && instance?.userData?.apiKey) {
      // let config = {
      //   method: "get",
      //   url: `${process.env.REACT_APP_API_PRODUCCION}/wb/getProfileInfo?phoneNumberId=${application[0].lines[0].phoneNumberId}&accessToken=${application[0].token}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      getProfile(application[0], instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setWhatsappProfile([res.data]);
            //setTimeout(() => {
            setAppStage(2);
            //}, 1500);
          }
        })
        .catch((error) => {
          messageApi.error('Error al obtener perfiles');
        })
        .finally(() => setShowSpin(false));
    }
  };

  useEffect(() => {
    if (application.length > 0) {
      setearPerfil(application);
    }
  }, [application]);

  return (
    <div className='conf-content fade-in'>
      {!showSpin ? (
        <Form
          form={form}
          requiredMark='optional'
          name='newApplication'
          layout='vertical'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onFinish={(v) => onFinishApp(v)}
        >
          <Form.Item
            label='Nombre de aplicación'
            name='appName'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='ID de aplicación'
            name='appId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'Solo se permiten caracteres númericos'
              }
            ]}
          >
            <Input type='numeric' />
          </Form.Item>
          <Form.Item
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <p>Token de usuario (permanente)</p>
              </div>
            }
            name='token'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 10 }}
              className='input-desabilitado'
            />
          </Form.Item>
          <Form.Item
            label='ID de cuenta de WhatsApp Business'
            name='wabaId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'Solo se permiten caracteres númericos'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='ID teléfono'
            name='phoneNumberId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'Solo se permiten caracteres númericos'
              }
            ]}
          >
            <Input type='tel' />
          </Form.Item>
          <Form.Item
            label='Número de teléfono'
            name='phoneNumber'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'Solo se permiten caracteres númericos'
              },
              {
                min: 11,
                max: 12,
                message:
                  'El número debe contener 11-12 caracteres (ej: 543535123456)'
              }
            ]}
          >
            <Input
              style={{
                width: '100%'
              }}
            />
          </Form.Item>
          <Form.Item
            label='Business ID'
            name='businessId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'Solo se permiten caracteres númericos'
              }
            ]}
          >
            <Input type='numeric' />
          </Form.Item>
          <Form.Item
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <p>Webhook Token</p>
              </div>
            }
            name='webhookToken'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              className='input-desabilitado'
            />
          </Form.Item>
          <div className='botones-wrapper-content' style={{ width: '100%' }}>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              className='btn-guardar'
              disabled={false}
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              type='secondary'
              size='large'
              disabled={false}
              onClick={() => setAppStage(0)}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      ) : (
        <Spin
          style={{
            marginTop: 100
          }}
          indicator={<LoadingIcon size='large' />}
        />
      )}
    </div>
  );
};
