import axios from "axios";

export const fetchInstance = (
  user,
  messageApi,
  setLoadingInstance,
  setWorkspaces
) => {
  if (user) {
    let count = 0;
    let fetch = () => {
      if (count < 5) {
        count += 1;

        setLoadingInstance(true);

        getInstance(user.email)
          .then((res) => {
            if (res?.status === 200) {
              const { data, user } = res.data;

              let array = data?.instance
                ?.map((workspace) => {
                  let association = user?.associations?.find(
                    (element) => element.instanceId === workspace._id
                  );

                  return {
                    ...workspace,
                    userData: { userId: user._id, ...association },
                  };
                })
                ?.filter((workspace) => {
                  if (workspace.userData?.status === false) {
                    return false;
                  }

                  return true;
                });

              setWorkspaces({ ...data, instance: array });
            } else {
              setTimeout(() => {
                fetch();
              }, 2000);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              fetch();
            }, 2000);
          })
          .finally(() => {
            setLoadingInstance(false);
          });
      } else {
        messageApi.error("Error al obtener workspace");
        setLoadingInstance(false);
      }
    };

    fetch();
  }
};

export const getInstance = async (email) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/getInstance/?email=${email}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadProfilePicture = async (key, data) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/uploadProfilePicture`,
      headers: {
        "Content-Type": "application/json",
        Authorization: key,
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserStatus = async (data, apiKey) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/chat/agent/agentStatus`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const lastLoginUpdate = async (apiKey) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/users/login`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserAccess = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/userAccess`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};



