import { PiAddressBookBold } from "react-icons/pi";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { isSelectedClass } from "../../../../../../helpers/isSelected";
import { useContext, useEffect } from "react";
import { SendTemplateContext } from "../../../../../context/SendTemplateContext";
import { Tag } from "antd";

export const ContactsNode = (node) => {
  const { setNodoSeleccionado, setMostrarDrawer } =
    useContext(SendTemplateContext);

  const handleClick = () => {
    setNodoSeleccionado(node);
    setMostrarDrawer(true);
  };

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(node.id);
  }, [node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => handleClick()}
    >
      <div className="node-header-send-template">
        <div>
          <PiAddressBookBold
            //   fill=""
            size={32}
          />
        </div>
        <div className="node-description">
          <span className="node-type">Seleccionar</span>
          <span className="mensaje-nodo">Filtros</span>
        </div>
      </div>
      <Handle
        type="source"
        className="custom-handle trigger-handle"
        position={Position.Right}
        isConnectable={false}
      />
    </div>
  );
};
