import { Button, Popover, Spin, Tag, Tooltip } from "antd";
import { Base64 } from "js-base64";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { PieGraph } from "./PieGraph";
import { TbTrash } from "react-icons/tb";
import { IoMegaphoneOutline } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import { deleteCampaign } from "../../../../helpers/fetchData/fetchCampaigns";
import { GlobalContext } from "../../../context/GlobalContext";
import dayjs from "dayjs";
import { accessDataFormat } from "../../../../helpers/access";
import { useAccess } from "../../../../hooks/useAccess";
import { IoIosStats } from "react-icons/io";
import LoadingIcon from "../../Loader/LoadingIcon";

const AccionesCampaigns = ({ item, setCampaigns }) => {
  const navigate = useNavigate();

  const { instance, messageApi } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);
  const [loadingBorrar, setLoadingBorrar] = useState(false);

  const isAllowed = useAccess();

  const handleNavigate = () => {
    const data = Base64.encode(JSON.stringify(item), true);
    navigate(`/campaigns/details?payload=${data}`);
  };

  const goToData = () => {
    const {name, _id, contactsCount = 0} = item;
    // let contactsCount = contactsCount || 0;
    let data = { _id, name, contactsCount };
    let obj64 = Base64.encode(JSON.stringify(data), true);

    navigate(`/campaigns/data?data=${obj64}`);
  };

  const handleDelete = (id) => {
    setLoadingBorrar(true);
    deleteCampaign(instance?.userData?.apiKey, id)
      .then((res) => {
        if (res?.status === 200) {
          setCampaigns((prevState) => {
            let array = prevState.filter((element) => element._id !== id);

            return array;
          });
        } else {
          messageApi.error("Ocurrió un error al eliminar una campaña");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al eliminar una campaña");
      })
      .finally(() => setLoadingBorrar(false));
  };

  return (
    <span
      className="horizontal gap8"
      style={{ justifyContent: "center", fontSize: 18 }}
    >
      <Tooltip className="pointer" title="Ver">
        <span>
          <FiEye onClick={handleNavigate} />
        </span>
      </Tooltip>
      {isAllowed(accessDataFormat("broadcast", "show")) && (
        <Tooltip className="pointer" title="Estadísticas">
          <span>
            <IoIosStats onClick={goToData} />
          </span>
        </Tooltip>
      )}
      {isAllowed(accessDataFormat("broadcast", "manage")) && (
        <Popover
          trigger="click"
          placement="bottomRight"
          open={showPopover}
          content={
            <div className="columna-simple">
              <span>{`¿Está seguro de borrar ${item?.name}?`}</span>
              <p
                style={{
                  color: "#858585",
                  fontSize: 12,
                  margin: "8px 0px",
                  fontWeight: 400,
                }}
              >
                Al eliminar se borrarán tambien las métricas asociadas
              </p>

              <div
                className="botones-wrapper-content"
                style={{ width: "100%" }}
              >
                <Button
                  type="primary"
                  className="btn-borrar"
                  onClick={() => handleDelete(item._id)}
                  //loading={loadingBorrar}
                  icon={
                    loadingBorrar ? (
                      <LoadingIcon size="small" color="#FFFFFF" />
                    ) : null
                  }
                  style={{ opacity: loadingBorrar ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  className="btn-cancelar"
                  type="secondary"
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          }
        >
          <span>
            <Tooltip className="pointer" title="Eliminar">
              <span>
                <TbTrash onClick={() => setShowPopover(true)} />
              </span>
            </Tooltip>
          </span>
        </Popover>
      )}
    </span>
  );
};

export const getContactosCount = (schedule) => {
  return schedule?.reduce((total, item) => {
    return (total += item.contacts?.length);
  }, 0);
};

const getCampaignMetrics = (id, metricas) => {
  let campaign = metricas.find((element) => element.campaignId === id);

  if (!campaign?.data) return { sent: 0, delivered: 0, read: 0 };

  let values = campaign.data.reduce(
    (total, envio) => {
      return {
        sent: (total.sent += envio?.sent || 0),
        delivered: (total.delivered += envio?.delivered || 0),
        read: (total.read += envio?.read || 0),
      };
    },
    {
      sent: 0,
      delivered: 0,
      read: 0,
    }
  );

  return values;
};

const renderLoader = (size = 20) => {
  return (
    <span
      style={{ height: 60, width: 79, display: "grid", placeItems: "center" }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: size,
            }}
            spin
          />
        }
      />
    </span>
  );
};

export const columnsCampaigns = (
  campaignsMetrics,
  navigate,
  loadingGraphs,
  setCampaigns
) => {
  const handleNavigate = (item) => {
    const data = Base64.encode(JSON.stringify(item), true);
    navigate(`/campaigns/details?payload=${data}`);
  };

  return [
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      render: (data, item) => (
        <span
          className="horizontal gap8"
          onClick={() => handleNavigate(item)}
          style={{ cursor: "pointer" }}
        >
          <IoMegaphoneOutline
            style={{ color: "var(--dark-color)" }}
            size={20}
          />
          <b>{data}</b>
        </span>
      ),
    },
    {
      title: "Plantilla",
      key: "template",
      dataIndex: "template",
      render: (data) => {
        return data?.template?.name;
      },
    },
    {
      title: "Creación",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (data) => {
        return dayjs(data, "x").format("DD/MM/YYYY");
      },
    },
    {
      title: "Fecha/s",
      key: "schedule",
      dataIndex: "schedule",
      render: (data) => {
        const wholeData = (
          <span
            className="horizontal gap4"
            style={{ maxWidth: 222, flexWrap: "wrap" }}
          >
            {data.map((schedule, index) => (
              <Tag color="geekblue" key={index}>
                {schedule.date}
              </Tag>
            ))}
          </span>
        );

        if (data.length > 2) {
          return (
            <Popover content={wholeData}>
              <span className="horizontal gap4">
                {data.slice(0, 2).map((schedule, index) => (
                  <Tag color="geekblue" key={index}>
                    {schedule.date}
                  </Tag>
                ))}
                <p>...</p>
              </span>
            </Popover>
          );
        }

        return wholeData;
      },
    },
    {
      title: "Contactos",
      key: "contactsCount",
      dataIndex: "contactsCount",
      align: "center",
      render: (data) => data || 0,
    },
    {
      title: "Enviados",
      key: "",
      dataIndex: "",
      align: "center",
      width: 60,
      render: (data, item) => {
        if (loadingGraphs) return renderLoader();

        const metricas = getCampaignMetrics(item._id, campaignsMetrics);
        return (
          <span className="pie-graph-wrapper" key="sent">
            <PieGraph
              data={metricas}
              type="sent"
              contactosCount={item.contactsCount}
            />
          </span>
        );
      },
    },
    {
      title: "Entregados",
      key: "",
      dataIndex: "",
      align: "center",
      width: 60,
      render: (data, item) => {
        if (loadingGraphs) return renderLoader();

        const metricas = getCampaignMetrics(item._id, campaignsMetrics);
        return (
          <span className="pie-graph-wrapper" key="delivered">
            <PieGraph
              data={metricas}
              type="delivered"
              contactosCount={item.contactsCount}
            />
          </span>
        );
      },
    },
    {
      title: "Leídos",
      key: "",
      dataIndex: "",
      align: "center",
      width: 60,
      render: (data, item) => {
        if (loadingGraphs) return renderLoader();

        const metricas = getCampaignMetrics(item._id, campaignsMetrics);
        return (
          <span className="pie-graph-wrapper" key="read">
            <PieGraph
              data={metricas}
              type="read"
              contactosCount={item.contactsCount}
            />
          </span>
        );
      },
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      render: (data, item) => {
        return (
          <AccionesCampaigns
            item={item}
            key={item._id}
            setCampaigns={setCampaigns}
          />
        );
      },
    },
  ];
};

// export const COLORES_PIE = ['#598F3B', '#B34949'];
export const COLORES_PIE = ["#8cac29", "#F69C41"];
