import { Button, Form, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  DEFAULT_MESSAGES,
  createDefaultMessages,
} from "../../../../../../helpers/fetchData/fetchConfig";
import { GlobalContext } from "../../../../../context/GlobalContext";

export const CreateErrorMessages = ({ setErrorMessagesStage }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const filteredObj = Object.entries(values)
      .filter(([key, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    createDefaultMessages(instance?.userData?.apiKey, filteredObj)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Configuración guardada correctamente");
        } else {
          messageApi.error("No se pudo guardar la configuración");
        }
      })
      .catch(() => {
        messageApi.error("No se pudo guardar la configuración");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {}, []);

  return (
    <div className="conf-content">
      <Form
        form={form}
        name="errorMessages"
        layout="vertical"
        onFinish={(v) => onFinish(v)}
        style={{ padding: "0px", display: "flex", flexDirection: "column" }}
      >
        <div className="columna" style={{ margin: "8px 0px 16px 0px" }}>
          <b>Variables</b>
          <div className="item-wrapper" style={{ backgroundColor: "#fbfbfb" }}>
            <Form.Item
              label="Validación de tipo de variable"
              name="defaultControlVarType"
            >
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultControlVarType}
              />
            </Form.Item>
          </div>
          <b>Peticiones HTTP</b>
          <div className="item-wrapper" style={{ backgroundColor: "#fbfbfb" }}>
            <Form.Item label="Fallo en una petición" name="defaultErrorHttp">
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultErrorHttp}
              />
            </Form.Item>
            <Form.Item
              label="Respuesta vacia a una petición"
              name="defaultHttp"
            >
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultHttp}
              />
            </Form.Item>
          </div>
          <b>Transcripción de audio</b>
          <div className="item-wrapper" style={{ backgroundColor: "#fbfbfb" }}>
            <Form.Item
              label="Fallo en la transcripción de audio"
              name="defaultTranscribe"
            >
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultTranscribe}
              />
            </Form.Item>
          </div>
        </div>
        <div className="botones-wrapper-content" style={{ marginTop: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            block
            loading={loading}
          >
            Guardar
          </Button>
          <span></span>
        </div>
      </Form>
    </div>
  );
};
