import axios from 'axios';
import { getContactName } from '../../components/ui/Layout/campaigns/sendTemplates/utils/getContactName';

export function archiveContact(
  item,
  instance,
  messageApi,
  setContactos,
  setLoading = null,
  setShowPopover
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_PRODUCCION}/archivedContacts/archiveContact`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data: {
      number: item.phones?.[0]?.phone
    }
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setContactos((prevState) => {
          const newArray = prevState.map((contacto) => {
            if (contacto._id === item._id) {
              return { ...contacto, isArchived: true };
            }
            return contacto;
          });

          return newArray;
        });
        setShowPopover(false);
        messageApi.success(`${getContactName(item.name)} archivado`);
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error('Error al archivar contacto');
    })
    .finally(setLoading(false));
}

export function unarchiveContact(
  item,
  instance,
  messageApi,
  setContactos,
  setLoading = null,
  setShowPopover
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_PRODUCCION}/archivedContacts/unarchiveContact`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data: {
      number: item.phones?.[0]?.phone
    }
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setContactos((prevState) => {
          const newArray = prevState.map((contacto) => {
            if (contacto._id === item._id) {
              return { ...contacto, isArchived: false };
            }
            return contacto;
          });

          return newArray;
        });
        setShowPopover(false);
        messageApi.success(`${getContactName(item.name)} desarchivado`);
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error('Error al desarchivar contacto');
    })
    .finally(setLoading(false));
}
