import dayjs from "dayjs";
import { getContactName } from "./getContactName";

export const formatearEstados = (segmentos) => {
  let outputData = {};

  segmentos?.forEach((entry) => {
    entry.dataStatuses?.forEach((item) => {
      const status = item.status;
      const interactions = item.contacts;

      interactions.forEach((interaction) => {
        const fecha = dayjs(interaction.timestamp * 1000).format("DD/MM/YYYY");

        if (!outputData[fecha]) {
          outputData[fecha] = [];
        }

        const formattedInteraction = {
          status: status,
        };

        outputData[fecha].push(formattedInteraction);
      });
    });
  });

  const finalOutput = Object.keys(outputData).map((fecha) => {
    let values = outputData[fecha];

    let conteo = {};

    values.forEach((valor) => {
      if (!conteo[valor.status]) {
        conteo[valor.status] = [];
      }

      conteo[valor.status].push(valor.status);
    });

    const resultado = {};

    Object.keys(conteo).forEach((key) => {
      resultado[key] = conteo[key]?.length;
    });

    return { fecha: fecha, ...resultado };
  });

  return finalOutput;
};

export const formatearInteracciones = (segmentos) => {
  let outputData = {};

  segmentos?.forEach((entry) => {
    entry.dataInteractions?.forEach((item) => {
      const buttonPayload = item.buttonPayload;
      const interactions = item.interactions;

      if (!outputData[buttonPayload]) {
        outputData[buttonPayload] = [];
      }

      interactions.forEach((interaction) => {
        const timestamp = parseInt(interaction.message.timestamp);
        const contactInfo = interaction.message.contactInfo;
        const name = getContactName(contactInfo.name);

        const formattedInteraction = {
          timestamp: timestamp,
          contactInfo: { name: name, _id: contactInfo._id },
        };

        outputData[buttonPayload].push(formattedInteraction);
      });
    });
  });

  const finalOutput = Object.keys(outputData).map((button) => {
    return { button: button, interactions: outputData[button] };
  });

  return finalOutput;
};
