import { Button, Popover, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import { Tag } from '../../../../tag/Tag';
import { deleteSavedMessage } from '../../../../../../helpers/fetchData/fetchSavedMessages';
import { GlobalContext } from '../../../../../context/GlobalContext';
import { DrawerEditarMensaje } from './DrawerEditarMensaje';

export const MessagesList = ({ record, setGrupos }) => {
  const listaMensajes = record.savedReplies.map((mensaje) => (
    <div key={mensaje?._id}>
      <PopoverMensaje mensaje={mensaje} setGrupos={setGrupos}>
        <Tag key={mensaje?._id} hex='#ffffff' nombre={mensaje?.name} />
      </PopoverMensaje>
    </div>
  ));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {listaMensajes}
    </div>
  );
};

const PopoverMensaje = ({ children, mensaje, setGrupos }) => {
  const [showPopoverEliminar, setShowPopoverEliminar] = useState(false);
  const [showDrawerEditar, setShowDrawerEditar] = useState(false);

  return (
    <>
      <Popover
        destroyTooltipOnHide
        trigger='click'
        content={
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div>
              <p style={{ fontWeight: '500' }}>{mensaje?.name}</p>
              <p>{mensaje?.value}</p>
            </div>
            <div className='caja-botones-vertical'>
              <Tooltip title='Editar' placement='right'>
                <FiEdit3
                  className='pointer'
                  size={16}
                  onClick={() => setShowDrawerEditar(true)}
                />
              </Tooltip>
              <Tooltip title='Eliminar' placement='right'>
                <PopoverEliminar
                  mensaje={mensaje}
                  showPopoverEliminar={showPopoverEliminar}
                  setShowPopoverEliminar={setShowPopoverEliminar}
                  setGrupos={setGrupos}
                >
                  <TbTrash
                    className='pointer'
                    size={16}
                    onClick={() => setShowPopoverEliminar(true)}
                  />
                </PopoverEliminar>
              </Tooltip>
            </div>
          </div>
        }
        placement='right'
      >
        <span
          className='pointer'
          style={{ display: 'block', width: 'fit-content' }}
        >
          {children}
        </span>
      </Popover>
      <DrawerEditarMensaje
        showDrawerEditar={showDrawerEditar}
        setShowDrawerEditar={setShowDrawerEditar}
        setGrupos={setGrupos}
        mensaje={mensaje}
      />
    </>
  );
};

const PopoverEliminar = ({
  children,
  mensaje,
  showPopoverEliminar,
  setShowPopoverEliminar,
  setGrupos
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  function eliminarMensaje() {
    deleteSavedMessage(instance?.userData?.apiKey, mensaje?._id)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success('Mensaje eliminado correctamente');

          setGrupos((prevState) => {
            let array = [...prevState];

            const category = prevState.find(
              (i) => i._id === mensaje.categoryId
            );

            const index = category.savedReplies.findIndex(
              (element) => element._id === mensaje?._id
            );

            if (index !== -1) {
              const newSavedReplies = category.savedReplies.filter(
                (i) => i._id !== mensaje._id
              );

              array[index].savedReplies = newSavedReplies;

              return array;
            }
          });
        } else {
          messageApi.error('Ocurrió un error al eliminar el mensaje');
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error('Ocurrió un error al eliminar el mensaje');
      })
      .finally(() => setShowPopoverEliminar(false));
  }

  return (
    <Popover
      open={showPopoverEliminar}
      content={
        <span className='columna' style={{ maxWidth: 280 }}>
          <p>¿Está seguro de eliminar este mensaje?</p>
          <span className='horizontal'>
            <Button
              type='primary'
              className='btn-borrar'
              onClick={eliminarMensaje}
            >
              Confirmar
            </Button>
            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={() => setShowPopoverEliminar(false)}
            >
              Cancelar
            </Button>
          </span>
        </span>
      }
    >
      {children}
    </Popover>
  );
};
