import { Button, Tabs } from 'antd';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { PiAddressBookBold } from 'react-icons/pi';
import { AdditionalFieldsTable } from './tabs/AdditionalFieldsTable';
import { ColumnsVisibility } from './tabs/ColumnsVisibility';
import { ArchivedContacts } from './tabs/ArchivedContacts';
import { UploadContacts } from './tabs/UploadContacts';
import { TablaBloqueos } from './tabs/TablaBloqueos';
import { NewAdditionalField } from './additionalFields/NewAdditionalField';

export const ContactsSettings = () => {
  const [tab, setTab] = useState('cargaContactos');
  const [additionalFields, setAdditionalFields] = useState([]);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);

  const itemsTab = [
    {
      key: 'cargaContactos',
      label: 'Carga de contactos',
      children: <UploadContacts />
    },
    {
      key: 'camposAdicionales',
      label: 'Campos adicionales',
      children: (
        <AdditionalFieldsTable
          additionalFields={additionalFields}
          setAdditionalFields={setAdditionalFields}
        />
      )
    },
    {
      key: 'visibilidadColumnas',
      label: 'Visibilidad de columnas',
      children: <ColumnsVisibility />
    },
    {
      key: 'bloqueados',
      label: 'Bloqueados',
      children: <TablaBloqueos />
    },
    {
      key: 'archivados',
      label: 'Archivados',
      children: <ArchivedContacts />
    }
  ];

  return (
    <>
      <div className='config-main-wrapper'>
        <div className='test-main-header'>
          <span
            className='horizontal'
            style={{ justifyContent: 'space-between' }}
          >
            <span className='columna' style={{ gap: '0px' }}>
              <span className='config-header-inline'>
                <span style={{ height: '24px' }}>
                  <PiAddressBookBold size={24} color='var(--primary-color)' />
                </span>
                <span className='header-title-templates'>Contactos</span>
              </span>
              <p className='header-subtitle'>Administra los contactos</p>
            </span>
            {tab === 'camposAdicionales' && (
              <div className='espacio-botones-contactos'>
                <Button
                  className='btn-agregar boton-dashed'
                  onClick={() => setOpenAddDrawer(true)}
                >
                  <FiPlus /> Campo
                </Button>
              </div>
            )}
          </span>
        </div>

        <Tabs
          activeKey={tab}
          items={itemsTab}
          onChange={(newTab) => setTab(newTab)}
        />
      </div>

      <NewAdditionalField
        open={openAddDrawer}
        setOpen={setOpenAddDrawer}
        setAdditionalFields={setAdditionalFields}
      />
    </>
  );
};
