import { Button, Checkbox, Col, Form, Row, Switch, Transfer } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { Tag } from "../../tag/Tag";
import { FlowContext } from "../../../context/FlowContext";
import { getContactName } from "../campaigns/sendTemplates/utils/getContactName";

export const FlowAllowed = () => {
  const { etiquetas, contactos } = useContext(GlobalContext);

  const { setShowDrawerPermisos, permisosData, setPermisosData } =
    useContext(FlowContext);

  const [permisosTmp, setPermisosTmp] = useState({
    type: "contacts",
    content: [],
  });

  const [form] = Form.useForm();

  const [targetKeys, setTargetKeys] = useState([]);

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const getContactsOptions = () => {
    let array = [];

    if (contactos?.length > 0) {
      array = contactos.map((contacto) => {
        return {
          id: contacto._id,
          title: getContactName(contacto.name),
        };
      });
    }

    return array;
  };

  const filterOption = (inputValue, option) => {
    let inputLowerCase = inputValue.toLowerCase();

    if (option.title.toLowerCase().indexOf(inputLowerCase) > -1) {
      return true;
    }

    return false;
  };

  const onFinish = (v) => {
    let obj = { type: permisosTmp.type, content: v.tags || targetKeys };
    setPermisosData(obj);

    setShowDrawerPermisos(false);
  };

  useEffect(() => {
    setPermisosTmp({ ...permisosData });

    const { type, content } = permisosData;

    if (content?.length > 0) {
      if (type === "tags") {
        form.setFieldsValue({
          tags: content,
        });
      } else if (type === "contacts") {
        setTargetKeys(content);
      }
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="flow-control"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <b style={{ marginBottom: 16 }}>Permisos</b>
      <Switch
        style={{ width: "fit-content", marginBottom: 16 }}
        checked={permisosTmp.type === "tags"}
        checkedChildren="Etiquetas"
        unCheckedChildren="Contactos"
        onChange={(v) => {
          if (v) {
            setPermisosTmp({
              type: "tags",
              content: [],
            });
          } else {
            setPermisosTmp({
              type: "contacts",
              content: [],
            });
          }
        }}
      />
      {permisosTmp?.type === "tags" ? (
        <Form.Item name="tags" label="Etiquetas">
          <Checkbox.Group style={{ width: "100%" }}>
            <Row style={{ width: "100%" }}>
              {etiquetas?.map((tag) => {
                return (
                  <Col span={12} key={tag._id} className="columnas-etiquetas">
                    <Checkbox
                      value={tag._id}
                      style={{
                        margin: "4px 0px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                      checked={permisosTmp?.content?.includes(tag._id)}
                    >
                      <Tag hex={tag.color} nombre={tag.name} key={tag._id} />
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      ) : (
        <Form.Item name="contacts" label="Contactos">
          <Transfer
            style={{ width: "100%" }}
            dataSource={getContactsOptions() || []}
            showSearch
            filterOption={filterOption}
            onChange={handleChange}
            targetKeys={targetKeys}
            rowKey={(record) => record.id}
            listStyle={{
              width: "calc(50% - 20px)",
              height: "calc(100vh - 340px)",
              minHeight: "350px",
            }}
            locale={{
              itemUnit: "",
              itemsUnit: "",
              searchPlaceholder: "Buscar",
            }}
            titles={["Contactos", "Seleccionados"]}
            render={(item) => item.title}
          />
        </Form.Item>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setShowDrawerPermisos(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
