import dayjs from "dayjs";
import { markMessageAsRead } from "../../../../../helpers/fetchData/fetchChats";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import { FiFile, FiImage, FiMusic, FiPlayCircle } from "react-icons/fi";
import { MessageLocation } from "../MessageLocation";
import { PreviewTemplate } from "../../settings/content/templates/PreviewTemplate";
import { formatoTextHtml } from "../../../../../helpers/utilidadesTemplates";
import { Image } from "antd";
import axios from "axios";
import { Buffer } from "buffer";

export const updateLiveConversation = (
  message,
  setLiveConversation,
  chatMessages
) => {
  // Checkea si ya existe el mensaje, para no repetirlo
  if (chatMessages?.length > 0) {
    let ticketMessagesIds = chatMessages[
      chatMessages.length - 1
    ]?.messages?.map((element) => element._id);

    if (!ticketMessagesIds?.includes(message?._id)) {
      setLiveConversation((prevState) => {
        return [...prevState, { ...message, status: "read" }];
      });
    }
  } else {
    setLiveConversation((prevState) => {
      return [...prevState, { ...message, status: "read" }];
    });
  }
};

export const updateConversations = (
  message,
  setConversations,
  setNotAssignedTickets
) => {
  setConversations((prevState) => {
    let array = [...prevState];
    let chat = array?.find((chat) => chat._id === message.chatId);

    if (chat) {
      array = array?.filter((element) => element._id !== message.chatId);

      let obj = {
        ...chat,
        conversation: [...chat?.conversation, message],
      };

      array.unshift(obj);
    } else {
      //No está en chats, buscar en los no asignados;
      setNotAssignedTickets((tickets) => {
        let index = tickets.findIndex(
          (element) => element._id === message.chatId
        );

        if (index >= 0) {
          tickets[index] = {
            ...tickets[index],
            conversation: [...tickets[index]?.conversation, message],
          };
        }

        return tickets;
      });
    }

    return array;
  });
};

const switchContactVar = (obj, selectedConversation) => {
  if (obj.value.startsWith("contact_")) {
    if (!selectedConversation) {
      return { type: "text", text: "-" };
    }

    let type = obj.value.split("_")[1];

    let value = "";
    switch (type) {
      case "lastName":
        value = selectedConversation?.name?.split(" ")?.[0];
        break;
      case "firstName":
        value = selectedConversation?.name?.split(" ")?.[1];
        break;
      case "name":
        value = selectedConversation?.name;
        break;
      case "phone":
        value = selectedConversation?.phoneNumber;
        break;
      case "fullAddress":
        const { street, city, state, country } =
          selectedConversation.addresses?.[0] || {};
        value = `${street}, ${city}, ${state}, ${country}`;
        break;
      case "street":
        value = selectedConversation.addresses?.[0]?.street;
        break;
      case "city":
        value = selectedConversation.addresses?.[0]?.city;
        break;
      case "state":
        value = selectedConversation.addresses?.[0]?.state;
        break;
      case "country":
        value = selectedConversation.addresses?.[0]?.country;
        break;
      case "zip":
        value = selectedConversation.addresses?.[0]?.zip;
        break;
      case "email":
        value = selectedConversation?.emails?.[0]?.email;
        break;
      case "birthday":
        value = selectedConversation?.birthday;
        break;
      case "org":
        value = selectedConversation?.org?.company;
        break;
      default:
        value = "";
        break;
    }
    return { type: "text", value: value || "-" };
  }

  return obj;
};

export const replaceContactVars = (templateVariables, selectedConversation) => {
  let tmp = {};

  for (let section in templateVariables) {
    tmp[section] = {};
    tmp[section] = { ...templateVariables[section] };

    if (
      templateVariables[section] &&
      typeof templateVariables[section] === "object" &&
      "vars" in templateVariables[section]
    ) {
      tmp[section].vars = templateVariables[section].vars?.map((obj) => {
        let newObj = { ...obj };

        if (newObj.type === "var") {
          newObj = switchContactVar(newObj, selectedConversation);
        }

        if (!newObj.type || newObj.value == null) {
          throw new Error("Variable incompleta");
        }

        return newObj;
      });
    } else {
      tmp[section] = null;
    }
  }

  return tmp;
};

export const newChatOK = (
  res,
  setSelectedConversation,
  setActiveTabKey,
  selectedContact,
  instance
) => {
  if (res?.status === 200 && res?.data?.insertedId) {
    let obj = {};

    if (selectedContact?._id) {
      obj = { ...selectedContact };
    }

    obj = {
      ...obj,
      status: "OPEN",
      createdAt: Date.now(),
      type: "chat",
      closeDate: null,
      userId: instance?.userData?.userId,
      conversation: res.data?.conversation || [],
      _id: res.data.insertedId,
    };

    setSelectedConversation(obj);

    setActiveTabKey("conversations");
  }
};

export const formatChatData = (data) => {
  if (data?.data || data?.data?.length < 1) {
    let notes = [];

    const array = data?.data?.map((chat) => {
      let messages = [];

      if (Array.isArray(chat?.notes)) {
        messages = [...messages, ...chat.notes];

        notes = [...notes, ...chat.notes];
      }
      if (Array.isArray(chat?.conversation)) {
        messages = [...messages, ...chat.conversation];
      }

      messages = messages.map((element) => {
        if (element.timestamp?.length < 12) {
          element.timestamp = element.timestamp * 1000;
        }

        return element;
      });

      messages.sort((a, b) => a.timestamp - b.timestamp);

      return {
        ...chat,
        messages,
      };
    });

    let expirationTimestamp = data.expirationTimestamp?.expiration_timestamp;

    if (expirationTimestamp && expirationTimestamp?.length < 12) {
      expirationTimestamp = expirationTimestamp * 1000;
    }

    notes.sort((a, b) => b.timestamp - a.timestamp);

    let obj = {
      expirationTimestamp: expirationTimestamp || null,
      messages: array,
      notes: notes,
    };

    return obj;
  } else {
    return {};
  }
};

export const readLastMessage = (chatFormattedData, application) => {
  if (chatFormattedData?.messages) {
    let mensajesUsuario =
      chatFormattedData.messages[
        chatFormattedData.messages.length - 1
      ]?.messages?.filter((message) => {
        if (message.userType !== "agent" && message?.id) {
          return true;
        } else {
          return false;
        }
      }) || [];

    if (mensajesUsuario.length > 0) {
      const message = mensajesUsuario[mensajesUsuario.length - 1];

      if (application?.[0]) {
        markMessageAsRead(message?.id, application)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
};

export const isExpired = (
  expirationTimestamp,
  selectedConversation,
  isConversationExpired
) => {
  if (expirationTimestamp === null) return true;

  let timeStamp = expirationTimestamp;
  if (timeStamp?.length < 12) {
    timeStamp = timeStamp * 1000;
  }

  if (isConversationExpired) return true;
  const now = Number(dayjs().format("x"));
  const lastDay = Number(dayjs().format("x") - 24 * 60 * 60 * 1000);
  if (timeStamp <= Number(now)) return true;

  //! Revisar si hace falta, en caso de que no tenga conversations capaz que si
  if (selectedConversation?.createdAt <= lastDay) return true;

  return false;
};

export const renderInput = (
  selectedConversation,
  isNota,
  conversationExpired,
  liveConversation
) => {
  // if (!selectedConversation?._id) return false;
  // if (selectedConversation?.type !== "chat") return false;
  if (selectedConversation?.status?.toUpperCase() === "NOT ASSIGNED")
    return false;
  if (isNota) return true;
  if (selectedConversation?.status === "CLOSED") return false;

  if (liveConversation?.length > 0) {
    // checkear que haya llegado un mensaje del cliente
    let clientMessages = liveConversation?.filter((element) => {
      if (element.userType !== "agent" && !element.isNota) {
        return true;
      } else {
        return false;
      }
    });

    let lastMessage = clientMessages?.[clientMessages?.length - 1];

    if (lastMessage) {
      const lastDay = dayjs().format("x") - 4 * 60 * 60 * 1000;

      let messageTimeStamp = lastMessage.timestamp;

      if (messageTimeStamp.length < 12) {
        messageTimeStamp = messageTimeStamp * 1000;
      }

      if (messageTimeStamp >= lastDay) {
        return true;
      }
    }
  }

  if (conversationExpired) return false;

  return true;
};

export const setChatsData = (data, setConversations, setNotAssignedTickets) => {
  const { own, shared } = data;
  if (Array.isArray(own)) {
    let arrayOrdenado = [...own];

    arrayOrdenado = arrayOrdenado.sort((a, b) => {
      let timestampA = Number(
        a.conversation?.[a.conversation?.length - 1]?.timestamp
      );
      let timestampB = Number(
        b.conversation?.[b.conversation?.length - 1]?.timestamp
      );

      if (timestampA.length > 10) {
        timestampA = Math.floor(timestampA / 1000);
      }
      if (timestampB.length > 10) {
        timestampB = Math.floor(timestampB / 1000);
      }

      if (timestampA < timestampB) {
        return 1;
      }
      if (timestampA > timestampB) {
        return -1;
      }
      return 0;
    });

    notSeenMessages(arrayOrdenado);

    setConversations(arrayOrdenado);
  }

  if (Array.isArray(shared)) {
    let arrayOrdenado = [...shared];

    arrayOrdenado = arrayOrdenado.sort((a, b) => {
      let timestampA = Number(
        a.conversation?.[a.conversation?.length - 1]?.timestamp
      );
      let timestampB = Number(
        b.conversation?.[b.conversation?.length - 1]?.timestamp
      );

      if (timestampA.length > 10) {
        timestampA = Math.floor(timestampA / 1000);
      }
      if (timestampB.length > 10) {
        timestampB = Math.floor(timestampB / 1000);
      }

      if (timestampA < timestampB) {
        return 1;
      }
      if (timestampA > timestampB) {
        return -1;
      }
      return 0;
    });

    setNotAssignedTickets(arrayOrdenado);
  }
};

export const lastMessageContent = (element) => {
  const message = element.conversation?.[element.conversation?.length - 1];
  if (message) {
    if (message?.text?.body) {
      return message.text.body;
    } else {
      switch (message.type?.toLowerCase()) {
        case "image":
          return "Imagen";
        case "audio":
          return "Audio";
        case "video":
          return "Video";
        case "location":
          return "Imagen";
        case "template":
          return "Plantilla";
        default:
          return null;
      }
    }
  } else {
    return null;
  }
};

const getNotSeenArray = () => {
  let localStorageData = localStorage.getItem("notSeen");

  if (localStorageData) {
    try {
      localStorageData = JSON.parse(localStorageData);
      if (!Array.isArray(localStorageData)) {
        localStorageData = [];
      }
    } catch (error) {
      console.log(error);
      localStorageData = [];
    }
  }

  return localStorageData;
};

export const notSeenMessages = (chats) => {
  const chatIds = chats.map((element) => {
    return element._id;
  });

  let localStorageData = getNotSeenArray();

  let updatedData = [];

  chatIds?.forEach((chat) => {
    let obj = localStorageData?.find((element) => element?.chatId === chat);

    if (obj) {
      updatedData.push(obj);
    } else {
      updatedData.push({ chatId: chat, timestamp: null });
    }
  });

  localStorage.setItem("notSeen", JSON.stringify(updatedData));
};

export const updateNotSeenMessages = (selectedConversation) => {
  if (selectedConversation?._id) {
    let localStorageData = getNotSeenArray();

    const index = localStorageData.findIndex(
      (element) => element.chatId === selectedConversation?._id
    );

    if (index >= 0) {
      let timestamp = dayjs().format("x");
      localStorageData[index] = {
        ...localStorageData[index],
        timestamp: timestamp,
      };
    }

    localStorage.setItem("notSeen", JSON.stringify(localStorageData));
  }
};

export const renderNewMessagesCount = (selectedConversation) => {
  if (selectedConversation?._id && selectedConversation?.conversation) {
    let localStorageData = getNotSeenArray();

    const obj = localStorageData.find(
      (chat) => chat.chatId === selectedConversation?._id
    );

    let count = 0;

    if (obj?.chatId) {
      selectedConversation?.conversation.forEach((element) => {
        if (element.userType !== "agent" && element.timestamp > obj.timestamp) {
          count += 1;
        }
      });
    }

    return count;
  }

  return 0;
};

export const findUser = (userId, usuarios) => {
  if (!usuarios || !userId) {
    return null;
  }

  const usuario = usuarios.find((element) => element._id === userId);
  const index = usuarios.findIndex((element) => element._id === userId);

  return { ...usuario, index: index };
};

export const findTeam = (userId, teams) => {
  if (!teams || !userId) {
    return null;
  }

  const team = teams?.find((element) => element.users.includes(userId));

  if (team) {
    return team.name;
  }

  return null;
};

export const scrollTo = (id) => {
  //! Tener en cuenta que el ticket puede no estar renderizandoce.
  //! Renderizar y luego scrollar?;
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const renderMessageStatus = (status) => {
  switch (status) {
    case "sent":
      return (
        <span style={{ height: "18px", display: "flex" }}>
          <BsCheck color="#d8d8d8" size={16} />
        </span>
      );
    case "delivered":
      return (
        <span style={{ height: "18px", display: "flex" }}>
          <BsCheckAll color="#d8d8d8" size={16} />
        </span>
      );
    case "read":
      return (
        <span style={{ height: "18px", display: "flex" }}>
          <BsCheckAll color="#009de2" size={16} />
        </span>
      );
    default:
      return <></>;
  }
};

const ICON_SIZE = 100;

export const META_API_VERSION = "v19.0";

export const getImage = (data) => {
  switch (data?.type) {
    case "image":
      return <FiImage color="white" size={ICON_SIZE} />;
    case "video":
      return <FiPlayCircle color="white" size={ICON_SIZE} />;
    case "document":
      return <FiFile color="white" size={ICON_SIZE} />;
    case "audio":
      return <FiMusic color="white" size={ICON_SIZE} />;
    case "location":
      // return <FiMapPin color="white" size={ICON_SIZE} />;
      return (
        <MessageLocation
          marker={{
            longitude: -63.244947,
            latitude: -32.410553,
            zoom: 15,
          }}
          viewport={{
            longitude: -63.244947,
            latitude: -32.410553,
            zoom: 15,
          }}
        />
      );
    case "template":
      if (data.template?.templateVariables) {
        const { templateVariables } = data.template;
        return (
          <PreviewTemplate
            ambito={`chat-${data.id}`}
            // inputText={inputText}
            bodyContent={formatoTextHtml(templateVariables.body?.value)}
            buttonsContent={templateVariables?.buttons || []}
            footerContent={templateVariables.footer?.value}
            headerContent={templateVariables.header?.value}
            headerMediaType={templateVariables.header?.type}
            headerType={
              templateVariables.header?.type?.toUpperCase() === "TEXT"
                ? "text"
                : "media"
            }
            renderTemplateButtons={false}
            interactions={null}
            edges={[]}
            node={{}}
          />
        );
      } else {
        return null;
      }
    default:
      return <FiImage color="white" size={ICON_SIZE} />;
  }
};

export const handlePreview = (
  data,
  application,
  setLoading,
  instance,
  setPreview
) => {
  let media = data?.[data.type];

  const id = media?.id;

  if (!id) {
    // messageApi.error("No existe id del archivo");
    return;
  }

  const treintaDiasSegundos = Date.now() - 2592000000;

  if (data?.timestamp < treintaDiasSegundos) {
    // Si tiene mas de 30dias no vale la pena buscar en META;
    return;
  }

  let config = {
    method: "get",
    url: `https://graph.facebook.com/${META_API_VERSION}/${id}`,
    headers: {
      Authorization: `Bearer ${application?.[0]?.token}`,
    },
  };

  setLoading(true);

  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${application?.[0]?.token}`);

  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", "true");
  myHeaders.append("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
  myHeaders.append(
    "Access-Control-Allow-Headers",
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization"
  );

  axios
    .request(config)
    .then((res) => {
      if (res?.status === 200) {
        let mime_type = res.data?.mime_type;

        if (res.data?.url) {
          axios
            .request({
              method: "post",
              url: `${process.env.REACT_APP_API_PRODUCCION}/chat/metaFile`,
              headers: {
                Authorization: instance?.userData?.apiKey,
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                url: res.data.url,
                token: `Bearer ${application?.[0]?.token}`,
              }),
            })
            .then((res) => {
              if (res?.status === 200) {
                if (res.data?.data?.data) {
                  let base64 = Buffer.from(
                    res.data.data.data,
                    "binary"
                  ).toString("base64");

                  base64 = `data:${mime_type};base64,${base64}`;

                  setPreview(base64);
                }
              }
            })
            .catch((error) => console.log("error", error));
        }
      }
    })
    .catch((error) => console.log(error))
    .finally(() => setLoading(false));
};

export const renderPreview = (data, preview) => {
  switch (data.type) {
    case "image":
      return (
        <Image
          className="image-preview"
          src={preview}
          alt="preview"
          width="calc(100%)"
          height={preview ? "auto" : "200px"}
          preview={true}
        />
      );
    case "document":
      return (
        <span
          className="horizontal"
          style={{ width: "100%", overflow: "hidden", wordBreak: "normal" }}
        >
          <iframe
            className="image-preview"
            title="pdf-preview"
            src={preview}
            width="calc(100%)"
            height="200px"
          />
        </span>
      );
    case "video":
      return (
        <video
          controls
          className="image-preview"
          src={preview}
          width="calc(100%)"
          height="200px"
        />
      );
    case "audio":
      return (
        <span
          style={{
            width: "fit-content",
            minWidth: "250px",
            position: "relative",
          }}
        >
          <audio
            controls
            src={preview}
            style={{
              width: "100%",
              backgroundColor: "#f1f3f4",
              borderRadius: "8px",
              display: "flex",
            }}
          />
        </span>
      );
    default:
      return null;
  }
};

export const decorarTextoChat = (wrappingString, emoji, setText) => {
  let textArea = document.getElementById("chatText");

  if (textArea) {
    let s = textArea.selectionStart;
    let e = textArea.selectionEnd;

    let oldValue = textArea.value;

    if (
      wrappingString !== "" &&
      wrappingString !== "${" &&
      wrappingString !== "emoji"
    ) {
      let newValue =
        oldValue.slice(0, s) +
        wrappingString +
        oldValue.slice(s, e) +
        wrappingString +
        oldValue.slice(e, oldValue.length);
      textArea.value = newValue;
    }

    if (wrappingString === "emoji") {
      textArea.value = oldValue + emoji.emoji;
    }

    setText(textArea.value);
  }
};

export const savedMessagesTreeData = (data) => {
  if (data?.length > 0) {
    return data.map((grupo, indexGrupo) => {
      return {
        title: (
          <span
            className="horizontal"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <p>{grupo.name}</p>
            <p style={{ fontSize: "13px", color: "#aaa" }}>
              {grupo.savedReplies?.length}
            </p>
          </span>
        ),
        key: `${indexGrupo}`,
        children: grupo.savedReplies?.map((mensaje, indexMensaje) => {
          return { title: mensaje.name, key: `${indexGrupo}-${indexMensaje}` };
        }),
      };
    });
  } else {
    return [];
  }
};

export const handleTreeSelect = (
  e,
  savedMessages,
  setInnerText,
  setShowSavedMessages
) => {
  if (e?.node?.key) {
    const array = e.node.key.split("-");

    if (array.length === 2) {
      const mensaje =
        savedMessages?.[Number(array[0])]?.savedReplies?.[Number(array[1])];

      if (mensaje?.value) {
        setInnerText(mensaje.value);
        setShowSavedMessages(false);
      }
    }
  }
};

export const scrollToBottom = (ref, context) => {
  let delay = 1500;

  if (context === "live") {
    delay = 300;
  }

  setTimeout(() => {
    const lastChildElement = ref.current?.lastElementChild;

    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  }, delay);
};

export const focusChatInput = () => {
  setTimeout(() => {
    const inputElement = document.getElementById("chatText");
    inputElement?.focus();
  }, 4000);
};
