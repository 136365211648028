import dayjs from 'dayjs';

export const formatContactData = (data) => {
  const {
    birthday,
    city,
    company,
    country,
    department,
    email,
    first_name,
    last_name,
    middle_name,
    phone,
    state,
    street,
    tags,
    title,
    type,
    zip,
    ...rest
  } = data;

  let newData = {
    addresses: [
      {
        country,
        state,
        city,
        street,
        zip,
        type
      }
    ],
    birthday: birthday ? dayjs(birthday).format('DD/MM/YYYY') : '',
    emails: [
      {
        email,
        type: 'WORK'
      }
    ],
    name: {
      first_name,
      last_name,
      middle_name
    },
    org: {
      company,
      department,
      title
    },
    phones: [
      {
        type: 'WORK',
        phone
      }
    ],
    tags,
    ...rest
  };

  return newData;
};
