import { Button, Drawer, Spin } from "antd";
import { FiEdit3, FiPlus, FiUsers } from "react-icons/fi";
import { MdOutlineWorkspaces } from "react-icons/md";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { getTeams } from "../../../../../../helpers/fetchData/fetchTeams";
import { LoadingOutlined } from "@ant-design/icons";
import { NewTeam } from "../drawerContent/NewTeam";
import { accessDataFormat } from "../../../../../../helpers/access";
import { useAccess } from "../../../../../../hooks/useAccess";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const Teams = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState({ open: false, item: null });

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getTeams(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res?.data)) {
              setTeams(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener equipos");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener equipos")
        )
        .finally(() => setLoading(false));
    }
  }, [instance]);

  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <MdOutlineWorkspaces size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Equipos</span>
            </span>
            <p className="header-subtitle">Crea grupos de usuarios</p>
          </span>
          <div className="espacio-botones-contactos">
          {isAllowed(accessDataFormat("teams", "manage")) && (
            <Button
              className="btn-agregar boton-dashed"
              // type="primary"
              onClick={() =>
                setShowDrawer({
                  open: true,
                  item: null,
                })
              }
            >
              <FiPlus /> Equipo
            </Button>
          )}
          </div>
        </span>
      </div>
      <div className="horizontal" style={{ flexWrap: "wrap" }}>
        {loading ? (
          <span
            style={{
              display: "flex",
              height: 200,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingIcon size="large" />
          </span>
        ) : (
          teams?.map((element) => {
            return (
              <span className="team-wrapper fade-in" key={element.name}>
                <span
                  className="horizontal-between"
                  style={{ marginBottom: "16px", gap: "180px" }}
                >
                  <FiUsers size={22} />
                  {isAllowed(accessDataFormat("teams", "manage")) && (
                  <FiEdit3
                    className="pointer"
                    size={20}
                    onClick={() =>
                      setShowDrawer({
                        open: true,
                        item: element,
                        edit: true,
                      })
                    }
                  />
                  )}
                </span>
                <p style={{ fontWeight: 500 }}>{element.name}</p>
                <span className="horizontal" style={{ color: "#8a8c8f" }}>
                  <p>{`${element.users?.length} miembros`}</p>
                  {/* <p>{`${element.channels?.length} canales`}</p> */}
                </span>
              </span>
            );
          })
        )}
      </div>
      <Drawer open={showDrawer?.open} width={600} destroyOnClose>
        <NewTeam
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          setTeams={setTeams}
        />
      </Drawer>
    </div>
  );
};
