import { Button, Form, Input, Radio, message } from "antd";
import {
  RADIO_OPTIONS,
  ROLE_PERMISSIONS,
  ROLE_PERMISSIONS_MODULES,
  ROLE_PERMISSIONS_SETTINGS,
} from "../../utils/users";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import {
  createRole,
  updateRole,
} from "../../../../../../helpers/fetchData/fetchTeams";
import { accessDataFormat } from "../../../../../../helpers/access";
import { useAccess } from "../../../../../../hooks/useAccess";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const RoleDetail = ({ setShowRoleDetail, showRoleDetail, setRoles }) => {
  const [form] = Form.useForm();

  const { instance, messageApi } = useContext(GlobalContext);

  const [permissions, setPermissions] = useState(ROLE_PERMISSIONS);

  const isAllowed = useAccess();

  const [loading, setLoading] = useState(false);

  const handlePermissions = (id, v) => {
    setPermissions((prevState) => {
      let object = { ...prevState, [id]: [v.target.value] };

      return object;
    });
  };

  const onFinish = async (v) => {
    let name = v.name?.trim() || "";
    let description = v.description?.trim() || "";

    if (!name) {
      messageApi.info("Es necesario que ingrese un nombre");
    }

    if (permissions?.settings[0] === 0) {
      await allSettingsHide();
    }

    let data = {
      name,
      description,
      permissions,
    };

    setLoading(true);
    if (showRoleDetail?._id) {
      let obj = {
        roleId: showRoleDetail._id,
        role: data,
      };

      if (showRoleDetail?.name === "ADMIN") {
        messageApi.info("El rol ADMIN no puede ser editado");
        return;
      }

      updateRole(instance?.userData?.apiKey, obj)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data?._id) {
              setRoles((prevState) => {
                let array = [...prevState];

                let index = array.findIndex(
                  (element) => element._id === res.data?._id
                );

                if (index >= 0) {
                  array[index] = res.data;
                }

                return array;
              });
              messageApi.success("Rol editado correctamente");
              setShowRoleDetail(null);
            } else {
              messageApi.error(res.data?.error);
            }
          } else {
            messageApi.error("Ocurrió un error al editar un rol");
          }
        })
        .catch((err) => messageApi.error("Ocurrió un error al editar un rol"))
        .finally(() => setLoading(false));
    } else {
      createRole(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data?._id) {
              setRoles((prevState) => {
                let array = [];
                if (prevState?.length > 0) {
                  array = [...prevState];
                }

                array.push(res.data);

                return array;
              });
              messageApi.success("Rol creado correctamente");
              setShowRoleDetail(null);
            } else {
              messageApi.error(res.data?.error);
            }
          } else {
            messageApi.error("Ocurrió un error al crear un rol");
          }
        })
        .catch((err) => {
          console.log(err);
          messageApi.error("Ocurrió un error al crear un rol");
        })
        .finally(() => setLoading(false));
    }
  };

  // Funcion que pone todos los permisos de Módulo configuración en 0.
  const allSettingsHide = async () => {

    await new Promise(resolve => { // Para frenar la ejecucion hasta que se complete el setState.
      setPermissions(prevState => {
        let objeto = { ...prevState };
  
        for (let key in objeto) {
          if (objeto.hasOwnProperty(key)) {
            if (ROLE_PERMISSIONS_SETTINGS?.find(permission => permission.id === key)) {
              objeto[key][0] = 0;
            }
          }
        }
        resolve(objeto); // Resuelve la promesa después de actualizar el estado
        return objeto;
      });
    });

  };

  useEffect(() => {
    if (showRoleDetail?._id) {
      setPermissions(showRoleDetail.permissions);
    }
  }, [showRoleDetail]);

  return (
    <div className="role-detail-wrapper">
      <span
        className="horizontal-between"
        style={{ marginBottom: 8, maxWidth: 500 }}
      >
        <b style={{ fontSize: 18 }}>{showRoleDetail.name || "Crear rol"}</b>
        <Button
          type="secondary"
          className="btn-oscuro"
          onClick={() => setShowRoleDetail(null)}
        >
          Volver
        </Button>
      </span>
      <Form
        form={form}
        name="role-permissions"
        layout="vertical"
        onFinish={(v) => onFinish(v)}
        requiredMark={false}
        disabled={permissions.name === "ADMIN"}
        className="form-roles"
      >
        <Form.Item
          label="Nombre"
          name="name"
          initialValue={showRoleDetail.name}
        >
          <Input maxLength={20} showCount />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          initialValue={showRoleDetail.description}
        >
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <p
          style={{ margin: "32px 0px 16px 0px", fontSize: 16, fontWeight: 500 }}
        >
          Módulos
        </p>
        {ROLE_PERMISSIONS_MODULES.map((element) => {
          return (
            <span
              className="horizontal-between"
              style={{
                padding: "16px 0px",
                maxWidth: 500,
              }}
              key={element.id}
            >
              <p style={{ fontWeight: 500, fontSize: 13 }}>{element.label}</p>
              <Radio.Group
                options={RADIO_OPTIONS}
                optionType="button"
                buttonStyle="solid"
                size="small"
                value={permissions[element.id]?.[0]}
                onChange={(v) => handlePermissions(element.id, v)}
              />
            </span>
          );
        })}
        {permissions.settings[0] > 0 && (
          <>
            <p
              style={{
                margin: "32px 0px 16px 0px",
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Módulo configuración
            </p>
            {ROLE_PERMISSIONS_SETTINGS.map((element) => {
              return (
                <span
                  className="horizontal-between"
                  style={{
                    padding: "16px 0px",
                    maxWidth: 500,
                  }}
                  key={element.id}
                >
                  <p style={{ fontWeight: 500, fontSize: 13 }}>
                    {element.label}
                  </p>
                  <Radio.Group
                    options={RADIO_OPTIONS}
                    optionType="button"
                    buttonStyle="solid"
                    size="small"
                    value={permissions[element.id]?.[0]}
                    onChange={(v) => handlePermissions(element.id, v)}
                  />
                </span>
              );
            })}
          </>
        )}
        <Form.Item style={{ marginTop: 32 }}>
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              // size="large"
              block
              //loading={loading}
              icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
              style={{ opacity: loading ? 0.65 : 1 }}
              disabled={!isAllowed(accessDataFormat("users", "manage"))}
            >
              Guardar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
