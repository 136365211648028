import { Drawer } from 'antd';
import { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { ShowContact } from './contentDrawer/ShowContact';
import { EditTag } from './contentDrawer/EditTag';
import { ManageTagContacts } from './contentDrawer/ManageTagContacts';
import { ContactEditor } from '../../drawers/ContactEditor';

const RightDrawer = () => {
  const { rightDrawerConfig, setRightDrawerConfig } = useContext(GlobalContext);

  const handleContent = () => {
    const { content } = rightDrawerConfig;

    switch (true) {
      case content === 'Ver Contacto':
        return <ShowContact />;
      case content === 'Editar Contacto':
        return <ContactEditor editMode />;
      case content === 'Editar Etiqueta':
        return <EditTag />;
      case content === 'Manejar Grupos':
        return <ManageTagContacts />;
      default:
        break;
    }
  };

  return (
    <Drawer
      width={rightDrawerConfig?.content === 'Manejar Grupos' ? 600 : 500}
      destroyOnClose
      maskClosable={true}
      placement={'right'}
      closable={true}
      closeIcon={null}
      onClose={() => setRightDrawerConfig({ visible: false, content: null })}
      open={rightDrawerConfig.visible}
      key={'rightDrawer'}
      styles={{ header: { display: 'none' }, body: { padding: '24px' } }}
    >
      {handleContent()}
    </Drawer>
  );
};

export default RightDrawer;
