export const FORMULAS = [
  {
    label: 'Operaciones matemáticas',
    value: 'math',
    formulas: [
      { value: 'sum', label: 'Suma dos valores', multiple: true },
      {
        value: 'subtract',
        label: 'Resta el segundo valor del primero',
        multiple: true
      },
      { value: 'times', label: 'Multiplica dos valores', multiple: true },
      {
        value: 'divide',
        label: 'Divide el primer valor por el segundo',
        multiple: true
      },
      { value: 'toFloat', label: 'Convierte el valor a punto flotante' },
      { value: 'toInteger', label: 'Convierte el valor a entero' },
      {
        value: 'quotient',
        label: 'Devuelve la parte entera de la división',
        multiple: true
      },
      {
        value: 'remainder',
        label: 'Devuelve el resto de la división',
        multiple: true
      },
      { value: 'round', label: 'Redondea el valor al entero más cercano' }
    ]
  },
  {
    label: 'Operaciones de comparación',
    value: 'compare',
    formulas: [
      {
        value: 'equal',
        label: 'Verifica si dos valores son iguales',
        multiple: true
      },
      {
        value: 'notEqual',
        label: 'Verifica si dos valores no son iguales',
        multiple: true
      },
      {
        value: 'greater',
        label: 'Verifica si el primer valor es mayor que el segundo',
        multiple: true
      },
      {
        value: 'greaterOrEqual',
        label: 'Verifica si el primer valor es mayor o igual al segundo',
        multiple: true
      },
      {
        value: 'less',
        label: 'Verifica si el primer valor es menor que el segundo',
        multiple: true
      },
      {
        value: 'lessOrEqual',
        label: 'Verifica si el primer valor es menor o igual al segundo',
        multiple: true
      }
    ]
  }
];
