import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { getInnerMetrics } from "../../../../helpers/fetchData/fetchCampaigns";
import dayjs from "dayjs";
import { Base64 } from "js-base64";
import {
  formatearEstados,
  formatearInteracciones,
} from "./sendTemplates/utils/metricsFormat";
import { ButtonsGraph } from "./campaignData/ButtonsGraph";
import { ButtonsBarGraph } from "./campaignData/ButtonsBarGraph";
import {
  Button,
  Checkbox,
  DatePicker,
  Popover,
  Progress,
  Select,
  Space,
  Spin,
  Statistic,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  datePickerFormat,
  datePickerOptions,
  getPorcentaje,
  statsProps,
  statusOptions,
} from "./utils";

export const CampaignData = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [estados, setEstados] = useState(["sent", "delivered", "read"]);
  const [data, setData] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState({
    status: true,
    interaction: true,
  });
  const [datePickerType, setDatePickerType] = useState("week");
  const [datePickerValue, setDatePickerValue] = useState(dayjs());

  const handleLoading = (type, value) => {
    setLoading((prevState) => {
      return { ...prevState, [type]: value };
    });
  };

  const decodedData = useMemo(() => {
    try {
      const search = location.search;

      const data = new URLSearchParams(search).get("data");
      if (data) {
        let parsedData = JSON.parse(Base64.decode(data))

        return parsedData;
      }
    } catch (error) {
      console.error(error);
      messageApi.error("No se pudo obtener información sobre la campaña");
      setLoading({ status: false, interaction: false });
    }
  }, []);

  useEffect(() => {
    const data = decodedData;

    if (data?._id && instance?.userData?.apiKey) {
      getInnerMetrics(instance.userData.apiKey, data._id)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              setOriginalData(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener data de la campaña");
          }
        })
        .catch((error) => {
          console.error(error);
          messageApi.error("Ocurrió un error al obtener data de la campaña");
        });
    }
  }, [instance]);

  useEffect(() => {
    if (Array.isArray(originalData) && originalData?.length > 0) {
      setData({
        interactions: formatearInteracciones(originalData),
        metric: formatearEstados(originalData),
      });

      handleLoading({ status: false, interaction: false });
    } else {
      setLoading({ status: false, interaction: false });
    }
  }, [originalData, datePickerType, datePickerValue]);

  const resumen = useMemo(() => {
    if (data.metric) {
      return data.metric.reduce(
        (total, envio) => {
          return {
            sent: (total.sent += envio?.sent || 0),
            delivered: (total.delivered += envio?.delivered || 0),
            read: (total.read += envio?.read || 0),
            contacts: total.contacts,
          };
        },
        {
          sent: 0,
          delivered: 0,
          read: 0,
          contacts: decodedData?.contactsCount || 0,
        }
      );
    } else {
      return { sent: 0, delivered: 0, read: 0, contacts: 0 };
    }
  }, [data]);

  return (
    <div className="main-wrapper">
      <div className="main-header">
        <span>{decodedData?.name}</span>
        <span className="horizontal gap16">
          <Popover
            content={
              <div>
                <Checkbox.Group
                  value={estados}
                  style={{ display: "flex", flexDirection: "column" }}
                  options={statusOptions}
                  onChange={(v) => setEstados(v)}
                />
              </div>
            }
            arrow={false}
          >
            <Button>
              <Space>
                Estados
                <DownOutlined />
              </Space>
            </Button>
          </Popover>
          <span className="horizontal" style={{ gap: 4 }}>
            <Select
              style={{ width: 100 }}
              options={datePickerOptions}
              value={datePickerType}
              onChange={(v) => setDatePickerType(v)}
            />
            <DatePicker
              picker={datePickerType}
              format={datePickerFormat[datePickerType]}
              onChange={(v) => setDatePickerValue(v)}
              value={datePickerValue}
            />
          </span>
        </span>
        <div className="espacio-botones-contactos">
          <Button
            type="secondary"
            className="btn-cancelar"
            onClick={() => navigate("/mensajes")}
          >
            Volver
          </Button>
        </div>
      </div>
      <div className="horizontal stats-header">
        <span className="columna-estadisticas">
          <Statistic
            title="Contactos"
            value={resumen?.contacts}
            {...statsProps}
          />
        </span>
        <span className="columna-estadisticas">
          <Statistic
            title="Enviados"
            value={resumen?.sent}
            suffix={getPorcentaje("sent", resumen)}
            {...statsProps}
          />
        </span>
        <span className="columna-estadisticas">
          <Statistic
            title="Entregados"
            value={resumen?.delivered}
            suffix={getPorcentaje("delivered", resumen)}
            {...statsProps}
          />
        </span>
        <span className="columna-estadisticas" style={{ borderRight: "none" }}>
          <Statistic
            title="Leídos"
            value={resumen?.read}
            suffix={getPorcentaje("read", resumen)}
            {...statsProps}
          />
        </span>
      </div>
      <div className="tabla-contactos" style={{ padding: 8 }}>
        <div className="columna gap16">
          <Progress percent={400} />
          {/* <div
            className="campaign-graph-wrapper"
            style={{
              height: 250,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading.status ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: "20px",
                    }}
                    spin
                  />
                }
              />
            ) : (
              <MetricsGraphs data={data.metric || []} />
            )}
          </div> */}
          <div
            className="campaign-graph-wrapper"
            style={{
              gap: 16,
              height: "auto",
            }}
          >
            <b style={{ marginLeft: "5%", fontSize: 18, marginTop: 8 }}>
              Interacciones con botones
            </b>
            <span
              className="horizontal"
              style={{
                width: "100%",
                height: 250,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading.interaction ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: "20px",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <>
                  <span style={{ height: "100%", width: "30%", minWidth: 150 }}>
                    <ButtonsGraph data={data?.interactions || []} />
                  </span>
                  <span style={{ height: "100%", flex: 1 }}>
                    <ButtonsBarGraph
                      data={data?.interactions || []}
                      datePickerType={datePickerType}
                    />
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
