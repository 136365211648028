import { Button, Tabs } from "antd";
import { TbMessage } from "react-icons/tb";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { FiPlus } from "react-icons/fi";
import { DefaultTemplate } from "./DefaultTemplate";
import { TicketTypes } from "./TicketTypes";
import { ClosingTypes } from "./ClosingTypes";
import { CloudStorage } from "./CloudStorage";

export const ChatConfig = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [showForm, setShowForm] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [showDrawerNuevo, setShowDrawerNuevo] = useState(false);
  const [drawerData, setDrawerData] = useState({ open: false, item: null }); // closing types

  const itemsTabs = [
    {
      key: 1,
      label: "Plantilla por defecto",
      children: (
        <DefaultTemplate showForm={showForm} setShowForm={setShowForm} />
      ),
    },
    {
      key: 2,
      label: "Tipos de ticket",
      children: (
        <TicketTypes
          showDrawerNuevo={showDrawerNuevo}
          setShowDrawerNuevo={setShowDrawerNuevo}
        />
      ),
    },
    {
      key: 3,
      label: "Tipos de cierre",
      children: (
        <ClosingTypes drawerData={drawerData} setDrawerData={setDrawerData} />
      ),
    },
    {
      key: 4,
      label: "Almacenamiento (archivos)",
      children: (
        <CloudStorage drawerData={drawerData} setDrawerData={setDrawerData} />
      ),
    },
  ];

  function handleChangeTab(tab) {
    setActiveKey(tab);
  }

  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <TbMessage size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Chat</span>
            </span>
            <p className="header-subtitle">Configure sus preferencias</p>
          </span>
          <div className="espacio-botones-contactos">
            {activeKey === 1 && (
              <Button
                className="btn-agregar boton-dashed fade-in"
                onClick={() => setShowForm(true)}
              >
                Plantilla por defecto
              </Button>
            )}
            {activeKey === 2 && (
              <Button
                className="btn-agregar boton-dashed fade-in"
                onClick={() => setShowDrawerNuevo(true)}
              >
                <FiPlus /> Tipo de ticket
              </Button>
            )}

            {activeKey === 3 && (
              <Button
                className="btn-agregar boton-dashed fade-in"
                onClick={() => setDrawerData({ open: true, item: null })}
              >
                <FiPlus /> Tipo de cierre
              </Button>
            )}
          </div>
        </span>
      </div>

      <div className="conf-content" style={{ maxWidth: 800 }}>
        <Tabs
          items={itemsTabs}
          onChange={handleChangeTab}
          style={{ maxWidth: 2000 }}
        />
      </div>
    </div>
  );
};
