import { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Form, Input, Select } from 'antd';
import { GlobalContext } from '../../../context/GlobalContext';

export const GeneralInfo = ({ editMode, form }) => {
  const { rightDrawerConfig } = useContext(GlobalContext);

  const { item } = rightDrawerConfig;

  useEffect(() => {
    if (item && editMode) {
      form.setFieldsValue({
        first_name: item.name?.first_name || '',
        middle_name: item.name?.middle_name || '',
        last_name: item.name?.last_name || '',
        birthday: item.birthday
          ? dayjs(item.birthday, 'DD/MM/YYYY')
          : undefined,
        company: item.org?.company || '',
        department: item.org?.department || '',
        title: item.org?.title || '',
        phone: item.phones?.[0]?.phone || '',
        email: item.emails?.[0]?.email || ''
      });

      if (item.addresses?.[0]) {
        let direccion = item.addresses[0];
        form.setFieldsValue({
          country: direccion.country || '',
          state: direccion.state || '',
          city: direccion.city || '',
          street: direccion.street || '',
          type: direccion.type.toLowerCase() || 'home',
          zip: direccion.zip || ''
        });
      }
    }
  }, [form, editMode, item]);

  return (
    <Form
      form={form}
      name='editGeneralInfo'
      layout='vertical'
      className='form-nodos'
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Información general</p>
      <span className='horizontal gap8'>
        <Form.Item
          label='Nombre'
          name='first_name'
          className='half-50'
          rules={[
            {
              required: true,
              message: 'Nombre requerido'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Segundo nombre'
          name='middle_name'
          className='half-50'
        >
          <Input />
        </Form.Item>
      </span>
      <span className='horizontal gap8'>
        <Form.Item
          label='Apellido'
          name='last_name'
          className='half-50'
          rules={[
            {
              required: true,
              message: 'Apellido requerido'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Fecha de nacimiento'
          name='birthday'
          className='half-50'
          initialValue={null}
        >
          <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
        </Form.Item>
      </span>
      <Form.Item
        label='Teléfono'
        name='phone'
        rules={[
          {
            pattern: new RegExp(/^[0-9]*$/),
            message:
              'No se permiten espacios ni caracteres especiales, solo números'
          },
          {
            min: 11,
            max: 12,
            message:
              'El número debe tener 11-12 dígitos. Por ejemplo: 543535123456'
          },
          {
            required: true,
            message: 'Teléfono requerido'
          }
        ]}
      >
        <Input placeholder='543535362409' />
      </Form.Item>
      <Form.Item label='Email' name='email'>
        <Input />
      </Form.Item>
      <Form.Item label='Organización' name='company'>
        <Input />
      </Form.Item>
      <span className='horizontal gap8'>
        <Form.Item label='Departamento' name='department' className='half-50'>
          <Input />
        </Form.Item>
        <Form.Item label='Cargo' name='title' className='half-50'>
          <Input />
        </Form.Item>
      </span>
      <span className='horizontal gap8'>
        <Form.Item label='País' name='country' className='half-50'>
          <Input />
        </Form.Item>
        <Form.Item label='Estado o provincia' name='state' className='half-50'>
          <Input />
        </Form.Item>
      </span>
      <span className='horizontal gap8'>
        <Form.Item label='Localidad' name='city' className='half-50'>
          <Input />
        </Form.Item>
        <Form.Item label='Dirección' name='street' className='half-50'>
          <Input />
        </Form.Item>
      </span>
      <span className='horizontal gap8'>
        <Form.Item label='Código postal' name='zip' className='half-50'>
          <Input />
        </Form.Item>
        <Form.Item
          label='Tipo de dirección'
          name='type'
          initialValue='home'
          className='half-50'
        >
          <Select
            options={[
              { label: 'Casa', value: 'home' },
              { label: 'Trabajo', value: 'work' }
            ]}
          />
        </Form.Item>
      </span>
    </Form>
  );
};
