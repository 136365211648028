import {
  Button,
  Drawer,
  Form,
  Input,
  Popover,
  Select,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { GlobalContext } from "../../../../context/GlobalContext";
import { MdOutlineHttp } from "react-icons/md";
import { updateNode } from "../utils/updateNode";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { getVariableName } from "../utils/getVariableName";
import { RequestVarsForm } from "./RequestVarsForm";
import { FiInfo, FiX } from "react-icons/fi";
import { SelectVariables } from "../SelectVariables";
import {
  handleKeyPress,
  handleVars,
  isInterpolate,
  handleInnerVars,
  hasSingleVariable,
  formatearVariablesComunes,
} from "../utils/httpRequest";
import {
  addBodyItem,
  deleteItemBody,
  handleValueItemBody,
  handleVariableType,
} from "../utils/httpRequestBody";
import { TbCodeDots } from "react-icons/tb";
import { newFallbackEdge } from "../utils/newFallbackEdge";
import { useUpdateNodeInternals } from "reactflow";
import { UrlParams } from "../../../urlParams/UrlParams";
import { RequestHeaders } from "../../../requestHeaders/RequestHeaders";
import { HeaderForm } from "./componentsForm/HeaderForm";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";

export const HttpRequestForm = () => {
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    flowVariables,
    setFlowVariables,
    setEdges,
    setMostrarDrawerVars,
    mostrarDrawerVars,
    nodes,
    edges,
  } = useContext(FlowContext);

  const [form] = Form.useForm();

  const { messageApi } = useContext(GlobalContext);

  const [nombreNodo, setNombreNodo] = useState("");
  const [method, setMethod] = useState("GET");
  const [url, setUrl] = useState("");
  const [headers, setHeaders] = useState([]);
  const [hasBody, setHasBody] = useState(false);
  const [bodyType, setBodyType] = useState("JSON");
  const [body, setBody] = useState("");
  const [variables, setVariables] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [showPopoverProbar, setShowPopoverProbar] = useState(false);
  const [hasVars, setHasVars] = useState(false);
  const [bodyVars, setBodyVars] = useState([]);
  const [indexado, setIndexado] = useState(false);
  const [variablesIDX, setVariablesIDX] = useState([]);
  const [authData, setAuthData] = useState({ type: "", object: {}, value: "" });
  const [msgEmptyRes, setMsgEmptyRes] = useState("");
  const [urlParams, setUrlParams] = useState([]);

  const [fallbackOptions, setFallbackOptions] = useState([]);

  const updateNodeInternals = useUpdateNodeInternals();

  const onFinish = (values) => {
    if (!url) {
      messageApi.info("Debe incluir una URL");
      return;
    }

    if (!values.variable) {
      messageApi.info("Ingrese un nombre para la variable");
      return;
    }

    let valores = {
      label: nombreNodo,
      method: method,
      url: url,
      urlParams: urlParams,
      // contentType: contentType,
      // authorizationType: authorizationType,
      indexado: indexado,
      msgEmptyRes: msgEmptyRes.trim(),
      fallback: values.fallback,
      variable: {
        name: getVariableName(values.variable, indexado ? "itbl" : "http"),
        type: "string",
        innerVars: !indexado
          ? handleInnerVars(
              values.variable,
              setFlowVariables,
              nodoSeleccionado.id,
              messageApi,
              variables,
              "innerVars"
            )
          : [],
        indexedVars: indexado
          ? handleInnerVars(
              values.variable,
              setFlowVariables,
              nodoSeleccionado.id,
              messageApi,
              variablesIDX,
              "indexedVars"
            )
          : [],
      },
    };

    valores.arrayHeaders = [...headers];

    if (method === "POST") {
      valores.hasBody = hasBody;
      valores.bodyType = bodyType;
      valores.hasVars = hasVars;
      if (!hasVars) {
        let rawString = body.replace(/'/g, '"').replace(/,\s*}/g, "}");
        if (rawString) {
          valores.body = rawString;
        }
      } else {
        if (bodyVars?.length > 0) {
          valores.bodyVars = bodyVars;

          let flag = bodyVars.some(
            (element) => !element.key || !element.value || !element.type
          );

          if (flag) {
            return messageApi.info(
              "Cada item del body debe contenener key, value y type"
            );
          }

          if (bodyVars.some((element) => !hasSingleVariable(element.value))) {
            return messageApi.info(
              "Los valores deben contener una variable como máximo"
            );
          }
        } else {
          return messageApi.info("Incluya al menos un item en el body");
        }
      }
    }

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      {
        name: getVariableName(values.variable, indexado ? "itbl" : "http"),
        type: indexado ? "itbl" : "http",
      },
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) {
      messageApi.error("No se pudo agregar la variable");
      return;
    }

    if (urlParams?.length > 0) {
      valores.urlParams = urlParams;
    }

    // FALLBACK
    newFallbackEdge(nodoSeleccionado.id, values.fallback.toString(), setEdges);

    setNodes((prevState) => updateNode(nodoSeleccionado, valores, prevState));
    setMostrarDrawer(false);
    updateNodeInternals(nodoSeleccionado.id);
  };

  const decorarVariable = (wrappingString, emoji, variable, index) => {
    if (wrappingString === "${") {
      handleValueItemBody(
        setBodyVars,
        index,
        "value",
        "${" + `${variable.name}` + "}"
      );
    }
  };

  const AddonVariable = ({ index }) => {
    const [showPopover, setShowPopover] = useState(false);

    return (
      <Popover
        trigger="click"
        placement="topRight"
        open={showPopover}
        content={
          <SelectVariables
            decorarTexto={decorarVariable}
            context="text"
            nodeId={nodoSeleccionado.id}
            setShowPopover={setShowPopover}
            index={index}
            excludedTypes={["audio", "video", "location", "document", "image"]}
          />
        }
      >
        <span
          onClick={() => setShowPopover(true)}
          style={{ cursor: "pointer" }}
        >
          <TbCodeDots />
        </span>
      </Popover>
    );
  };

  useEffect(() => {
    setBody("");
    setHasVars(false);
    setBodyType("JSON");
    form.setFieldsValue({
      variable: "",
    });

    if (nodoSeleccionado.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);
      data.variable?.innerVars && setVariables(data.variable?.innerVars);
      data.variable?.indexedVars && setVariablesIDX(data.variable?.indexedVars);
      data.indexado ? setIndexado(data.indexado) : setIndexado(false);
      data.method && setMethod(data.method);
      data.url && setUrl(data.url);
      // data.contentType && setContentType(data.contentType);
      // data.authorizationType && setAuthorizationType(data.authorizationType);
      data.authData && setAuthData(data.authData);
      data.msgEmptyRes ? setMsgEmptyRes(data.msgEmptyRes) : setMsgEmptyRes("");

      let variableName;
      if (data.variable) {
        let variable = data.variable;
        variableName = variable.name.substring(variable.name.indexOf("_") + 1);
      } else {
        variableName = "";
      }
      form.setFieldsValue({
        variable: variableName,
      });

      if (data.body) {
        setHasBody(true);
        setBody(data.body);
        setBodyType(data.bodyType);
      } else {
        setHasBody(false);
        setBody("");
        setBodyType("");
      }

      if (data.bodyVars?.length > 0) {
        setHasBody(true);
        setHasVars(true);
        setBodyVars(data.bodyVars);
      } else {
        setHasVars(false);
        setBodyVars([]);
      }

      if (data.urlParams) {
        setUrlParams(data.urlParams);
      } else {
        setUrlParams([]);
      }

      if (data.arrayHeaders) {
        setHeaders(
          data.arrayHeaders.filter(
            (element) => element.type && element.value && element.key
          )
        );
      } else {
        setHeaders([]);
      }
    }
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (nodoSeleccionado?.data?.url) {
      let originalUrl = nodoSeleccionado.data.url;

      if (url === originalUrl) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [url, nodoSeleccionado]);

  useEffect(() => {
    // let set = getLastNode(edges, nodoSeleccionado.id);
    let array = [];

    nodes.forEach((node) => {
      if (["message", "buttons", "list", "iterableList"].includes(node.type)) {
        let string = `#${node.id} ${node.data?.label}`;
        let value = Number(node.id);

        array.push({ label: string, value: value });
      }
    });

    setFallbackOptions(array);
  }, [nodes]);

  useEffect(() => {
    if (nodoSeleccionado.data?.fallback) {
      form.setFieldsValue({
        fallback: nodoSeleccionado.data.fallback,
      });

      return;
    }

    if (fallbackOptions.length > 0) {
      let idNumber = Number(nodoSeleccionado.id);

      let lowerOptions = fallbackOptions.filter(
        (element) => element.value < idNumber
      );

      let closest = lowerOptions.reduce((prev, curr) => {
        return Math.abs(curr - idNumber) < Math.abs(prev - idNumber)
          ? curr
          : prev;
      });

      form.setFieldsValue({
        fallback: closest?.value ? closest.value : fallbackOptions[0]?.value,
      });
    }
  }, [fallbackOptions, nodoSeleccionado]);

  const showInitialUrlPreview = useMemo(() => {
    if (nodoSeleccionado?.data) {
      const { url } = nodoSeleccionado.data;

      if (isInterpolate(url)) {
        return true;
      }
    }

    return false;
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formRequest"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <Drawer
        open={mostrarDrawerVars}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <RequestVarsForm
          setMostrarDrawerVars={setMostrarDrawerVars}
          method={method}
          url={url}
          body={body}
          bodyVars={bodyVars}
          hasBody={hasBody}
          setVariables={setVariables}
          nodeId={nodoSeleccionado.id}
          innerVars={nodoSeleccionado.data?.variable?.innerVars}
          indexedVars={nodoSeleccionado.data.variable?.indexedVars}
          setIsValid={setIsValid}
          indexado={indexado}
          setIndexado={setIndexado}
          variablesIDX={variablesIDX}
          setVariablesIDX={setVariablesIDX}
          authData={authData}
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          headers={headers}
          setHeaders={setHeaders}
        />
      </Drawer>
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <MdOutlineHttp style={{ color: "var(--dark-color)" }} size={32} />
        }
      />
      <Form.Item label="Método">
        <Select
          value={method}
          onChange={(v) => setMethod(v)}
          options={[
            { value: "GET", label: "GET" },
            { value: "POST", label: "POST" },
          ]}
        />
      </Form.Item>
      <FormattedTextArea
        value={url}
        setValue={setUrl}
        minRows={3}
        placeholder="Escriba una URL"
        nodeId={nodoSeleccionado?.id}
        elementId="httpRequestUrl"
        showSelectVariables={true}
        showDecorators={false}
        showEmojis={false}
        label="URL"
        previewOnDefault={showInitialUrlPreview}
      />
      <UrlParams
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        modulo="flows"
        flowVariables={flowVariables}
        nodeId={nodoSeleccionado.id}
        edges={edges}
      />
      <div style={{ padding: 8 }}></div>
      <RequestHeaders
        headers={headers}
        setHeaders={setHeaders}
        modulo="flows"
        flowVariables={flowVariables}
        nodeId={nodoSeleccionado.id}
        edges={edges}
      />
      {method !== "GET" && (
        <span
          className="horizontal"
          style={{ margin: "16px 0px", justifyContent: "space-between" }}
        >
          <span className="horizontal">
            <p>Body</p>
            <Switch
              onChange={(v) => {
                setHasBody(v);
              }}
              checked={hasBody}
            />
          </span>
          {hasBody && method !== "GET" && (
            <span className="horizontal">
              <p>JSON</p>
              <Switch checked={hasVars} onChange={(v) => setHasVars(v)} />
              <p>Con variables</p>
            </span>
          )}
        </span>
      )}
      {hasBody && method !== "GET" && (
        <>
          {hasVars ? (
            <div className="variables-body-wrapper">
              <span className="horizontal variables-body-header-wrapper">
                <p className="variables-body-header">Key</p>
                <p className="variables-body-header">Value</p>
                <p className="variables-body-header">Type</p>
              </span>
              {bodyVars.map((variable, index) => {
                return (
                  <span className="variable-body-item" key={index}>
                    <span className="columna-body-item">
                      <Input
                        value={variable.key}
                        onChange={(v) =>
                          handleValueItemBody(
                            setBodyVars,
                            index,
                            "key",
                            v.target.value
                          )
                        }
                      />
                    </span>
                    <span className="columna-body-item">
                      <Input
                        value={variable.value}
                        onChange={(v) =>
                          handleValueItemBody(
                            setBodyVars,
                            index,
                            "value",
                            v.target.value
                          )
                        }
                        addonAfter={<AddonVariable index={index} />}
                      />
                    </span>
                    <Select
                      value={handleVariableType(
                        variable.type,
                        variable.value,
                        flowVariables
                      )}
                      onChange={(v) =>
                        handleValueItemBody(setBodyVars, index, "type", v)
                      }
                      options={[
                        { value: "string", label: "String" },
                        { value: "number", label: "Number" },
                      ]}
                    />
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteItemBody(setBodyVars, index)}
                    >
                      <FiX />
                    </span>
                  </span>
                );
              })}
              <span
                style={{ width: "100%", display: "flex", margin: "8px 0px" }}
              >
                <Button
                  className="btn-aceptar btn-oscuro"
                  style={{ margin: "0px 8px 8px auto" }}
                  type="primary"
                  size="small"
                  onClick={() => addBodyItem(setBodyVars)}
                >
                  Agregar item
                </Button>
              </span>
            </div>
          ) : (
            <Form.Item>
              <Input.TextArea
                autoSize={{ minRows: 2 }}
                onChange={(v) => setBody(v.target.value)}
                value={body}
              />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item
        label="Nombre de la variable"
        name="variable"
        onKeyPress={(e) => handleKeyPress(e)}
        rules={[
          { required: true, message: "Campo requerido" },
          {
            pattern: /^[a-zA-Z0-9-]+$/,
            message: 'Se permiten solo letras, números o "-"',
          },
        ]}
      >
        <Input
          addonBefore={indexado ? "itbl_" : "http_"}
          addonAfter={
            method === "POST" ? (
              <Popover
                trigger="click"
                placement="topRight"
                open={showPopoverProbar}
                content={
                  <div className="columna-simple">
                    <span style={{ marginBottom: "8px" }}>
                      ¿Está seguro de probar esta petición?
                    </span>
                    <span
                      style={{ marginBottom: "16px", fontWeight: "normal" }}
                    >
                      Esto puede tener un impacto no deseado.
                    </span>
                    <div className="botones-wrapper-content">
                      <Button
                        type="primary"
                        className="btn-guardar"
                        onClick={() => {
                          setShowPopoverProbar(false);
                          handleVars(url, setMostrarDrawerVars, messageApi);
                        }}
                      >
                        Confirmar
                      </Button>
                      <Button
                        className="btn-cancelar"
                        type="secondary"
                        onClick={() => setShowPopoverProbar(false)}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div>
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPopoverProbar(true)}
                >
                  Probar
                </span>
              </Popover>
            ) : (
              <span
                onClick={() =>
                  handleVars(url, setMostrarDrawerVars, messageApi)
                }
                style={{ cursor: "pointer" }}
              >
                Probar
              </span>
            )
          }
        />
      </Form.Item>
      <span
        className="horizontal"
        style={{
          color: "#505050",
          alignItems: "flex-start",
          marginBottom: "20px",
        }}
      >
        <span style={{ marginTop: "2px" }}>
          <FiInfo />
        </span>
        <span>
          <p>En caso de que la petición falle, se interrumpirá el flujo.</p>
          <p>
            Al elegir una variable obtenida a traves de una petición, asegúrese
            que sea tipo "String" o "Number"; de otro modo podría fallar.
          </p>
          <p>
            Si el body response es indexado su prefijo será "itbl_", sino
            "http_".
          </p>
        </span>
      </span>
      <FormattedTextArea
        value={msgEmptyRes}
        setValue={setMsgEmptyRes}
        label="Mensaje para respuesta vacía"
        nodeId={nodoSeleccionado?.id}
        elementId="msgEmptyRes"
        previewOnDefault={true}
      />
      <Form.Item label="Seleccione un fallback" name="fallback">
        <Select options={fallbackOptions} />
      </Form.Item>
      {!indexado && variables?.length > 0 && (
        <Form.Item
          label="Variables seleccionadas"
          style={{ marginTop: "16px" }}
        >
          <span className="columna">
            {variables.map((element, index) => {
              if (element.checked) {
                return (
                  <Tooltip title={element.name} key={index}>
                    <Tag
                      color="green"
                      style={{ maxWidth: "100%", width: "fit-content" }}
                    >
                      {formatearVariablesComunes(element.name)}
                    </Tag>
                  </Tooltip>
                );
              } else {
                return <></>;
              }
            })}
          </span>
        </Form.Item>
      )}
      {indexado && variablesIDX?.length > 0 && (
        <Form.Item
          label="Variables seleccionadas"
          style={{ marginTop: "16px" }}
        >
          <span className="columna">
            {variablesIDX.map((element, index) => {
              if (element.checked) {
                return (
                  <Tooltip title={element.name} key={index}>
                    <Tag
                      color="green"
                      style={{ maxWidth: "100%", width: "fit-content" }}
                    >
                      {element.name.substring(element.name.indexOf("[idx]"))}
                    </Tag>
                  </Tooltip>
                );
              } else {
                return <></>;
              }
            })}
          </span>
        </Form.Item>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            disabled={method === "POST" ? false : !isValid}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
