import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { LiaRobotSolid } from "react-icons/lia";
import { Empty } from "antd";
import {
  getDefaultFlow,
  getFlows,
} from "../../../../../../helpers/fetchData/fetchFlows";
import { DefaultBot } from "./DefaultBot";
import { DefaultBotDrawer } from "./DefaultBotDrawer";
import LoadingIcon from "../../../../Loader/LoadingIcon";

export const Bots = () => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [defaultFlow, setDefaultFlow] = useState(null);
  const [loadingDefault, setLoadingDefault] = useState(true);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getFlows(setFlows, messageApi, setLoading, instance);

      setLoadingDefault(true);
      getDefaultFlow(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data?.flowId) {
              setDefaultFlow(res.data.flowId);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener bot por defecto");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener bot por defecto")
        )
        .finally(() => setLoadingDefault(false));
    }
  }, [instance]);

  const flowData = useMemo(() => {
    if (!defaultFlow) {
      return null;
    }

    const flow = flows.find((element) => element._id === defaultFlow);

    return flow || null;
  }, [flows, defaultFlow]);

  useEffect(() => {
    if (showDrawer && defaultFlow) {
      setSelectedFlow(defaultFlow);
    }
  }, [showDrawer, defaultFlow]);

  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <LiaRobotSolid size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Bots</span>
            </span>
            <p className="header-subtitle">Administre sus bots</p>
          </span>
        </span>
      </div>
      {(loadingDefault || loading) && (
        <span
          style={{
            marginTop: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingIcon size="large" />
        </span>
      )}
      {!loadingDefault &&
        !loading &&
        (flowData ? (
          <DefaultBot flowData={flowData} setShowDrawer={setShowDrawer} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ))}

      <DefaultBotDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        flows={flows}
        selectedFlow={selectedFlow}
        setSelectedFlow={setSelectedFlow}
        setDefaultFlow={setDefaultFlow}
      />
    </div>
  );
};
