import { useState } from 'react';
import { ContactsLoadGet } from './ContactsLoadGet';
import { ContactsLoadGetForm } from './ContactsLoadGetForm';
import { ContactsLoadCron } from './ContactsLoadCron';
import { ContactsLoadExcel } from './ContactsLoadExcel';

const INITIAL_CONFIG = {
  clientsLoad: {}
};

export const DrawerConfigContacts = ({
  contactsConfig,
  setContactsConfig,
  closeModal,
  setMostrarRequestDrawer,
  stage,
  setStage
}) => {
  const [tmpConfig, setTmpConfig] = useState(contactsConfig || INITIAL_CONFIG);
  const [result, setResult] = useState(null);

  const handleStage = (number) => {
    setStage(number);
  };

  const renderStage = () => {
    switch (stage) {
      case 0:
        return (
          <ContactsLoadGet
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            setMostrarRequestDrawer={setMostrarRequestDrawer}
          />
        );
      case 1:
        return (
          <ContactsLoadGetForm
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            closeModal={closeModal}
            setContactsConfig={setContactsConfig}
          />
        );
      case 2:
        return (
          <ContactsLoadCron
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            setContactsConfig={setContactsConfig}
            closeModal={closeModal}
            setMostrarRequestDrawer={setMostrarRequestDrawer}
          />
        );
      //Excel method:
      case 3:
        return (
          <ContactsLoadExcel
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            setMostrarRequestDrawer={setMostrarRequestDrawer}
            setContactsConfig={setContactsConfig}
          />
        );
      default:
        return <></>;
    }
  };

  return <div style={{ height: '100%' }}>{renderStage()}</div>;
};
