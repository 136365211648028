export const dataToNodes = (campaign, setNodes, setEdges, nodes) => {
  const { schedule, template, actions } = campaign;

  if (!schedule || !template) {
    return;
  }

  let contactos = [];
  schedule?.forEach((calendario) => {
    contactos.push(...calendario.contacts);
  });

  // crea los nodos y edges de schedules.
  // handleBatches(contactos, setNodes, setEdges, nodes);

  setNodes((prevState) => {
    let array = [...prevState];

    // Cargo los contactos seleccionados.
    let index = array.findIndex((element) => element.type === "contacts");

    if (index >= 0) {
      let data = { ...array[index].data };

      array[index].data = { ...data, contactos: contactos };
    }

    // actualizo el nodo schedule con su data.
    let indexSchedule = array.findIndex(
      (element) => element.type === "schedule"
    );

    if (indexSchedule >= 0) {
      let data = { ...array[indexSchedule].data };

      array[indexSchedule].data = {
        ...data,
        schedule: schedule,
      };
    }

    // actualizo nodo template con su data.
    let indexTemplate = array.findIndex(
      (element) => element.type === "template"
    );

    const showHandles = actions?.length > 0;

    if (indexTemplate >= 0) {
      let data = { ...array[indexTemplate].data };

      array[indexTemplate].data = {
        ...data,
        showHandles: showHandles,
        template: template.template,
        templateVariables: template.templateVariables,
        xls: template.xls,
        httpRequest: template.httpRequest,      
      };
    }

    setTimeout(() => {
      if (actions?.length > 0) {
        let actionsNodos = [];
        let actionsEdges = [];

        let idNodo = 3;

        actions.forEach((action) => {
          const id = idNodo.toString();

          const yPos = 100 + (idNodo - 3) * 175;

          const obj = {
            data: {
              flow: action.flow || null,
              tag: action.tag || null,
            },
            position: { x: 950, y: yPos },
            id: id,
            type: "button",
          };

          const edge = {
            target: id,
            source: "2",
            sourceHandle: action.index,
            id: `button-${id}`,
          };

          actionsNodos.push(obj);
          actionsEdges.push(edge);

          idNodo++;
        });

        setNodes((prevState) => {
          return [...prevState, ...actionsNodos];
        });

        setEdges((prevState) => {
          return [...prevState, ...actionsEdges];
        });
      }
    }, 300);

    return array;
  });
};
