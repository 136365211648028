import { Button, Card, Form, Input, Spin, Tooltip } from "antd";
import "./main.css";
import { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import AuthCode from "react-auth-code-input";
import { GlobalContext } from "../../context/GlobalContext";
import { LoadingOutlined } from "@ant-design/icons";
import { handleKeyPress } from "../Layout/flows/utils/httpRequest";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { validateInstanceName } from "../../../helpers/validateInstanceName";

export const MailConfirmation = () => {
  const { user } = useAuth0();
  const { instance, setInstance, messageApi, setWorkspaces } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(0);
  const [otp, setOtp] = useState("");

  const [form] = Form.useForm();

  const SIZE = 6;

  const handleOnChange = (value) => {
    setOtp(value);
  };

  const estadoInstancia = (check) => {
    switch (check) {
      case 0:
        return <></>;
      case 1:
        return (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: "16px",
                }}
                spin
              />
            }
          />
        );
      case 2:
        return (
          <AiOutlineCheckCircle style={{ color: "var(--primary-color)" }} />
        );
      default:
        return (
          <Tooltip>
            <FiAlertCircle style={{ color: "#cc0033" }} />
          </Tooltip>
        );
    }
  };

  const validarNombreInstancia = (nombre) => {
    let flag = "";
    let count = 0;
    let fetch = () => {
      return new Promise((resolve, reject) => {
        if (count <= 5) {
          count += 1;
          validateInstanceName(nombre)
            .then((res) => {
              if (res?.status === 200) {
                if (res.data.message === "OK.") {
                  flag = false;
                } else {
                  flag = true;
                }
                resolve(flag);
              } else {
                setTimeout(() => {
                  fetch();
                }, 2000);
              }
            })
            .catch(function (error) {
              setTimeout(() => {
                fetch();
              }, 2000);
            });
        } else {
          messageApi.error("No se pudo validar el nombre.");
          reject(true);
        }
      });
    };

    return fetch();
  };

  const sendConfirmation = async (v) => {
    let instanceName = v.name?.trim() || "";
    if (!instanceName) {
      messageApi.info("Ingrese un nombre");
      return;
    }

    if (otp?.length < SIZE) {
      messageApi.info("Complete el código");
    }

    let data = {
      idDraft: instance.idDraft,
      userEmail: user.email,
      userName: user.name,
      otp: otp.toString(),
      name: instanceName.toLowerCase(),
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/confirmDraft`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await validarNombreInstancia(instanceName)
      .then((flag) => {
        if (!flag) {
          const request = axios(config);
          setLoading(true);
          request
            .then((res) => {
              if (res?.status === 200) {
                setInstance(res.data);
                setWorkspaces((prevState) => {
                  let object = {
                    ...prevState,
                    draft: {},
                  };

                  if (prevState?.instance) {
                    object.instance = [...prevState.instance, res.data];
                  } else {
                    object.instance = [res.data];
                  }

                  return object;
                });

                messageApi.success(
                  "Workspace creado correctamente. ¡Bienvenido!"
                );
              } else if (res?.status === 409) {
                messageApi.error(res.data.error);
              } else {
                messageApi.error("Ocurrió un error al confirmar el código");
              }
            })
            .catch((error) => {
              console.log(error);
              messageApi.error("Ocurrió un error al confirmar el código");
            })
            .finally(() => setLoading(false));
        } else {
          messageApi.error("No se pudo validar el nombre");
        }
      })
      .catch((error) => {
        setCheck(3);
      });
  };

  return (
    <div className="instance-main-container">
      <Form
        form={form}
        name="mail-validation"
        layout="vertical"
        className="first-registry-form"
        onFinish={sendConfirmation}
      >
        <b style={{ fontSize: 20, marginBottom: 0 }}>{instance?.userEmail}</b>
        <p style={{ fontSize: 12, marginBottom: 24 }}>
          Enviamos un código de confirmación al mail, revise su bandeja.
        </p>
        <Form.Item
          label="Nombre del workspace"
          name="name"
          onKeyPress={(e) => handleKeyPress(e)}
          rules={[
            {
              validator(rule, value) {
                setCheck(1);
                return new Promise((resolve, reject) => {
                  if (value.length > 3) {
                    if (!value.match(new RegExp(/^[a-zA-Z0-9]*$/))) {
                      setCheck(3);
                      reject(
                        "No se permiten espacios ni caracteres especiales"
                      );
                    } else {
                      validarNombreInstancia(value)
                        .then((flag) => {
                          if (!flag) {
                            setCheck(2);
                            resolve();
                          } else {
                            setCheck(3);
                            reject("El nombre no se encuentra disponible");
                          }
                        })
                        .catch((error) => {
                          setCheck(3);
                          reject("No se pudo validar el nombre");
                        });
                    }
                  } else {
                    reject("Ingrese como mínimo 4 caracteres");
                    setCheck(0);
                  }
                });
              },
            },
          ]}
        >
          <Input type="text" suffix={estadoInstancia(check)} />
        </Form.Item>
        <Form.Item label="Ingrese el código de confirmación">
          <Card style={{ border: "none", padding: 0 }}>
            <AuthCode
              length={SIZE}
              allowedCharacters="numeric"
              autoFocus={true}
              value={otp}
              onChange={handleOnChange}
              containerClassName="container-auth-code"
              inputClassName="inputs-auth-code"
            />
          </Card>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <span className="horizontal">
            <Button type="primary" block htmlType="submit" loading={loading}>
              Confirmar
            </Button>
            <Button
              type="secondary"
              block
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setInstance({})}
            >
              Volver
            </Button>
          </span>
        </Form.Item>
      </Form>
    </div>
  );
};
