import { Button, Form, Select, Switch, Upload } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { flattenFirstElementGetProducts } from '../../../../flows/utils/flattenJSON';
import { BoxInfo } from '../../../../../box-info/BoxInfo';
import { InboxOutlined } from '@ant-design/icons';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { upsertContactConfig } from '../../../../../../../helpers/fetchData/fetchContactConfig';
import {
  executeExternalContactsLoadExcel,
  fetchContacts
} from '../../../../../../../helpers/fetchData/fetchContacts';
import { LoadNotification } from '../../catalog/budgetConfigSteps/LoadNotification';
import { getAdditionalFields } from '../../../../../../../helpers/fetchData/fetchAdditionalFields';
import { customRequest } from '../../../../../template-form/utils';

const { Dragger } = Upload;

export const ContactsLoadExcel = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setMostrarRequestDrawer,
  setContactsConfig
}) => {
  const { messageApi, instance, notificationApi, setContactos } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [loadingContactos, setLoadingContactos] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [includeClientId, setIncludeClientId] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoading
      );
    }
  }, [instance]);

  useEffect(() => {
    if (tmpConfig?.contactsLoadExcel?.objMap) {
      form.setFieldsValue({
        ...tmpConfig.contactsLoadExcel.objMap,
        includeClientId: tmpConfig.contactsLoadExcel.includeClientId
      });
      setIncludeClientId(!!tmpConfig.contactsLoadExcel.includeClientId);
    }
  }, [tmpConfig, form]);

  const handleGuardar = async (values) => {
    const { includeClientId, ...objMap } = values;

    try {
      if (!xlsData) {
        messageApi.info('Es necesario que ingrese un archivo excel valido');
        return;
      }

      setTmpConfig((prevState) => {
        let obj = { ...prevState };

        let contactsLoadExcel = { ...obj.contactsLoadExcel };

        contactsLoadExcel.objMap = objMap;

        contactsLoadExcel.includeClientId = includeClientId;

        obj.contactsLoadExcel = contactsLoadExcel;
        return obj;
      });

      let contactsLoad = tmpConfig.contactsLoad;
      let contactsLoadExcel = {
        objMap,
        includeClientId
      };

      let data = {
        contactsLoad: contactsLoad,
        contactsLoadExcel: contactsLoadExcel
      };

      // Guardado de configuracion
      setLoading(true);
      upsertContactConfig(instance?.userData?.apiKey, data).then((res) => {
        if (res?.status === 200) {
          messageApi.success('Configuración guardada correctamente');
          setContactsConfig((prevState) => {
            let obj = { ...prevState };

            obj.contactsLoadExcel = contactsLoadExcel;

            return obj;
          });
          setMostrarRequestDrawer(false);

          // Sincronizar contactos brocoly - excel
          executeExternalContactsLoadExcel(instance?.userData?.apiKey, xlsData)
            .then((res) => {
              if (res?.status === 200) {
                notificationApi.success({
                  message: 'Carga de contactos completada correctamente.',
                  description: <LoadNotification data={res.data} />,
                  placement: 'bottomRight',
                  duration: 10
                });

                fetchContacts(
                  instance,
                  setContactos,
                  messageApi,
                  setLoadingContactos
                );
              } else {
                messageApi.error(
                  'Ocurrió un error al ejecutar la carga de contactos'
                );
              }
            })
            .catch((error) => {
              console.log(error);
              messageApi.error(
                'Ocurrió un error al ejecutar la carga de contactos'
              );
            })
            .finally(() => setLoading(false));
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const xlsOptions = useMemo(() => {
    if (!xlsData || xlsData.length < 1) {
      return [];
    }

    let options = Object.keys(xlsData[0])?.map((element) => {
      return {
        value: element,
        label: element
      };
    });

    return options;
  }, [xlsData]);

  const keyOptions = useMemo(() => {
    if (xlsData) {
      let flattenedArray = flattenFirstElementGetProducts(xlsData);

      return (
        flattenedArray?.map((element) => {
          return { value: element, label: element };
        }) || []
      );
    }

    return [];
  }, [xlsData]);

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={handleGuardar}
      name='contactsLoadGet'
      className='form-nodos'
    >
      <b style={{ marginBottom: '12px' }}>
        Configuremos cómo cargará los contactos
      </b>

      <span style={{ marginBottom: '24px' }}>
        <BoxInfo
          message={[
            'Suba un archivo donde obtendremos el listado de variables.',
            'El archivo deberá tener el formato correspondiente.'
          ]}
        />
      </span>

      <Form.Item>
        <Dragger
          name='file'
          customRequest={
            (v) => customRequest(v, messageApi, setXlsData) // PROBABLEMENTE DEBEREMOS HACER NUESTRA VERSION
          }
          maxCount={1}
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>

          <p className='ant-upload-text'>
            Haga click o arrastre un archivo aquí
          </p>

          <p className='ant-upload-hint'>{`Soporta archivos en formato xls, xlsx.`}</p>

          <p className='ant-upload-hint'>{`Con un máximo de 100MB.`}</p>
        </Dragger>
      </Form.Item>

      {xlsOptions?.length > 0 && (
        <>
          <span style={{ marginTop: '24px', marginBottom: '12px' }}>
            <BoxInfo
              message={[
                'Necesitamos asociar las características de tus contactos para que coincidan con el formato utilizado en Brocoly.'
              ]}
            />
          </span>
        </>
      )}

      <Form.Item name='includeClientId' label='¿Incluye la cuenta de cliente?'>
        <Switch
          checked={includeClientId}
          onChange={() => setIncludeClientId(!includeClientId)}
        />
      </Form.Item>

      {includeClientId && (
        <Form.Item
          name='clientId'
          label='Cuenta de cliente'
          rules={[{ required: true, message: 'Campo requerido' }]}
        >
          <Select options={keyOptions} allowClear />
        </Form.Item>
      )}

      <Form.Item
        name='first_name'
        label='Nombre'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='middle_name' label='Segundo nombre'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name='last_name'
        label='Apellido'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='birthday' label='Fecha de nacimiento'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name='phone'
        label='Teléfono'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='email' label='Correo electrónico'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='company' label='Organización'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='department' label='Departamento'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='title' label='Cargo'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='type' label='Tipo de dirección'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='street' label='Dirección'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='city' label='Localidad'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='state' label='Estado o provincia'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='country' label='País'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='zip' label='Código postal'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      {additionalFields?.length > 0 && (
        <>
          {additionalFields.map((field) => (
            <Form.Item name={field.name} label={field.name}>
              <Select options={keyOptions} allowClear />
            </Form.Item>
          ))}
        </>
      )}

      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            style={{ opacity: loading ? 0.65 : 1 }}
            icon={loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null}
            disabled={xlsData.length === 0}
          >
            Guardar
          </Button>

          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarRequestDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
