export const FormErrorsNotification = ({ errorFields }) => {
  return (
    <div className='columna gap4'>
      {errorFields &&
        errorFields.map((err) => (
          <>
            <b>{err.formName}:</b>
            {err.errors &&
              err.errors.map((err) => <span>- {err.errors[0]}</span>)}
          </>
        ))}
    </div>
  );
};
