import { Form, Radio, Select, Switch } from "antd";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";
import { HeaderTypeLocation } from "./HeaderTypeLocation";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { getMediaByType } from "../../../../../helpers/fetchData/fetchMedia";

export const HeaderTextOrMedia = ({
  messageType,
  setMessageType,
  nodeId,
  headerMediaType,
  setHeaderMediaType,
  text,
  setText,
  url,
  setURL,
  setPreview,
  caption,
  setCaption,
  location,
  setLocation,
  context = "message",
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loadingResources, setLoadingResources] = useState(false);
  const [resources, setResources] = useState([]);
  const [showSavedResources, setShowSavedResources] = useState(false);

  const enableMediaPicker = useMemo(() => {
    const condicion = ["message", "template", "interactive-buttons"].includes(
      context?.toLowerCase()
    );

    if (!condicion && messageType !== "text") {
      // Si fuera tipo "media" cuando no está permitido
      setMessageType("text");
    }
    // Header tipo media solo habilitado para mensajes comunes, templates, o interactive "buttons"
    return condicion;
  }, [context, messageType]);

  const isInteractive = useMemo(() => {
    // Solo para mensajes tipo "message", no "interactive"
    return context?.toLowerCase()?.startsWith("interactive");
  }, [context]);

  const handleSwitchResources = () => {
    setURL(null);
    setShowSavedResources((prevState) => !prevState);
  };

  useEffect(() => {
    if (
      instance?.userData?.apiKey &&
      showSavedResources &&
      resources?.length < 1
    ) {
      setLoadingResources(true);
      getMediaByType(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              setResources(res.data);
              return;
            }
          }

          messageApi.error("Ocurrió un error al obtener recursos");
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener recursos")
        )
        .finally(() => setLoadingResources(false));
    }
  }, [showSavedResources, instance]);

  const options = useMemo(() => {
    if (showSavedResources) {
      setURL(null);
      if (resources?.length > 0) {
        let array = resources
          .filter(
            (element) =>
              element.mediaType?.toLowerCase() === headerMediaType &&
              element.publicURL
          )
          ?.map((element) => {
            return {
              value: element.publicURL,
              label: element.name,
            };
          });

        return array;
      }
    }

    return [];
  }, [headerMediaType, showSavedResources, resources]);

  return (
    <>
      {enableMediaPicker && (
        <Form.Item label="Tipo">
          <Radio.Group
            onChange={(v) => setMessageType(v.target.value)}
            buttonStyle="solid"
            value={messageType}
          >
            <Radio.Button value="text">Texto</Radio.Button>
            <Radio.Button value="media">Media</Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}
      {messageType === "text" && (
        <FormattedTextArea
          value={text}
          setValue={setText}
          showDecorators={context === "message" ? true : false}
          nodeId={nodeId}
          placeholder="Escriba un texto"
          elementId="textHeader"
          label="Texto"
        />
      )}
      {messageType === "media" && enableMediaPicker && (
        <>
          <Form.Item>
            <Radio.Group
              onChange={(v) => {
                setHeaderMediaType(v.target.value);
                setPreview && setPreview("");
              }}
              className="radio-media"
              style={{
                width: "100%",
                marginBottom: "12px",
              }}
              buttonStyle="solid"
              size="small"
              value={headerMediaType}
            >
              <Radio.Button value="image">Imagen</Radio.Button>
              <Radio.Button value="video">Video</Radio.Button>
              <Radio.Button value="document">Documento</Radio.Button>
              <Radio.Button value="audio">Audio</Radio.Button>
              {!isInteractive && (
                <Radio.Button value="location">Ubicación</Radio.Button>
              )}
            </Radio.Group>
          </Form.Item>

          {headerMediaType !== "location" && (
            <>
              <span className="horizontal" >
                <Switch
                  checked={showSavedResources}
                  onClick={handleSwitchResources}
                  checkedChildren="Recursos almacenados"
                  unCheckedChildren="URL"
                  style={{width: "fit-content"}}
                />
              </span>

              {!showSavedResources ? (
                <FormattedTextArea
                  value={url}
                  setValue={setURL}
                  showDecorators={false}
                  showEmojis={false}
                  nodeId={nodeId}
                  elementId="URL"
                  label="URL"
                />
              ) : (
                <Form.Item>
                  <div className="horizontal">
                    <Select
                      options={options}
                      placeholder="Seleccione un recurso"
                      style={{ width: "100%" }}
                      onChange={(v) => setURL(v)}
                      loading={loadingResources}
                    />
                  </div>
                </Form.Item>
              )}
            </>
          )}

          {["image", "video", "document"].includes(headerMediaType) &&
            !isInteractive && (
              <FormattedTextArea
                value={caption}
                setValue={setCaption}
                minRows={2}
                placeholder=""
                elementId="captionText"
                nodeId={nodeId}
                label={
                  headerMediaType === "document"
                    ? "Nombre del archivo"
                    : "Descripción"
                }
                showDecorators={headerMediaType === "document" ? false : true}
                showEmojis={headerMediaType === "document" ? false : true}
              />
            )}
          {headerMediaType === "location" && !isInteractive && (
            <HeaderTypeLocation
              location={location}
              setLocation={setLocation}
              nodeId={nodeId}
            />
          )}
        </>
      )}
    </>
  );
};
