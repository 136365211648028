import React, { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Drawer, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { renderLiveDateDivider } from "./utils/renderDateDivider";
import { Message } from "./Message";
import { NotSelected } from "./results/NotSelected";
import { Expired } from "./results/Expired";
import { Initiate } from "./results/Initiate";
import { NotMine } from "./results/NotMine";
import { ChatInput } from "./results/ChatInput";
import { WaitingConversation } from "./results/WaitingConversation";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  newChatOK,
  replaceContactVars,
  scrollToBottom,
  updateConversations,
  updateLiveConversation,
} from "./utils/chatFunctions";
import {
  newChat,
  newChatNote,
  sendTemplateInChat,
} from "../../../../helpers/fetchData/fetchChats";
import { ChatTemplateForm } from "./ChatTemplateForm";
import { getGroupReplies } from "../../../../helpers/fetchData/fetchSavedMessages";
import { ConversationTickets } from "./ConversationTickets";
import LoadingIcon from '../../Loader/LoadingIcon';


export const Interactions = ({
  selectedConversation,
  setSelectedConversation,
  selectedContact,
  chatFormattedData,
  liveConversation,
  setLiveConversation,
  conversationStage,
  loadingConversation,
  setActiveTabKey,
  myTeams,
  socket,
  isNota,
  setIsNota,
  setConversations,
  defaultTemplate,
  setNotAssignedTickets,
  setIsConversationExpired,
  setSelectedConversationData,
  conversationExpired,
  ticketTypes,
  closingTypes,
}) => {
  const { instance, messageApi, contactos } = useContext(GlobalContext);

  const ref = useRef(null);
  const [loadingNewChat, setLoadingNewChat] = useState(false);
  const [configTemplate, setConfigTemplate] = useState({
    open: false,
    data: null,
  });

  const [shouldScrollBottom, setShouldScrollBottom] = useState(true);

  const [loadingDefaultTemplate, setLoadingDefaultTemplate] = useState(false);

  const [savedMessages, setSavedMessages] = useState([]);

  const sendDefaultTemplate = () => {
    try {
      setLoadingDefaultTemplate(true);

      if (
        conversationStage === "expired" &&
        selectedConversation?.status === "OPEN"
      ) {
        let contacto = {
          ...contactos.find((contact) => {
            return (
              contact.phones?.[0]?.phone === selectedConversation?.phoneNumber
            );
          }),
          ...selectedConversation,
        };

        const templateVariables = replaceContactVars(
          defaultTemplate?.templateVariables,
          contacto
        );

        let templateData = { ...defaultTemplate, templateVariables };

        let data = {
          phoneNumber: selectedContact?.phoneNumber,
          templateData: templateData,
          chatId: selectedConversation?._id,
        };

        sendTemplateInChat(instance?.userData?.apiKey, data)
          .then((res) => {
            if (res?.status !== 200) {
              messageApi.error("Ocurrió un error al enviar la plantilla");
              setSelectedConversation((prevState) => {
                return { ...prevState };
              });
            }
          })
          .catch((error) => {
            messageApi.error("Ocurrió un error al enviar la plantilla");
          })
          .finally(() => setLoadingDefaultTemplate(false));
      } else {
        let contacto = {
          ...contactos.find((contact) => {
            return (
              contact.phones?.[0]?.phone === selectedConversation?.phoneNumber
            );
          }),
          ...selectedContact,
        };

        const templateVariables = replaceContactVars(
          defaultTemplate?.templateVariables,
          contacto
        );

        let templateData = { ...defaultTemplate, templateVariables };

        let data = {
          phoneNumber: selectedContact?.phoneNumber,
          templateData: templateData,
          textMessage: null,
        };

        newChat(instance?.userData?.apiKey, data, myTeams, messageApi)
          .then((res) => {
            if (res?.status === 200) {
              newChatOK(
                res,
                setSelectedConversation,
                setActiveTabKey,
                selectedContact,
                instance
              );
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingDefaultTemplate(false));
      }
    } catch (error) {
      console.log(error);
      messageApi.error(
        "No se pudo completar las variables de la plantilla. Asegurese que el contacto tenga la información necesaria."
      );
      setLoadingDefaultTemplate(false);
    }
  };

  const handleNewChat = (type, chatText) => {
    // New chat, desde contacto.
    let contacto = { ...selectedConversation, ...selectedContact };

    let data = {
      phoneNumber: contacto?.phoneNumber,
      templateData: null,
      textMessage: null,
    };

    if (type === "text") {
      data.textMessage = chatText;
    } else {
      const templateVariables = replaceContactVars(
        configTemplate.data.templateVariables,
        contacto
      );

      let templateData = configTemplate.data;

      templateData.templateVariables = templateVariables;

      data.templateData = templateData;
    }

    setLoadingNewChat(true);

    newChat(instance?.userData?.apiKey, data, myTeams, messageApi)
      .then((res) => {
        if (res?.status === 200) {
          newChatOK(
            res,
            setSelectedConversation,
            setActiveTabKey,
            selectedContact,
            instance
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoadingNewChat(false));
  };

  const handleSendTemplate = () => {
    // Dentro de un ticket y no está expirado, ya tiene selectedConversation ok

    if (!selectedConversation?._id) {
      // No permitir si no existe ticket abierto

      messageApi.info("No podes enviar templates hasta abrir un ticket");
      return;
    }

    let contacto = {
      ...contactos.find((contact) => {
        return contact.phones?.[0]?.phone === selectedConversation?.phoneNumber;
      }),
      ...selectedConversation,
    };

    let data = {
      phoneNumber: selectedConversation?.phoneNumber,
      templateData: null,
      textMessage: null,
      chatId: selectedConversation?._id,
    };

    const templateVariables = replaceContactVars(
      configTemplate.data.templateVariables,
      contacto
    );

    let templateData = { ...configTemplate.data, templateVariables };

    data.templateData = templateData;

    setLoadingNewChat(true);

    sendTemplateInChat(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status !== 200) {
          messageApi.error("Ocurrió un error al enviar la plantilla");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al enviar la plantilla");
      })
      .finally(() => setLoadingNewChat(false));
  };

  const sendMessage = (chatText) => {
    if (!chatText) return messageApi.info("Ingrese un texto");

    if (!selectedConversation?._id && !selectedContact._id) {
      messageApi.info("Seleccione un contacto para enviar un mensaje");
      return;
    } else if (selectedContact?._id && !selectedConversation?._id && !isNota) {
      handleNewChat("text", chatText);
      return;
    }

    if (isNota) {
      if (!selectedConversation?._id) {
        messageApi.info("No se puede agregar una nota sin un ticket abierto");
        return;
      }

      const message = {
        type: "text",
        text: { body: chatText },
        timestamp: Date.now(),
        to: selectedConversation?.phoneNumber,
        chatId: selectedConversation?._id,
        isNote: true,
        doNotReturn: true,
      };

      newChatNote(instance?.userData?.apiKey, message)
        .then((res) => {
          if (res?.status === 200) {
          } else {
            messageApi.error("Ocurrió un error al cargar una nota");
          }
        })
        .catch((err) => {
          console.log(err);
          messageApi.error("Ocurrió un error al cargar una nota");
        });
    } else {
      if (socket.connected) {
        const message = {
          type: "text",
          text: { body: chatText },
          apiKey: instance?.userData?.apiKey,
          timestamp: Date.now(),
          to: selectedConversation?.phoneNumber,
          chatId: selectedConversation?._id,
          userType: "agent",
          doNotReturn: true,
        };

        socket.emit("sendMessage", { message });

        updateLiveConversation(
          message,
          setLiveConversation,
          chatFormattedData?.messages
        );

        updateConversations(message, setConversations, setNotAssignedTickets);
      } else {
        messageApi.error("No hay conexión al servidor de mensajes");
      }
    }
  };

  useEffect(() => {
    if (configTemplate.data?.template) {
      if (selectedConversation?.status === "OPEN" && !conversationExpired) {
        handleSendTemplate();
      } else {
        handleNewChat("");
      }
    }
  }, [configTemplate]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getGroupReplies(instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res?.data)) {
              setSavedMessages(res.data);
            }
          } else {
            messageApi.error(
              "Ocurrió un error al obtener mensajes predeterminados"
            );
          }
        })
        .catch((error) =>
          messageApi.error(
            "Ocurrió un error al obtener mensajes predeterminados"
          )
        );
    }
  }, [instance]);

  useEffect(() => {
    if (liveConversation?.length > 0 && shouldScrollBottom) {
      scrollToBottom(ref, "live");
    }
  }, [liveConversation]);

  return (
    <>
      <div className="background-container" id="background-container">
        <div
          className="chat-interaction-wrapper"
          id="chat-interaction-wrapper"
          ref={ref}
        >
          {!selectedConversation?._id && !selectedContact?._id ? (
            <NotSelected />
          ) : !loadingConversation ? (
            <>
              <ConversationTickets
                chatFormattedData={chatFormattedData}
                ticketTypes={ticketTypes}
                closingTypes={closingTypes}
                wrapperRef={ref}
              />
              {chatFormattedData.messages &&
                renderLiveDateDivider(liveConversation, chatFormattedData)}
              {liveConversation?.map((element, index) => {
                let timestamp = element.timestamp;

                if (timestamp?.length < 12) {
                  timestamp = timestamp * 1000;
                }

                element = {
                  ...element,
                  timestamp: timestamp,
                  date: dayjs(timestamp, "x")?.format("DD/MM/YYYY"),
                  status: element.status || "delivered",
                  id: `live-${index}`,
                };

                return (
                  <div key={element.id} className="message-date-wrapper">
                    <Message data={element} />
                  </div>
                );
              })}
            </>
          ) : (
            <span
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Spin indicator={<LoadingOutlined size={26} spin />} /> */}
              <LoadingIcon size="default" />
            </span>
          )}

          <span
            id="scroll-to-here"
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              marginTop: 40,
            }}
          ></span>
        </div>
        {!loadingConversation && conversationStage && (
          <div className="chat-input-wrapper fade-in">
            <span
              className={
                !isNota
                  ? "chat-inner-input-wrapper"
                  : "chat-inner-input-wrapper input-note"
              }
            >
              {conversationStage === "textInput" && (
                <ChatInput
                  loadingNewChat={loadingNewChat}
                  sendMessage={sendMessage}
                  isNota={isNota}
                  setIsNota={setIsNota}
                  setConfigTemplate={setConfigTemplate}
                  socket={socket}
                  selectedConversation={selectedConversation}
                  liveConversation={liveConversation}
                  setLiveConversation={setLiveConversation}
                  setConversations={setConversations}
                  chatFormattedData={chatFormattedData}
                  setIsConversationExpired={setIsConversationExpired}
                  selectedContact={selectedContact}
                  setSelectedConversation={setSelectedConversation}
                  setActiveTabKey={setActiveTabKey}
                  setNotAssignedTickets={setNotAssignedTickets}
                  myTeams={myTeams}
                  savedMessages={savedMessages}
                />
              )}
              {conversationStage === "notMine" && (
                <NotMine selectedConversation={selectedConversation} />
              )}
              {conversationStage === "waiting" && (
                <WaitingConversation
                  selectedConversation={selectedConversation}
                  setSelectedConversationData={setSelectedConversationData}
                />
              )}
              {conversationStage === "initiate" && (
                <Initiate
                  setConfigTemplate={setConfigTemplate}
                  loadingNewChat={loadingNewChat}
                  sendDefaultTemplate={sendDefaultTemplate}
                  defaultTemplate={defaultTemplate}
                  loadingDefaultTemplate={loadingDefaultTemplate}
                />
              )}
              {conversationStage === "expired" && (
                <Expired
                  setIsNota={setIsNota}
                  setConfigTemplate={setConfigTemplate}
                  loadingNewChat={loadingNewChat}
                  sendDefaultTemplate={sendDefaultTemplate}
                  defaultTemplate={defaultTemplate}
                  loadingDefaultTemplate={loadingDefaultTemplate}
                />
              )}
            </span>
          </div>
        )}
      </div>
      <Drawer
        open={configTemplate?.open}
        placement="right"
        destroyOnClose
        width={450}
      >
        <ChatTemplateForm setConfigTemplate={setConfigTemplate} />
      </Drawer>
    </>
  );
};
