const SECTIONS = ["HEADER", "BODY", "FOOTER", "BUTTONS", "CAROUSEL"];

export const validateTemplateVariables = (
  templateVariables,
  varOptions,
  messageApi
) => {
  let error = false;

  SECTIONS.forEach((section) => {
    const sectionName = section.toLowerCase();

    let arrayVars = [];

    if (sectionName === "buttons") {
      arrayVars = templateVariables.buttonsVars?.filter(
        (variable) => variable.type === "var"
      );
    } else {
      arrayVars = templateVariables[sectionName]?.vars?.filter(
        (variable) => variable.type === "var"
      );
    }

    if (arrayVars?.length > 0) {
      if (arrayVars.some((element) => !element.value)) {
        error = true;
        messageApi.error("Debe configurar todas las variables");
      } else if (
        arrayVars.some(
          (element) =>
            !varOptions
              ?.map((variable) => variable.value)
              ?.includes(element.value)
        )
      ) {
        error = true;
        messageApi.error("Hay variables que ya no existen");
      }
    }
  });

  return error;
};
