import { Button, Popover, Table } from 'antd';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FiEdit3, FiPlus, FiTag, FiUsers } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import { Tag } from '../../../tag/Tag';
import { deleteTag } from '../../../../../helpers/fetchData/fetchTags';
import { accessDataFormat } from '../../../../../helpers/access';
import { useAccess } from '../../../../../hooks/useAccess';
import LoadingIcon from '../../../Loader/LoadingIcon';

export const Tags = () => {
  const {
    setRightDrawerConfig,
    setSideDrawerConfig,
    etiquetas,
    setEtiquetas,
    instance,
    messageApi,
    loadingEtiquetas
  } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const AccionesTags = ({ item }) => {
    const [showPopover, setShowPopover] = useState(false);

    const isAllowed = useAccess();

    const [loading, setLoading] = useState(false);

    const handleDeleteTag = (id) => {
      setLoading(true);
      deleteTag(instance?.userData?.apiKey, { _id: id })
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Etiqueta eliminada correctamente');

            setEtiquetas((prevState) => {
              let array = [...prevState];

              let index = array.findIndex((element) => element._id === id);

              if (index >= 0) {
                array.splice(index, 1);
              }

              return array;
            });
          } else {
            messageApi.error('Ocurrió un error al eliminar una etiqueta');
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error('Ocurrió un error al eliminar una etiqueta');
        })
        .finally(() => setLoading(false));
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {item.name?.toUpperCase() !== 'NO SPAM' && (
          <span style={{ height: 18 }}>
            <FiEdit3
              className='icono-acciones'
              onClick={() => {
                setRightDrawerConfig({
                  visible: true,
                  content: 'Editar Etiqueta',
                  item: item
                });
              }}
            />
          </span>
        )}
        <span style={{ height: 18 }}>
          <FiUsers
            className='icono-acciones'
            onClick={() => {
              setRightDrawerConfig({
                visible: true,
                content: 'Manejar Grupos',
                item: item
              });
            }}
          />
        </span>
        {item.name?.toUpperCase() !== 'NO SPAM' && (
          <Popover
            open={showPopover}
            content={
              <span className='columna' style={{ maxWidth: 280 }}>
                <p>{`¿Está seguro de eliminar ${item.name}?`}</p>
                <span className='horizontal'>
                  <Button
                    type='primary'
                    className='btn-borrar'
                    onClick={() => handleDeleteTag(item._id)}
                    //loading={loading}
                    icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                    style={{ opacity: loading ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    type='secondary'
                    className='btn-cancelar'
                    onClick={() => setShowPopover(false)}
                  >
                    Cancelar
                  </Button>
                </span>
              </span>
            }
          >
            <span style={{ height: 18 }}>
              <TbTrash
                className='icono-acciones'
                onClick={() => setShowPopover(true)}
              />
            </span>
          </Popover>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex, item) => {
        return (
          <div className='tag-wrapper'>
            <Tag hex={item.color} nombre={item.name} />
          </div>
        );
      }
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '...',
      align: 'center',
      dataIndex: '',
      width: 80,
      key: '',
      render: (dataIndex, item) => {
        return <AccionesTags item={item} />;
      }
    }
  ];

  return (
    <div className='config-main-wrapper'>
      <div className='test-main-header'>
        <span
          className='horizontal'
          style={{ justifyContent: 'space-between' }}
        >
          <span className='columna' style={{ gap: '0px' }}>
            <span className='config-header-inline'>
              <span style={{ height: '24px' }}>
                <FiTag size={24} color='var(--primary-color)' />
              </span>
              <span className='header-title-templates'>Etiquetas</span>
            </span>
            <p className='header-subtitle'>
              Utilice etiquetas para identificar y segmentar a sus contactos
            </p>
          </span>
          <div className='espacio-botones-contactos'>
            {isAllowed(accessDataFormat('tags', 'manage')) && (
              <Button
                className='btn-agregar boton-dashed'
                onClick={() =>
                  setSideDrawerConfig({
                    visible: true,
                    content: 'plus',
                    tab: 'tag'
                  })
                }
              >
                <FiPlus /> Etiqueta
              </Button>
            )}
          </div>
        </span>
      </div>
      <Table
        columns={columns}
        dataSource={etiquetas || []}
        rowKey={'_id'}
        size='small'
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} etiquetas`
        }}
        className='tabla-config'
        loading={{
          spinning: loadingEtiquetas,
          indicator: <LoadingIcon size="large" />,
        }}
      />
    </div>
  );
};
