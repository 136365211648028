import { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MailConfirmation } from "./MailConfirmation";
import { Loader } from "../Loader/Loader";
import { GlobalContext } from "../../context/GlobalContext";
import { Instance } from "../Layout/settings/content/instance/Instance";
import { useNavigate } from "react-router-dom";
import { PickInstance } from "./PickInstance";
import LoadingIcon from '../Loader/LoadingIcon';

export const Main = () => {
  const { loadingInstance, instance, workspaces } = useContext(GlobalContext);

  const { isLoading, isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (instance?._id) {
      navigate("/dashboard");
    }
  }, [instance]);

  useEffect(() => {
    localStorage.removeItem("workspace");
  }, []);

  const handleStage = (workspaces) => {
    if (isLoading || loadingInstance) {
      return (
        <div className="main-loading-container">
          {/* <Loader /> */}
          <LoadingIcon size="mainLoader" />
        </div>
      );
    }

    if (
      !workspaces?.draft?.idDraft &&
      (instance?.create ||
        (workspaces?.instance?.length === 0 &&
          workspaces?.invitations?.length === 0))
    ) {
      return <Instance />;
    } else if (
      instance?.idDraft ||
      instance?.status?.toLowerCase() === "to be approved"
    ) {
      return <MailConfirmation />;
    }

    return <PickInstance />;
  };

  return <>{handleStage(workspaces)}</>;
};
