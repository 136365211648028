import { Spin } from 'antd';
import { MessageLocation } from '../../MessageLocation';
import { scrollTo } from '../../utils/chatFunctions';
import { FiFile, FiImage, FiMusic, FiPlayCircle } from 'react-icons/fi';
import { MdDownload } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import LoadingIcon from '../../../../Loader/LoadingIcon';


export const MediaTab = ({ media, loading }) => {
  const sortedMedia = media?.slice().sort((a, b) => b.timestamp - a.timestamp);

  return (
    <div className='extras-section' style={{ borderBottom: 'none' }}>
      <Spin spinning={loading} indicator={<LoadingIcon size="default" />}>
        <span className='extras-section-content fade-in'>
          {sortedMedia && sortedMedia.length > 0 ? (
            sortedMedia.map((attach) => (
              <Media key={attach._id} attach={attach} />
            ))
          ) : (
            <span className='extras-no-hay'>No hay media</span>
          )}
        </span>
      </Spin>
    </div>
  );
};

const Media = ({ attach }) => {
  const ICON_SIZE = 30;

  function getSpeaker() {
    if (attach.userType === 'agent') {
      return {
        backgroundColor: '#E9F7DF',
        borderColor: '#D8E6CF'
      };
    } else if (attach.userType === 'user') {
      return {
        backgroundColor: '#F7F7F7',
        borderColor: '#E6E6E6'
      };
    }
  }

  function getMediaIcon() {
    switch (attach.type) {
      case 'image':
        return <FiImage color='#49d16e' size={ICON_SIZE} />;
      case 'video':
        return <FiPlayCircle color='#d24a4c' size={ICON_SIZE} />;
      case 'document':
        return <FiFile color='#d1b349' size={ICON_SIZE} />;
      case 'audio':
        return <FiMusic color='#4956d1' size={ICON_SIZE} />;
      case 'location':
        return (
          <MessageLocation
            marker={{
              longitude: -63.244947,
              latitude: -32.410553,
              zoom: 15
            }}
            viewport={{
              longitude: -63.244947,
              latitude: -32.410553,
              zoom: 15
            }}
          />
        );
      default:
        return <FiImage color='white' size={ICON_SIZE} />;
    }
  }

  function getMediaName() {
    switch (attach.type) {
      case 'image':
        return 'IMAGEN';
      case 'video':
        return 'VIDEO';
      case 'document':
        return 'DOCUMENTO';
      case 'audio':
        return 'AUDIO';
      default:
        break;
    }
  }

  return (
    <div
      className='Media'
      style={getSpeaker()}
      onClick={() => scrollTo(attach._id)}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <span>{getMediaIcon()}</span>
        <span style={{ fontWeight: '500' }}>{getMediaName()}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <span style={{ fontSize: 14 }}>
          {dayjs(attach.timestamp, 'x').format('DD/MM/YYYY HH:mm')}
        </span>
        <MdDownload size={20} />
      </div>
    </div>
  );
};
