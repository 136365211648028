import { validarCalendarios } from "./schedule";

export const getSendTemplateData = (nodes, messageApi, name, size, edges) => {
  try {
    let error = false;

    let schedule = nodes.find((nodo) => nodo.type === "schedule");

    let filters = nodes[0].data?.filters;

    if (!filters || !schedule.data?.schedule) {
      messageApi.info("Debe configurar contactos y calendarios");
      error = true;
    }

    // if (contacts.length < 1) {
    //   messageApi.info("Es necesario que seleccione contactos");
    //   error = true;
    // }

    let array = [];
    let inicio = 0;

    if (
      validarCalendarios(
        schedule.data.schedule?.map((element) => element.date),
        messageApi
      )
    ) {
      error = true;
    }

    schedule?.data?.schedule?.forEach((segmento) => {
      if (!segmento.date || !segmento.time) {
        messageApi.info("Cada calendario debe tener un valor para ser enviado");
        error = true;
      }

      array.push({
        time: segmento.time,
        date: segmento.date,
        filters: filters,
      });
      inicio = inicio + size;
    });

    let template = nodes.find((nodo) => nodo.type === "template")?.data;

    if (!template) {
      messageApi.info("Seleccione y complete su plantilla");
      error = true;
    }

    let actions = [];
    let botones = nodes.filter((element) => element.type === "button");

    if (botones?.length > 0) {
      botones.forEach((boton) => {
        let filteredEdges = edges?.filter((edge) => edge.target === boton.id);

        filteredEdges?.forEach((edge, index) => {
          let text =
            template?.templateVariables?.buttons?.value?.[edge?.sourceHandle]
              ?.text || "";

          actions.push({
            ...boton.data,
            index: edge?.sourceHandle || "",
            text: text,
          });
        });
      });
    }

    let data = {
      schedule: array,
      template: template,
      actions: actions,
      name: name,
      immidiate: schedule.data?.immidiate || false,
      type: "broadcast",
    };

    return { data: data, error: error };
  } catch (error) {
    return { data: {}, error: true };
  }
};
