import { Button, Image, Result, Switch, Tabs, Tooltip, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FiCircle, FiEdit3 } from 'react-icons/fi';
import { MdVerified, MdWebhook } from 'react-icons/md';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import './index.css';
import { ImWhatsapp } from 'react-icons/im';
import { updateProductsAndServicesStatus } from '../../../../../../../helpers/fetchData/fetchCatalog';
import { EditarPerfilNegocio } from '../perfilNegocio/EditarPerfilNegocio';
import { Estadisticas } from '../statistics/Estadisticas';
import { useAccess } from '../../../../../../../hooks/useAccess';
import { accessDataFormat } from '../../../../../../../helpers/access';
import { getProfile } from '../../../../../../../helpers/fetchData/fetchProfile';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const CardWapp = () => {
  const [picture, setPicture] = useState('');

  const {
    setAppStage,
    messageApi,
    application,
    setApplication,
    whatsappProfile,
    instance
  } = useContext(GlobalContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const pictureUrl = await getPicture();

        setPicture(pictureUrl);
      } catch (error) {
        console.error(error);
      }
    }

    async function getPicture() {
      try {
        const perfil = await getProfile(
          application[0],
          instance?.userData?.apiKey
        );

        let picture;
        if (perfil?.status === 200) {
          picture = perfil?.data?.profile_picture_url;
        }

        return picture;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    fetchData();
  }, [application, instance, whatsappProfile]);

  const isAllowed = useAccess();

  const [loading, setLoading] = useState(false);

  const [drawerEditarPerfil, setDrawerEditarPerfil] = useState(false);

  function getColorRating() {
    switch (whatsappProfile[0]?.quality_rating) {
      case 'GREEN':
        return 'var(--primary-color)';
      case 'YELLOW':
        return 'yellow';
      case 'RED':
        return 'red';
      default:
        break;
    }
  }

  const items = [
    {
      key: 1,
      label: 'Perfil del negocio',
      children: (
        <div className='lines-list-wrapper'>
          {application?.[0]?.lines &&
            whatsappProfile?.[0]?.verified_name &&
            application[0].lines.map((linea) => {
              return (
                <div className='card-linea-wrapper' key={linea.phoneNumberId}>
                  <div className='card-wapp-header'>
                    <Image
                      width={60}
                      src={picture || '/assets/image-placeholder.png'}
                      preview={false}
                    />
                    <div className='card-wapp-description'>
                      <span className='nombre-estado-wrapper'>
                        <p>
                          {whatsappProfile[0].verified_name &&
                            whatsappProfile[0].verified_name}
                        </p>
                        {whatsappProfile[0].code_verification_status && (
                          <MdVerified size={16} color='var(--primary-color)' />
                        )}
                        {isAllowed(accessDataFormat('channels', 'manage')) && (
                          <FiEdit3
                            onClick={() => setDrawerEditarPerfil(true)}
                            size={16}
                            style={{ marginLeft: 'auto', cursor: 'pointer' }}
                          />
                        )}
                      </span>
                      <span className='scope-tag2'>
                        {whatsappProfile[0].display_phone_number &&
                          whatsappProfile[0].display_phone_number}
                      </span>
                      <span className='scope-tag2'>
                        {whatsappProfile[0].address &&
                          whatsappProfile[0].address}
                      </span>
                      <span className='scope-tag2'>
                        {whatsappProfile[0].email && whatsappProfile[0].email}
                      </span>
                      <span className='scope-tag2'>
                        {whatsappProfile[0].websites?.length > 0 &&
                          whatsappProfile[0].websites[0]}
                      </span>
                      <div className='horizontal gap4'>
                        <p>RATING</p>
                        <FiCircle
                          fill={getColorRating()}
                          size={18}
                          color='white'
                        />
                      </div>
                    </div>
                  </div>

                  <EditarPerfilNegocio
                    drawerEditarPerfil={drawerEditarPerfil}
                    setDrawerEditarPerfil={setDrawerEditarPerfil}
                  />
                </div>
              );
            })}
        </div>
      )
    },
    {
      key: 2,
      label: 'Estadísticas',
      children: <Estadisticas />
    },
    {
      key: 3,
      label: 'QR',
      children: <></>
    }
  ];

  const handleProductsAndServicesStatus = () => {
    setLoading(true);
    updateProductsAndServicesStatus(instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          setApplication((prevState) => {
            let object = { ...prevState?.[0] };

            object = {
              ...object,
              productsAndServices: !object.productsAndServices
            };

            return [object];
          });
        } else {
          messageApi.error(
            'Ocurrió un error al habilitar/deshabilitar productos y servicios'
          );
        }
      })
      .catch(() =>
        messageApi.error(
          'Ocurrió un error al habilitar/deshabilitar productos y servicios'
        )
      )
      .finally(() => setLoading(false));
  };

  const handleWebhookCopy = () => {
    let tenant = `https://webhook.brocoly.ar/webhook?tenant=${application?.[0]?.urlTenant}`;

    if (tenant) {
      navigator.clipboard.writeText(tenant);
      messageApi.info(`Texto copiado: ${tenant}`);
    } else {
      messageApi.info('No se encontró URL');
    }
  };

  return application?.length > 0 ? (
    <>
      <span
        className='horizontal fade-in'
        style={{
          justifyContent: 'space-between',
          marginLeft: '36px',
          marginBottom: '16px'
        }}
      >
        <span className='channel-wrapper'>
          <span
            className='horizontal'
            style={{ justifyContent: 'space-between', marginBottom: '12px' }}
          >
            <Image width={42} src='/assets/WhatsApp.png' preview={false} />
            <span className='horizontal'>
              {isAllowed(accessDataFormat('channels', 'manage')) && (
                <FiEdit3
                  size={20}
                  style={{ cursor: 'pointer', color: '#252525' }}
                  onClick={() => setAppStage(3)}
                />
              )}
              <Tooltip title='Webhook URL'>
                <MdWebhook
                  size={20}
                  style={{ cursor: 'pointer', color: '#252525' }}
                  onClick={() => handleWebhookCopy()}
                />
              </Tooltip>
            </span>
          </span>
          {application[0]?.lines && (
            <span>
              Número{' '}
              <p
                style={{
                  fontWeight: '500',
                  color: '#252525',
                  display: 'inline'
                }}
              >
                {application[0].lines[0]?.phoneNumber}
              </p>
            </span>
          )}
          <span>
            Business ID{' '}
            <p
              style={{
                fontWeight: '500',
                color: '#252525',
                display: 'inline'
              }}
            >
              {application[0]?.businessId}
            </p>
          </span>
          {isAllowed(accessDataFormat('channels', 'manage')) && (
            <span className='horizontal gap4'>
              <p>Productos y servicios</p>
              <Switch
                loading={loading}
                size='small'
                checked={application[0]?.productsAndServices}
                onChange={() => handleProductsAndServicesStatus()}
              />
            </span>
          )}
        </span>
      </span>
      {whatsappProfile.length > 0 ? (
        <div className='conf-content fade-in' style={{ maxWidth: 1200 }}>
          <Tabs items={items} />
        </div>
      ) : (
        <Spin
          style={{
            marginTop: 100
          }}
          indicator={<LoadingIcon size='large' />}
        />
      )}
    </>
  ) : (
    <>
      <Result
        className='fade-in'
        icon={<ImWhatsapp size={56} color='var(--primary-color)' />}
        title='No existe perfil creado'
        subTitle='Es necesario crear un perfil para poder utilizar la plataforma.'
        extra={[
          <div style={{ display: 'flex', justifyContent: 'center' }} key={0}>
            <Button
              type='primary'
              key='console'
              className='btn-agregar'
              onClick={() => {
                setAppStage(3);
              }}
            >
              Editar aplicación
            </Button>
          </div>
        ]}
      />
    </>
  );
};
