import { Table } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { getFlows } from "../../../../helpers/fetchData/fetchFlows";
import { paginacion } from "../../../../helpers/paginacion";
import { columnsFlows } from "./utils/flowColums";
import LoadingIcon from "../../Loader/LoadingIcon";

export const FlowsMainTable = ({ statusFiltro, setFlows, filteredFlows, loading, setLoading }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  // const [flows, setFlows] = useState([]);
  // const [filteredFlows, setFilteredFlows] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (instance?._id) {
      getFlows(setFlows, messageApi, setLoading, instance);
    }
  }, [instance]);

  // useEffect(() => {
  //   if (!loading) {
  //     setLoadingFilter(true);
  //     let flujos = flows;
  //     if (flows.length > 0) {
  //       if (statusFiltro !== "todos") {
  //         flujos = flujos.filter((flow) => flow.status === statusFiltro);
  //       }
  //     }

  //     setLoadingFilter(false);
  //   }
  // }, [flows, statusFiltro]);

  return (
    <Table
      columns={columnsFlows(navigate, setFlows, setLoading)}
      dataSource={filteredFlows}
      rowKey="_id"
      size="small"
      className="tabla-contactos"
      pagination={paginacion(filteredFlows, "flows")}
      loading={{
        spinning: loading,
        indicator: <LoadingIcon size="large" />,
      }}
    />
  );
};
