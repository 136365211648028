import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getAdditionalFields } from '../../../../../../../helpers/fetchData/fetchAdditionalFields';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import {
  getVisibleColumns,
  updateVisibleColumns
} from '../../../../../../../helpers/fetchData/fetchVisibleColumns';

export const ColumnsVisibility = () => {
  const [loadingAdditionalFields, setLoadingAdditionalFields] = useState(false);
  const [loadingVisibleColumns, setLoadingVisibleColumns] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [optionsCheckbox, setOptionsCheckbox] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);

  const [formColumnsVisibility] = Form.useForm();

  const { instance, messageApi } = useContext(GlobalContext);

  useEffect(() => {
    if (instance) {
      getVisibleColumns(
        instance,
        messageApi,
        setVisibleColumns,
        setLoadingVisibleColumns
      );

      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoadingAdditionalFields
      );
    }
  }, [instance]);

  useEffect(() => {
    let newOptions = [];

    if (visibleColumns) {
      visibleColumns.forEach((column) => {
        if (column.label && column.key) {
          newOptions.push({
            label: column.label,
            value: column.key
          });
        }
      });
    }

    additionalFields?.forEach((field) => {
      if (
        field.name &&
        !newOptions.some((option) => option.value === field.name)
      ) {
        newOptions.push({
          label: field.name,
          value: field.name
        });
      }
    });

    setOptionsCheckbox(newOptions);
  }, [visibleColumns, additionalFields]);

  useEffect(() => {
    if (optionsCheckbox && optionsCheckbox.length > 0) {
      let newCheckedOptions = [];

      if (visibleColumns) {
        visibleColumns.forEach((column) => {
          if (column.isVisible) {
            newCheckedOptions.push(column.key);
          }
        });
      }

      setCheckedOptions(newCheckedOptions);
    }
  }, [optionsCheckbox, visibleColumns]);

  function handleChangeCheckbox(checkedValues) {
    setCheckedOptions(checkedValues);
  }

  function handleFinish() {
    let newVisibleColumns = [];

    optionsCheckbox?.forEach((option) => {
      const isVisibleValue = checkedOptions.includes(option.value);

      newVisibleColumns.push({
        key: option.value,
        label: option.label,
        isVisible: isVisibleValue
      });
    });

    updateVisibleColumns(
      newVisibleColumns,
      instance,
      messageApi,
      setLoadingUpdate
    );

    setVisibleColumns(newVisibleColumns);
  }

  return (
    <Spin
      spinning={loadingAdditionalFields || loadingVisibleColumns}
      indicator={<LoadingOutlined size={26} spin style={{ marginTop: 50 }} />}
    >
      {additionalFields?.length > 0 ? (
        <Form
          form={formColumnsVisibility}
          name='columnsVisibility'
          layout='vertical'
          onFinish={handleFinish}
        >
          <p className='columns-visibility-info'>
            Seleccione las columnas que van a estar visibles en la tabla de
            contactos:
          </p>
          <Form.Item>
            <Checkbox.Group
              className='columns-visibility-checkbox-group'
              options={optionsCheckbox || []}
              value={checkedOptions}
              onChange={handleChangeCheckbox}
            />
          </Form.Item>

          <Form.Item className='columns-visibility-save-button'>
            <Button type='primary' htmlType='submit' loading={loadingUpdate}>
              Guardar
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <p className='columns-visibility-info'>
          No hay campos adicionales disponibles.
        </p>
      )}
    </Spin>
  );
};
