import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { Button, Form, Switch } from 'antd';
import { BoxInfo } from '../../../../../box-info/BoxInfo';
import { CUSTOM_LOCALE } from '../../../../enviosMasivos/enviosBuilder/utils/customLocale';
import Cron from 'react-js-cron';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { upsertContactConfig } from '../../../../../../../helpers/fetchData/fetchContactConfig';

export const ContactsLoadCron = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setContactsConfig,
  setMostrarRequestDrawer
}) => {
  const { instance, messageApi, chkCron, setChkCron } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [cronValue, setCronValue] = useState('30 * * * *');

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      let dataCron = {};

      if (!cronValue) {
        // Validar
        messageApi.info('No es un valor válido');
        return;
      }

      dataCron.schedule = [cronValue];

      dataCron.enable = values.chkEnableCron;

      let contactsLoad = {
        request: tmpConfig.contactsLoad?.request,
        fullRequest: tmpConfig.contactsLoad?.fullRequest,
        objMap: tmpConfig.contactsLoad?.objMap,
        cron: dataCron,
        includeClientId: tmpConfig.contactsLoad?.includeClientId
      };

      let data = { contactsLoad: contactsLoad };

      setLoading(true);

      upsertContactConfig(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Configuración guardada correctamente');
            setContactsConfig((prevState) => {
              let obj = { ...prevState };

              obj.contactsLoad = contactsLoad;

              return obj;
            });
            setMostrarRequestDrawer(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tmpConfig?.contactsLoad) {
      const { cron } = tmpConfig.contactsLoad;

      if (cron) {
        form.setFieldsValue({
          chkEnableCron: cron.enable
        });

        if (cron.enable) setChkCron(cron.enable);

        setCronValue(cron.schedule[0]);
      }
    }
  }, []);

  const onChangeCronSwitch = (v) => {
    setChkCron(!chkCron);
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='formText'
      requiredMark={false}
      onFinish={onFinish}
      className='form-nodos'
    >
      <b style={{ marginBottom: '12px' }}>
        Configuremos la frecuencia con la que se realizará la petición
      </b>
      <span style={{ marginBottom: '24px' }}>
        <BoxInfo
          message={[
            'Configure la frecuencia con la que hará la petición.',
            'Se realizará de manera automática en caso de ser activada.'
          ]}
        />
      </span>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-beetween',
          gap: '16px',
          marginTop: '20px'
        }}
      >
        <Form.Item name='chkEnableCron'>
          <Switch onChange={onChangeCronSwitch} />
        </Form.Item>
        <Cron
          value={cronValue}
          setValue={setCronValue}
          clearButton={false}
          locale={CUSTOM_LOCALE}
        />
      </div>

      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => handleStage(1)}
          >
            Volver
          </Button>

          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            icon={loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
