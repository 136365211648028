import { Drawer, Popover, Spin, Switch, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { FiPlay, FiSliders } from 'react-icons/fi';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { useAccess } from '../../../../../../../hooks/useAccess';
import { useNavigate } from 'react-router-dom';
import {
  getContactConfig,
  upsertContactConfig
} from '../../../../../../../helpers/fetchData/fetchContactConfig';
import { DrawerConfigContacts } from '../cargaContactos/DrawerConfigContacts';
import { LoadNotification } from '../../catalog/budgetConfigSteps/LoadNotification';
import {
  executeExternalContactsLoad,
  fetchContacts
} from '../../../../../../../helpers/fetchData/fetchContacts';
import { ResetContactConfig } from '../cargaContactos/ResetContactConfig';
import { GrPowerReset } from 'react-icons/gr';

const SIZE = 20;
const SETTED = 'Configurado';
const NOT_SETTED = 'No ha sido configurado aún';

export const UploadContacts = () => {
  const {
    instance,
    messageApi,
    notificationApi,
    chkCron,
    setChkCron,
    setContactos
  } = useContext(GlobalContext);

  const isAllowed = useAccess();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [loadingEjecutar, setLoadingEjecutar] = useState(false);
  const [loadingReestablecer, setLoadingReestablecer] = useState(false);
  const [loadingContactos, setLoadingContactos] = useState(false);
  const [showPopoverReset, setShowPopoverReset] = useState(false);
  const [contactsConfig, setContactsConfig] = useState(null);
  const [showContactConfig, setShowContactConfig] = useState(false);
  const [mostrarRequestDrawer, setMostrarRequestDrawer] = useState(false);
  const [stage, setStage] = useState(0);

  const closeModal = () => {
    setShowContactConfig(null);
  };

  const handleContactsLoad = () => {
    setLoadingEjecutar(true);
    executeExternalContactsLoad(instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          notificationApi.success({
            message: 'Carga de contactos completada correctamente.',
            description: <LoadNotification data={res.data} />,
            placement: 'bottomRight',
            duration: 10
          });

          fetchContacts(
            instance,
            setContactos,
            messageApi,
            setLoadingContactos
          );
        } else {
          messageApi.error(
            'Ocurrió un error al ejecutar la carga de contactos'
          );
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error('Ocurrió un error al ejecutar la carga de contactos');
      })
      .finally(() => setLoadingEjecutar(false));
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getContactConfig(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data) {
              setContactsConfig(res.data);
              if (res.data?.contactsLoad) {
                const { cron } = res.data?.contactsLoad;

                if (cron) {
                  if (cron.enable) {
                    setChkCron(cron.enable);
                  } else {
                    setChkCron(false);
                  }
                }
              }
            }
          } else {
            messageApi.error(
              'Ocurrió un error al obtener configuracion de contactos'
            );
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error(
            'Ocurrió un error al obtener configuracion de contactos'
          );
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  const onChangeHttp = (e) => {
    setChkCron(e);

    let data = { ...contactsConfig };
    data.contactsLoad.cron.enable = e;
    setLoadingSwitch(true);
    upsertContactConfig(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success('Configuración guardada correctamente');
          setContactsConfig((prevState) => {
            let obj = { ...prevState };
            obj.contactsLoad = data.contactsLoad;
            return obj;
          });
          closeModal();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoadingSwitch(false));
  };

  return (
    <div>
      {loading ? (
        <span
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Spin
            style={{
              marginTop: 100,
              marginInline: 'auto'
            }}
            indicator={<LoadingIcon size='large' />}
          />
        </span>
      ) : (
        <div>
          <div className='products-config'>
            <div className='products-config-section'>
              <span className='horizontal-between'>
                <p className='products-config-section-title'>Excel</p>
                <div
                  className='btn-config-container'
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Tooltip title='Configurar'>
                    <span
                      onClick={() => {
                        setShowContactConfig('excelLoad');
                        setMostrarRequestDrawer(true);
                        setStage(3);
                      }}
                      style={{ height: SIZE }}
                    >
                      <FiSliders size={SIZE} className='pointer' />
                    </span>
                  </Tooltip>
                </div>
              </span>

              <p className='products-config-section-subtitle'>
                {contactsConfig?.contactsLoadExcel ? SETTED : NOT_SETTED}{' '}
                {/* Esto es el configurado o no configurado*/}
              </p>
            </div>

            <div className='products-config-section'>
              <span className='horizontal-between'>
                <p className='products-config-section-title'>HTTP Request</p>
                <div
                  className='btn-config-container'
                  style={{ justifyContent: 'flex-end' }}
                >
                  {contactsConfig?.contactsLoad ? (
                    <Tooltip title='Petición automática'>
                      <span>
                        <Switch
                          checked={chkCron}
                          onChange={onChangeHttp}
                          loading={loadingSwitch}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    ''
                  )}

                  <Tooltip title='Configurar'>
                    <span
                      onClick={() => {
                        setShowContactConfig('httpLoad');
                        setMostrarRequestDrawer(true);
                        setStage(0);
                      }}
                      style={{ height: SIZE }}
                    >
                      <FiSliders size={SIZE} className='pointer' />
                    </span>
                  </Tooltip>
                </div>
              </span>

              <p className='products-config-section-subtitle'>
                {contactsConfig?.contactsLoad ? SETTED : NOT_SETTED}
              </p>

              {contactsConfig?.contactsLoad && (
                <div style={{ display: 'flex', gap: 12, marginLeft: 'auto' }}>
                  <Tooltip title='Reestablecer'>
                    <Popover
                      open={showPopoverReset}
                      content={
                        <ResetContactConfig
                          setShowPopover={setShowPopoverReset}
                        />
                      }
                    >
                      <span>
                        {loadingReestablecer ? (
                          <LoadingIcon size='default' />
                        ) : (
                          <GrPowerReset
                            size={SIZE}
                            className='pointer'
                            onClick={() => setShowPopoverReset(true)}
                          />
                        )}
                      </span>
                    </Popover>
                  </Tooltip>

                  <Tooltip title='Ejecutar'>
                    <span
                      onClick={handleContactsLoad}
                      // style={{ marginLeft: 'auto' }}
                    >
                      {loadingEjecutar ? (
                        <LoadingIcon size='default' />
                      ) : (
                        <FiPlay size={SIZE} className='pointer' />
                      )}
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>

            <Drawer
              open={mostrarRequestDrawer}
              width={450}
              className='drawer-vars'
              destroyOnClose
            >
              {showContactConfig === 'httpLoad' && (
                <DrawerConfigContacts
                  contactsConfig={contactsConfig}
                  setContactsConfig={setContactsConfig}
                  setMostrarRequestDrawer={setMostrarRequestDrawer}
                  setStage={setStage}
                  stage={stage}
                />
              )}

              {showContactConfig === 'excelLoad' && (
                <DrawerConfigContacts
                  contactsConfig={contactsConfig}
                  setContactsConfig={setContactsConfig}
                  setMostrarRequestDrawer={setMostrarRequestDrawer}
                  setStage={setStage}
                  stage={stage}
                />
              )}
            </Drawer>
          </div>
        </div>
      )}
    </div>
  );
};
