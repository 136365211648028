import { useContext, useEffect, useState } from "react";
import { CreateErrorMessages } from "./CreateErrorMessages";
import { EditErrorMessages } from "./EditErrorMessages";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { fetchConfig } from "../../../../../../helpers/fetchData/fetchConfig";
import { BiMessageError } from "react-icons/bi";
import { accessDataFormat } from "../../../../../../helpers/access";
import { useAccess } from "../../../../../../hooks/useAccess";
import LoadingIcon from "../../../../Loader/LoadingIcon";

export const ErrorMessages = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const [config, setConfig] = useState(null);
  const [errorMessagesStage, setErrorMessagesStage] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleStage = () => {
    switch (errorMessagesStage) {
      case 0:
        return (
          isAllowed(accessDataFormat("errorMessages", "manage")) && (
            <CreateErrorMessages
              setErrorMessagesStage={setErrorMessagesStage}
            />
          )
        );
      case 1:
        return (
          isAllowed(accessDataFormat("errorMessages", "manage")) && (
            <EditErrorMessages
              setErrorMessagesStage={setErrorMessagesStage}
              config={config}
            />
          )
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (instance?._id) {
      fetchConfig(instance, setConfig, messageApi);
    } else {
      setLoading(false);
    }
  }, [instance]);

  useEffect(() => {
    if (config) {
      if (config.defaultMessages) {
        setErrorMessagesStage(1);
      }

      setLoading(false);
    }
  }, [config]);

  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <BiMessageError size={24} color="#E57373" />
              </span>
              <span className="header-title-templates">Mensajes de error</span>
            </span>
            <p className="header-subtitle">
              Configure mensajes que se dispararán en distintos casos de error.
            </p>
          </span>
        </span>
      </div>
      {loading ? (
        <span
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Spin
            style={{
              marginTop: 100,
              marginInline: "auto",
            }}
            indicator={<LoadingIcon size="large" />}
          />
        </span>
      ) : (
        instance?._id && <>{handleStage()}</>
      )}
    </div>
  );
};
