import axios from "axios";

export const sendCampaign = async (key, data) => {
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/campaign/newCampaign`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getCampaigns = async (key, data) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/campaign/getCampaigns`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getInnerMetrics = async (key, id) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/campaign/innerMetric/${id}`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCampaign = async (key, id, data) => {
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/campaign/update/${id}`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCampaign = async (key, id) => {
  try {
    let config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_PRODUCCION}/campaign/delete/${id}`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getCampaignsMetrics = async (ids, apiKey) => {
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/campaign/campaignsMetrics`,
      headers: {
        "Content-Type": "application/json",
        Authorization: apiKey,
      },
      data: {
        ids,
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
