import { BranchNode } from "./customNodes/BranchNode";
import { BudgetNode } from "./customNodes/BudgetNode";
import { ButtonNode } from "./customNodes/ButtonNode";
import { DefaultNode } from "./customNodes/DefaultNode";
import { EndNode } from "./customNodes/EndNode";
import { HttpRequestNode } from "./customNodes/HttpRequestNode";
import { IterableListNode } from "./customNodes/IterableListNode";
import { ListNode } from "./customNodes/ListNode";
import { MessageNode } from "./customNodes/MessageNode";
import { MultiProductNode } from "./customNodes/MultiProductNode";
import { OneTimePasswordNode } from "./customNodes/OneTimePasswordNode";
import { SaveResponseNode } from "./customNodes/SaveResponseNode";
import { SendTemplateNode } from "./customNodes/SendTemplateNode";
import { SetDelayNode } from "./customNodes/SetDelayNode";
import { SetVariablesNode } from "./customNodes/SetVariablesNode";
import { SingleProductNode } from "./customNodes/SingleProductNode";
import { TimeNode } from "./customNodes/TimeNode";
import { TranscribeAudioNode } from "./customNodes/TranscribeAudioNode";
import { TriggerNode } from "./customNodes/TriggerNode";
import { WorkingNode } from "./customNodes/WorkingNode";
import { EncodeNode } from "./customNodes/EncodeNode";
import { CtaButtonNode } from "./customNodes/CtaButtonNode";
import { DecodeNode } from "./customNodes/DecodeNode";
import { CallFunctionNode } from "./customNodes/CallFunctionNode";
import { BroadcastNode } from "./customNodes/BroadcastNode";
import { ContactAgentNode } from "./customNodes/ContactAgentNode";
import { IsContactNode } from "./customNodes/IsContactNode";
import { FormulaNode } from "./customNodes/FormulaNode";
import { SendMessageToChatNode } from "./customNodes/SendMessageToChatNode";
import { TagContactNode } from "./customNodes/TagContactNode";
import { SubscriptionNode } from "./customNodes/SubscriptionNode";
import { GoToFlowNode } from "./customNodes/GoToFlowNode";
import { SendEmailNode } from "./customNodes/SendEmailNode";
import { NoteNode } from "./customNodes/NoteNode";

export const nodeTypes = {
  trigger: TriggerNode,
  message: MessageNode,
  buttons: ButtonNode,
  list: ListNode,
  inicial: DefaultNode,
  end: EndNode,
  branch: BranchNode,
  dateTime: TimeNode,
  transcribeAudio: TranscribeAudioNode,
  httpRequest: HttpRequestNode,
  setDelay: SetDelayNode,
  saveResponse: SaveResponseNode,
  sendTemplate: SendTemplateNode,
  iterableList: IterableListNode,
  setVariables: SetVariablesNode,
  // textToSpeech: TextToSpeechNode,
  workingNode: WorkingNode,
  oneTimePassword: OneTimePasswordNode,
  sendMultiProduct: MultiProductNode,
  sendSingleProduct: SingleProductNode,
  budget: BudgetNode,
  encode: EncodeNode,
  cta_button: CtaButtonNode,
  decode: DecodeNode,
  callFunction: CallFunctionNode,
  broadcast: BroadcastNode,
  contactAgent: ContactAgentNode,
  isContact: IsContactNode,
  formula: FormulaNode,
  sendMessageToChat: SendMessageToChatNode,
  tagContact: TagContactNode,
  subscription: SubscriptionNode,
  goToFlow: GoToFlowNode,
  sendEmail: SendEmailNode,
  note: NoteNode,
};

export const NODE_TYPES = [
  { id: "note", name: "Nota" },
  {
    id: "message",
    name: "Enviar mensaje",
  },
  { id: "buttons", name: "Mensaje con botones" },
  {
    id: "list",
    name: "Mensaje con lista",
  },
  { id: "cta_button", name: "Botón URL" },
  { id: "branch", name: "Ramas" },
  { id: "budget", name: "Presupuesto" },
  { id: "dateTime", name: "Horarios" },
  { id: "transcribeAudio", name: "Transcribir audio" },
  { id: "httpRequest", name: "Petición HTTP" },
  { id: "iterableList", name: "Lista iterable" },
  { id: "setDelay", name: "Demora" },
  { id: "sendTemplate", name: "Enviar template" },
  { id: "tagContact", name: "Etiquetar contacto" },
  { id: "setVariables", name: "Set variables" },
  // { id: "sendSingleProduct", name: "Producto único" },
  // { id: "sendMultiProduct", name: "Multi-producto" },
  { id: "oneTimePassword", name: "Contraseña de un solo uso" },
  { id: "encode", name: "Codificar" },
  { id: "decode", name: "Decodificar" },
  { id: "callFunction", name: "Interacción única ChatGPT" },
  { id: "isContact", name: "¿Es contacto?" },
  { id: "formula", name: "Convertir" },
  // { id: "contactAgent", name: "Contactar agente" },
  // { id: "sendMessageToChat", name: "Guardar mensaje en chat" },
  { id: "subscription", name: "Suscribir contacto" },
  { id: "goToFlow", name: "Redirigir a otro bot" },
  { id: "sendEmail", name: "Enviar correo electrónico" },
  { id: "end", name: "Fin de flujo" },
  // { id: "textToSpeech", name: "Texto a audio" },
];
