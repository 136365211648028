import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin, Transfer } from "antd";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { updateTagContacts } from "../../../../../helpers/fetchData/fetchTags";
import { Tag } from "../../../tag/Tag";
import { getContactName } from "../../campaigns/sendTemplates/utils/getContactName";
import LoadingIcon from '../../../Loader/LoadingIcon';

export const ManageTagContacts = () => {
  const {
    contactos,
    rightDrawerConfig,
    setRightDrawerConfig,
    refreshContactos,
    setRefreshContactos,
    messageApi,
    instance,
  } = useContext(GlobalContext);

  const initialTargetKeys = contactos
    ?.filter((contacto) => {
      return contacto.tags?.some((tag) => {
        return tag === rightDrawerConfig?.item?._id;
      });
    })
    .map((contacto) => contacto._id);

  const [targetKeys, setTargetKeys] = useState(initialTargetKeys);

  const [loading, setLoading] = useState(false);

  const onChangeTransfer = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const renderItem = (item) => {
    return {
      label: item.fullName,
      value: item._id,
    };
  };

  const filterOption = (value, option) => {
    return option?.fullName?.toLowerCase()?.includes(value.toLowerCase());
  };

  const updateTags = () => {
    let contactsToSend = [...initialTargetKeys];

    targetKeys.forEach((key) => {
      if (!contactsToSend.includes(key)) {
        contactsToSend.push(key);
      } else {
        let index = contactsToSend.indexOf(key);
        contactsToSend.splice(index, 1);
      }
    });

    let data = JSON.stringify({
      tagId: rightDrawerConfig?.item?._id,
      contacts: contactsToSend,
    });

    setLoading(true);
    updateTagContacts(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Contactos etiquetados correctamente.");
          setRefreshContactos(!refreshContactos);
          setRightDrawerConfig({ visible: false, content: "" });
        } else {
          messageApi.error("No se pudo etiquetar contactos.");
        }
      })
      .catch((error) => {
        messageApi.error("No se pudo etiquetar contactos.");
      })
      .finally(() => setLoading(false));
  };

  const formattedContacts = useMemo(() => {
    if (!contactos || contactos.length < 1) {
      return [];
    }

    return contactos.map((element) => {
      element.fullName = getContactName(element.name);

      return element;
    });
  }, [contactos]);

  return (
    <div className="asignar-contactos-wrapper form-nodos">
      <div className="asignar-header-wrapper">
        <p style={{ fontWeight: 600 }}>Etiquetar contactos</p>
        <Tag
          hex={rightDrawerConfig?.item?.color}
          nombre={rightDrawerConfig?.item?.name}
          key={rightDrawerConfig?.item?._id}
        />
      </div>
      <div>
        {targetKeys !== "undefined" ? (
          <Transfer
            style={{ width: "100%" }}
            dataSource={formattedContacts || []}
            targetKeys={targetKeys}
            filterOption={filterOption}
            onChange={onChangeTransfer}
            render={renderItem}
            titles={["Contactos", "Seleccionados"]}
            showSelectAll
            listStyle={{
              width: "calc(50% - 20px)",
              height: "calc(100vh - 140px)",
              minHeight: "400px",
            }}
            rowKey={(record) => record._id}
            showSearch={true}
            // pagination={{ pageSize: 15, simple: false }}
            // showSelectAll={true}
            locale={{
              itemUnit: "",
              itemsUnit: "",
              searchPlaceholder: "Buscar",
            }}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spin style={{ marginTop: "100px" }}
              indicator={
                // <LoadingOutlined
                //   style={{
                //     fontSize: 24,
                //     marginTop: 100,
                //   }}
                //   spin
                // />
                <LoadingIcon size="large" />
              }
            />
          </div>
        )}
      </div>
      <span className="form-custom-footer" style={{ marginBottom: 12 }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
            onClick={() => updateTags()}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() =>
              setRightDrawerConfig({
                visible: false,
                content: "",
              })
            }
          >
            Cancelar
          </Button>
        </div>
      </span>
    </div>
  );
};
