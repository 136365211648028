import { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { Button, Form, Input, Radio, Select } from 'antd';
import { updateNode } from '../utils/updateNode';
import { PiMathOperations } from 'react-icons/pi';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { getVariableName } from '../utils/getVariableName';
import { FORMULAS } from '../utils/formulas';
import { HeaderForm } from './componentsForm/HeaderForm';

const INPUT_TYPES = [
  { value: 'string', label: 'Texto' },
  { value: 'number', label: 'Número' },
  { value: 'variable', label: 'Variable' },
  { value: 'json', label: 'JSON' }
];

const InputTypes = ({ element, index, setInput }) => {
  const { flowVariables } = useContext(FlowContext);

  const [hasVars, setHasVars] = useState(false);
  const [bodyVars, setBodyVars] = useState([]);

  const handleValue = (value) => {
    setInput((prevState) => {
      let array = [...prevState];

      array[index] = {
        ...array[index],
        value: value
      };

      return array;
    });
  };

  if (element.type === 'string') {
    return (
      <Input.TextArea
        value={element.value}
        onChange={(v) => handleValue(v.target.value)}
      />
    );
  } else if (element.type === 'number') {
    return (
      <Input
        type='number'
        value={element.value}
        onChange={(v) => handleValue(v.target.value)}
      />
    );
  } else if (element.type === 'variable') {
    return (
      <Select
        value={element.value}
        onChange={(v) => handleValue(v)}
        options={flowVariables?.map((element) => {
          return { value: element.name, label: element.name };
        })}
      />
    );
  } else if (element.type === 'JSON') {
    // return (
    //   <>
    //     {hasVars ? (
    //       <div className="variables-body-wrapper">
    //         <span className="horizontal variables-body-header-wrapper">
    //           <p className="variables-body-header">Key</p>
    //           <p className="variables-body-header">Value</p>
    //           <p className="variables-body-header">Type</p>
    //         </span>
    //         {element.value?.map((variable, index) => {
    //           return (
    //             <span className="variable-body-item" key={index}>
    //               <span className="columna-body-item">
    //                 <Input
    //                   value={variable.key}
    //                   onChange={(v) =>
    //                     handleValueItemBody(
    //                       setBodyVars,
    //                       index,
    //                       "key",
    //                       v.target.value
    //                     )
    //                   }
    //                 />
    //               </span>
    //               <span className="columna-body-item">
    //                 <Input
    //                   value={variable.value}
    //                   onChange={(v) =>
    //                     handleValueItemBody(
    //                       setBodyVars,
    //                       index,
    //                       "value",
    //                       v.target.value
    //                     )
    //                   }
    //                   addonAfter={<AddonVariable index={index} />}
    //                 />
    //               </span>
    //               <Select
    //                 // value={handleVariableType(
    //                 //   variable.type,
    //                 //   variable.value,
    //                 //   flowVariables
    //                 // )}
    //                 onChange={(v) => {
    //                   //   handleValueItemBody(setBodyVars, index, "type", v)
    //                 }}
    //                 options={[
    //                   { value: "string", label: "String" },
    //                   { value: "number", label: "Number" },
    //                 ]}
    //               />
    //               <span
    //                 style={{ cursor: "pointer" }}
    //                 // onClick={() => deleteItemBody(setBodyVars, index)}
    //               >
    //                 <FiX />
    //               </span>
    //             </span>
    //           );
    //         })}
    //         <span style={{ width: "100%", display: "flex", margin: "8px 0px" }}>
    //           <Button
    //             className="btn-aceptar btn-oscuro"
    //             style={{ margin: "0px 8px 8px auto" }}
    //             type="primary"
    //             size="small"
    //             // onClick={() => addBodyItem(setBodyVars)}
    //           >
    //             Agregar item
    //           </Button>
    //         </span>
    //       </div>
    //     ) : (
    //       <Form.Item>
    //         <Input.TextArea
    //           autoSize={{ minRows: 2 }}
    //           onChange={(v) => handleValue(v.target.value)}
    //           value={element.value}
    //         />
    //       </Form.Item>
    //     )}
    //   </>
    // );
  }
};

export const FormulaForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    setFlowVariables,
    setEdges
  } = useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [selectedType, setSelectedType] = useState(null);

  const [selectedOperation, setSelectedOperation] = useState(null);

  const [input, setInput] = useState([{ type: 'string', value: '' }]);

  const onFinish = (values) => {
    values.label = nombreNodo;

    values.type = selectedType;

    values.operation = selectedOperation;

    values.input = input;

    if (!values.variableName) {
      return messageApi.error('Ingrese un nombre para la variable');
    }
    values.variable = { name: '', type: values.variableType };

    let variableName = getVariableName(values.variableName, 'formula');
    values.variableName = variableName;
    values.variable.name = variableName;
    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      values.variable,
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {

    let operation = FORMULAS.find(
      (element) => element.value === selectedType
    )?.formulas.find((element) => element.value === selectedOperation);

    if (operation) {
      if (operation.multiple) {
        setInput((prevState) => {
          let array = [...prevState];

          if (array.length < 2) {
            array.push({ ...array[0], value: null });
          }

          return array;
        });
      }
    } else {
      setInput([{ type: 'string', value: '' }]);
    }
  }, [selectedOperation]);

  const formulaOptions = useMemo(() => {
    if (selectedType) {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas ||
        []
      );
    } else {
      return [];
    }
  }, [selectedType]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { data } = nodoSeleccionado;

      setNombreNodo(data.label || '');

      if (data.type) {
        setSelectedType(data.type);
      }

      if (data.operation) {
        setSelectedOperation(data.operation);
      }

      if (data.input) {
        setInput(data.input);
      }

      if (data.variableName) {
        form.setFieldsValue({
          variableName: data.variableName.substring(
            data.variableName.indexOf('_') + 1
          )
        });
      }

      if (data.variableType) {
        form.setFieldsValue({
          variableType: data.variable.type || 'string'
        });
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='form-formula'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <PiMathOperations style={{ color: 'var(--dark-color)' }} size={32} />
        }
      />

      <Form.Item label='Tipo de operación'>
        <Select
          options={FORMULAS}
          value={selectedType || null}
          onChange={(v) => {
            setSelectedOperation(null);
            setSelectedType(v);
          }}
        />
      </Form.Item>
      {selectedType && (
        <Form.Item label='Operación a realizar'>
          <Select
            options={formulaOptions || []}
            value={selectedOperation || null}
            onChange={(v) => setSelectedOperation(v)}
          />
        </Form.Item>
      )}
      {input?.map((element, index) => {
        return (
          <Form.Item key={index} label='Ingrese un valor (input)'>
            <>
              <Radio.Group
                optionType='button'
                buttonStyle='solid'
                options={INPUT_TYPES}
                value={element.type}
                onChange={(v) =>
                  setInput((prevState) => {
                    let array = [...prevState];

                    array[index] = {
                      ...array[index],
                      type: v.target.value,
                      value: null
                    };

                    return array;
                  })
                }
                style={{ marginBottom: 8 }}
              />
              <InputTypes
                element={element}
                index={index}
                setInput={setInput}
                input={input}
              />
            </>
          </Form.Item>
        );
      })}
      <span className='horizontal' style={{ justifyContent: 'space-between' }}>
        <Form.Item
          label='Nombre de la variable (output)'
          name='variableName'
          style={{ width: '63%' }}
        >
          <Input addonBefore='formula' placeholder='response' />
        </Form.Item>
        <Form.Item
          label='Tipo'
          name='variableType'
          value='string'
          initialValue='string'
          style={{ width: '35%' }}
        >
          <Select
            options={[
              { value: 'string', label: 'String' },
              { value: 'number', label: 'Number' },
              { value: 'json', label: 'JSON' },
              { value: 'boolean', label: 'Boolean' }
            ]}
          />
        </Form.Item>
      </span>
      {/* <Form.Item
        label="Nombre de la variable de salida (output)"
        name="variableName"
        initialValue={nodoSeleccionado?.data?.variableName}
      >
        <Input addonBefore="formula_" style={{ width: "63%" }} />
      </Form.Item> */}
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
