import axios from 'axios';
import { getContactName } from '../../components/ui/Layout/campaigns/sendTemplates/utils/getContactName';

export function getBlacklist(
  instance,
  messageApi,
  setBloqueados,
  setLoading = null
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_PRODUCCION}/blacklist/getBlacklist`,
    headers: {
      Authorization: instance?.userData?.apiKey
    }
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setBloqueados(res.data);
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error('Error al cargar los contactos bloqueados');
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}

export function addToBlacklist(
  item,
  instance,
  messageApi,
  setContactos,
  setLoading,
  setShowPopover
) {
  setLoading(true);

  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_PRODUCCION}/blacklist/manageBlacklist`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data: {
      addNumbers: [item.phones?.[0]?.phone]
    }
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setContactos((prevState) => {
          const newArray = prevState.map((contacto) => {
            if (contacto._id === item._id) {
              return { ...contacto, isBlocked: true };
            }
            return contacto;
          });

          return newArray;
        });
        setShowPopover(false);
        messageApi.success(`${getContactName(item.name)} bloqueado`);
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error('Error al bloquear contacto');
    })
    .finally(setLoading(false));
}

export function deleteToBlacklist(
  item,
  instance,
  messageApi,
  setContactos,
  setLoading,
  setShowPopover
) {
  setLoading(true);

  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_PRODUCCION}/blacklist/manageBlacklist`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data: {
      removeNumbers: [item.phones?.[0]?.phone]
    }
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setContactos((prevState) => {
          const newArray = prevState.map((contacto) => {
            if (contacto._id === item._id) {
              return { ...contacto, isBlocked: false };
            }
            return contacto;
          });

          return newArray;
        });
        setShowPopover(false);
        messageApi.success(`${getContactName(item.name)} desbloqueado`);
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error('Error al desbloquear contacto');
    })
    .finally(setLoading(false));
}
