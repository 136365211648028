import { Table, Input, Button } from "antd";
import { useContext, useMemo, useState } from "react";
import { listasColumns } from "./utils/columns";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { FiPlus } from "react-icons/fi";
import { SearchOutlined } from "@ant-design/icons";

export const TablaListas = () => {
  const { setConfigDrawerContent, instance, messageApi } =
    useContext(GlobalContext);
  const { catalogDetail } = useContext(SettingsContext);

  const [busqueda, setBusqueda] = useState("");

  const filteredLists = useMemo(() => {
    let array = [];

    if (catalogDetail?.lists?.length > 0) {
      array = catalogDetail.lists;

      if (busqueda) {
        array = array.filter((element) =>
          element.name.toLowerCase()?.includes(busqueda.toLowerCase())
        );
      }
    }

    return array;
  }, [busqueda, catalogDetail]);

  return (
    <>
      <span className="horizontal" style={{ marginBottom: "16px" }}>
        <Input
          type="search"
          placeholder="Buscar estados"
          onChange={(v) => setBusqueda(v.target.value)}
          addonAfter={<SearchOutlined onClick={() => {}} />}
          style={{ maxWidth: "200px", marginLeft: "auto" }}
        />
        <Button
          className="btn-agregar boton-dashed-32"
          onClick={() =>
            setConfigDrawerContent({
              visible: true,
              content: "newList",
              item: null,
            })
          }
        >
          <FiPlus />
          <p>Lista</p>
        </Button>
      </span>
      <Table
        columns={listasColumns(
          setConfigDrawerContent,
          catalogDetail,
          messageApi,
          instance
        )}
        dataSource={filteredLists}
        rowKey={"_id"}
        size="small"
        pagination={false}
        className="tabla-config"
      />
    </>
  );
};
