import axios from "axios";

export const fetchConfig = (instance, setConfig, messageApi) => {
  if (instance?._id) {
    let count = 0;
    const fetch = () => {
      if (count <= 5) {
        count += 1;
        getConfig(instance?.userData?.apiKey)
          .then((res) => {
            console.log(res.data);
            if (res?.status === 200) {
              if (res.data?.config) {
                setConfig(res.data.config);
              }
            }
          })
          .catch(function (error) {
            setTimeout(() => {
              fetch();
            }, 2000);
          });
      } else {
        messageApi.error("Error al obtener configuración");
      }
    };
    fetch();
  }
};

export const getConfig = async (key) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/config/getConfig`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const createDefaultMessages = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/config/createDefaultMessages`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: { defaultMessages: data },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const editDefaultMessages = async (key, data) => {
  console.log(data);
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/config/editDefaultMessages`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: { defaultMessages: data },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const createDefaultTemplate = async (key, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/config/defaultTemplate`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: { template: data },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const DEFAULT_MESSAGES = {
  defaultControlVarType: "La respuesta ingresada no es válida.",
  defaultTranscribe: "No fue posible transcribir el audio.",
  defaultHttp: "El resultado de la petición esta vacio.",
  defaultErrorHttp: "Se produjo un error en la petición.",
};
