import { Button, Input, Form } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  DEFAULT_MESSAGES,
  editDefaultMessages,
} from "../../../../../../helpers/fetchData/fetchConfig";
import { GlobalContext } from "../../../../../context/GlobalContext";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const EditErrorMessages = ({ setErrorMessagesStage, config }) => {
  const [form] = Form.useForm();

  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [editar, setEditar] = useState(false);

  const onFinish = (values) => {
    const filteredObj = Object.entries(values)
      .filter(([key, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    setLoading(true);
    editDefaultMessages(instance?.userData?.apiKey, filteredObj)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Configuración guardada correctamente");
        } else {
          messageApi.error("No se pudo guardar la configuración");
        }
      })
      .catch(() => {
        messageApi.error("No se pudo guardar la configuración");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (config?.defaultMessages) {
      form.setFieldsValue({
        defaultControlVarType: config.defaultMessages?.defaultControlVarType,
        defaultErrorHttp: config.defaultMessages?.defaultErrorHttp,
        defaultHttp: config.defaultMessages?.defaultHttp,
        defaultTranscribe: config.defaultMessages?.defaultTranscribe,
      });
    }
  }, [config]);

  return (
    <div className="conf-content">
      <Form
        form={form}
        name="errorMessages"
        layout="vertical"
        onFinish={(v) => onFinish(v)}
        style={{ padding: "0px", display: "flex", flexDirection: "column" }}
        disabled={!editar}
      >
        <div className="columna" style={{ margin: "8px 0px 16px 0px" }}>
          <b>Variables</b>
          <div className="item-wrapper" style={{ backgroundColor: "#fbfbfb" }}>
            <Form.Item
              label="Validación de tipo de variable"
              name="defaultControlVarType"
              style={{ marginBottom: 8 }}
            >
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultControlVarType}
              />
            </Form.Item>
          </div>
          <b>Peticiones HTTP</b>
          <div className="item-wrapper" style={{ backgroundColor: "#fbfbfb" }}>
            <Form.Item label="Fallo en una petición" name="defaultErrorHttp">
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultErrorHttp}
              />
            </Form.Item>
            <Form.Item
              label="Respuesta vacia a una petición"
              name="defaultHttp"
              style={{ marginBottom: 8 }}
            >
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultHttp}
              />
            </Form.Item>
          </div>
          <b>Transcripción de audio</b>
          <div className="item-wrapper" style={{ backgroundColor: "#fbfbfb" }}>
            <Form.Item
              label="Fallo en la transcripción de audio"
              name="defaultTranscribe"
              style={{ marginBottom: 8 }}
            >
              <Input
                style={{ color: "rgba(0, 0, 0, 0.88)" }}
                placeholder={DEFAULT_MESSAGES.defaultTranscribe}
              />
            </Form.Item>
          </div>
        </div>
        <div className="botones-wrapper-content" style={{ marginTop: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            type="secondary"
            size="large"
            disabled={false}
            onClick={() => setEditar((prevState) => !prevState)}
          >
            {editar ? "Cancelar" : "Editar"}
          </Button>
        </div>
      </Form>
    </div>
  );
};
