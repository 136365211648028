import axios from 'axios';

export const getClosingTypes = async (apiKey) => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/closingTypes/getClosingTypes`,
      headers: {
        Authorization: apiKey
      }
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newClosingType = async (apiKey, data) => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/closingTypes/newClosingType`,
      headers: {
        Authorization: apiKey
      },
      data: data
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const editClosingType = async (apiKey, id, data) => {
  console.log('apiKey', apiKey);
  console.log('id', id);
  console.log('data', data);

  try {
    const config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PRODUCCION}/closingTypes/editClosingType/${id}`,
      headers: {
        Authorization: apiKey
      },
      data: data
    };

    console.log('config', config);

    const res = await axios.request(config);

    console.log('res', res);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const deleteClosingType = async (apiKey, id) => {
  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_PRODUCCION}/closingTypes/deleteClosingType/${id}`,
      headers: {
        Authorization: apiKey
      }
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
