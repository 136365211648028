import { useAuth0 } from "@auth0/auth0-react";
import { Image } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { encode } from "js-base64";

const EntryView = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      window.localStorage.setItem("token", encode(JSON.stringify(user)));
      //* gestionar router, use contacto para navigate a una ruta privada
      navigate("/main");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="instance-main-container">
      <div>
        <Image
          style={{ cursor: "pointer" }}
          src="https://binamics.nyc3.digitaloceanspaces.com/logos/logo-brocoly-02.png"
          preview={false}
          width={200}
          onClick={() => {
            loginWithRedirect({ ui_locales: "es" });
          }}
        />
      </div>
    </div>
  );
};

export default EntryView;
