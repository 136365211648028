import { Button, Form, Tooltip, Transfer } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { SettingsContext } from "../../../../../../context/SettingsContext";
import { getProductTooltipTitle } from "../../catalog/utils/getProductTooltipTitle";
import { updateListItems } from "../../../../../../../helpers/fetchData/fetchCatalog";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const ManageListProducts = () => {
  const { setConfigDrawerContent, configDrawerContent, messageApi, instance } =
    useContext(GlobalContext);

  const { catalogDetail, setCatalogDetail } = useContext(SettingsContext);

  const [targetKeys, setTargetKeys] = useState([]);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const validateSKUs = () => {
    let allProducts = [];
    catalogDetail.products.forEach((lista) =>
      lista.products?.forEach((producto) => {
        if (targetKeys.includes(producto.id)) {
          allProducts.push(producto.sku);
        }
      })
    );

    return new Set(allProducts).size !== allProducts.length;
  };

  const onFinish = (v) => {
    if (!configDrawerContent?.item?._id) {
      messageApi.info("No se pudo identificar la lista");
    }

    if (validateSKUs()) {
      return messageApi.info(
        "Se encontraron 2 o mas productos con el mismo SKU"
      );
    }

    let data = {
      listId: configDrawerContent.item._id,
      productsToUpdate: targetKeys,
    };

    setLoading(true);

    updateListItems(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Lista actualizada correctamente");
          // setCatalogDetail(res.data);

          setCatalogDetail((prevState) => {
            let obj = { ...prevState };

            let listas = obj.lists.map((lista) => {
              if (lista._id === configDrawerContent.item._id) {
                return { ...lista, products: targetKeys };
              }
            });

            obj.lists = listas;

            return obj;
          });

          setConfigDrawerContent({ visible: false, item: null, content: "" });
        } else {
          messageApi.error("Ocurrió un error al actualizar una lista");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al actualizar una lista");
      })
      .finally(() => setLoading(false));
  };

  const filterOption = (inputValue, option) => {
    let inputLowerCase = inputValue.toLowerCase();

    if (option.title.toLowerCase().indexOf(inputLowerCase) > -1) {
      return true;
    }

    let sku = option?.sku?.toString()?.toLowerCase() || "";

    if (sku.indexOf(inputLowerCase) > -1) {
      return true;
    }

    return false;
  };

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const getOptions = () => {
    if (Array.isArray(catalogDetail?.products)) {
      return catalogDetail.products;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (configDrawerContent.item?.products) {
      const { products } = configDrawerContent.item;

      // Usa ids, no _ids
      const productosListaSeleccionada = products;

      setTargetKeys(productosListaSeleccionada);
    }
  }, [configDrawerContent, catalogDetail]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="newImagen"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>
        {configDrawerContent?.item?.list?.name}
      </p>
      <Form.Item label="Seleccionar artículos">
        <Transfer
          style={{ width: "100%" }}
          dataSource={getOptions()}
          showSearch
          filterOption={filterOption}
          onChange={handleChange}
          targetKeys={targetKeys}
          rowKey={(record) => record.id}
          listStyle={{
            width: "calc(50% - 20px)",
            height: "calc(100vh - 200px)",
          }}
          locale={{
            itemUnit: "",
            itemsUnit: "",
            searchPlaceholder: "Buscar",
          }}
          titles={["Artículos", "Seleccionados"]}
          render={(item) => {
            return (
              <Tooltip
                title={getProductTooltipTitle(item, catalogDetail?.lists)}
              >
                <span
                  className="ant-transfer-list-content-item-text"
                  key={item.id}
                >
                  {item?.title}
                </span>
              </Tooltip>
            );
          }}
        />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setConfigDrawerContent({
                visible: false,
                content: "",
                item: null,
              });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
