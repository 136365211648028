import axios from 'axios';

export const fetchTags = (
  instance,
  setEtiquetas,
  messageApi,
  setLoadingEtiquetas
) => {
  if (instance?._id) {
    setLoadingEtiquetas(true);
    let count = 0;
    let fetch = () => {
      if (count <= 5) {
        count += 1;
        getTags(instance)
          .then((res) => {
            if (res?.status === 200) {
              setLoadingEtiquetas(false);
              if (Array.isArray(res.data)) {
                setEtiquetas(res.data);
              }
            } else {
              setTimeout(() => {
                fetch();
              }, 2000);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              fetch();
            }, 2000);
          })
          .finally(() => setLoadingEtiquetas(false));
      } else {
        messageApi.error('Error al obtener etiquetas');
      }
    };

    fetch();
  }
};

export const getTags = async (instance) => {
  try {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/tags/getTags?instanceId=${instance?._id}`,
      headers: {
        Authorization: `${instance?.userData?.apiKey}`,
        'Content-Type': 'application/json'
      }
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTag = async (key, data) => {
  try {
    let config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_PRODUCCION}/tags/deleteTag`,
      headers: {
        Authorization: key,
        'Content-Type': 'application/json'
      },
      data: data
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateTagContacts = async (key, data) => {
  try {
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/tags/tagContacts`,
      headers: {
        Authorization: key,
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const tagContact = async (instance, item, data) => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/tags/tagsToContact`,
      data: {
        contactId: item?._id,
        tagsIds: data.tags
      },
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
        'Content-Type': 'application/json'
      }
    };

    const res = axios(config);

    return await res;
  } catch (err) {
    console.error(err);
  }
};
