import { useContext, useMemo } from "react";
import { GlobalContext } from "../components/context/GlobalContext";

export const useIsDevsInstance = () => {
  const { instance } = useContext(GlobalContext);

  const isDevsInstance = useMemo(() => {
    // Id de instancia "devsapp"
    return instance?._id === "66b503f8c0fe3aa16d013752";
  }, [instance]);

  return { isDevsInstance };
};
