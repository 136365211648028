import { useContext, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { GlobalContext } from '../../../context/GlobalContext';
import { getAdditionalFields } from '../../../../helpers/fetchData/fetchAdditionalFields';
import LoadingIcon from '../../Loader/LoadingIcon';

export const ContactAdditionalFields = ({ editMode, form }) => {
  const { instance, messageApi, rightDrawerConfig } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);

  const { item } = rightDrawerConfig;

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoading
      );
    }
  }, [instance]);

  useEffect(() => {
    if (item && additionalFields?.length > 0 && editMode) {
      const initialValues = {};

      additionalFields.forEach((field) => {
        if (item.hasOwnProperty(field.name)) {
          initialValues[field.name] = item[field.name];
        }
      });

      form.setFieldsValue(initialValues);
    }
  }, [additionalFields, form, editMode, item]);

  return (
    <Form
      className='form-nodos'
      form={form}
      name='editAdditionalFields'
      layout='vertical'
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Campos adicionales</p>

      {loading ? (
        <span
          style={{
            height: 300,
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <>
          {additionalFields?.length > 0 ? (
            additionalFields.map((field, index) => (
              <AdditionalFieldInput key={index} additionalField={field} />
            ))
          ) : (
            <p>No hay campos adicionales</p>
          )}
        </>
      )}
    </Form>
  );
};

const AdditionalFieldInput = ({ additionalField }) => {
  if (!additionalField) {
    return null;
  }

  return (
    <Form.Item name={additionalField.name} label={additionalField.name}>
      {additionalField.type === 'text' ? (
        <Input.TextArea />
      ) : additionalField.type === 'number' ? (
        <Input type='number' />
      ) : (
        <Input />
      )}
    </Form.Item>
  );
};
