import { ReactFlowProvider } from "reactflow";
import MainLayout from "../ui/Layout/MainLayout";
import { SendTemplates } from "../ui/Layout/campaigns/sendTemplates/SendTemplates";

export const SendTemplateView = ({ edit = false }) => {
  return (
    <MainLayout>
      <ReactFlowProvider>
        <SendTemplates edit={edit} />
      </ReactFlowProvider>
    </MainLayout>
  );
};
