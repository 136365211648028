import { Button, Popover, Spin, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { TbTrash } from 'react-icons/tb';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import {
  deleteAdditionalField,
  getAdditionalFields
} from '../../../../../../../helpers/fetchData/fetchAdditionalFields';
import { LoadingOutlined } from '@ant-design/icons';
import { EditAdditionalField } from '../additionalFields/EditAdditionalField';

export const AdditionalFieldsTable = ({
  additionalFields,
  setAdditionalFields
}) => {
  const [loading, setLoading] = useState(false);

  const { instance, messageApi } = useContext(GlobalContext);

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoading
      );
    }
  }, [instance]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (dataIndex) => {
        switch (dataIndex) {
          case 'string':
            return 'Texto corto';
          case 'number':
            return 'Número';
          case 'text':
            return 'Texto';
          default:
            break;
        }
      }
    },
    {
      title: '...',
      key: '',
      align: 'center',
      width: 80,
      render: (dataIndex, item) => {
        return (
          <AccionesAdditionalFields
            item={item}
            setAdditionalFields={setAdditionalFields}
          />
        );
      }
    }
  ];

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined size={26} spin style={{ marginTop: 50 }} />}
    >
      <Table
        className='tabla-config'
        columns={columns}
        dataSource={additionalFields || []}
        rowKey='_id'
        size='small'
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} campos`
        }}
      />
    </Spin>
  );
};

const AccionesAdditionalFields = ({ item, setAdditionalFields }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const { instance, messageApi } = useContext(GlobalContext);

  function handleDeleteField(id) {
    deleteAdditionalField(
      instance,
      messageApi,
      id,
      setAdditionalFields,
      setLoading
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        <span style={{ height: 18 }}>
          <FiEdit3
            className='icono-acciones'
            onClick={() => setOpenEditDrawer(true)}
          />
        </span>

        <Popover
          open={showPopover}
          content={
            <span className='columna' style={{ maxWidth: 280 }}>
              <p>{`¿Está seguro de eliminar ${item.name}?`}</p>
              <p>
                Tenga en cuenta que esto eliminará definitivamente los datos
                guardados de este campo.
              </p>
              <span className='horizontal'>
                <Button
                  type='primary'
                  className='btn-borrar'
                  onClick={() => handleDeleteField(item._id)}
                  //loading={loading}
                  icon={
                    loading ? (
                      <LoadingIcon size='small' color='#FFFFFF' />
                    ) : null
                  }
                  style={{ opacity: loading ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  type='secondary'
                  className='btn-cancelar'
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </span>
            </span>
          }
        >
          <span style={{ height: 18 }}>
            <TbTrash
              className='icono-acciones'
              onClick={() => setShowPopover(true)}
            />
          </span>
        </Popover>
      </div>

      <EditAdditionalField
        open={openEditDrawer}
        setOpen={setOpenEditDrawer}
        setAdditionalFields={setAdditionalFields}
        item={item}
      />
    </>
  );
};
