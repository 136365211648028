import { Button, Form, Input, Radio, Upload } from "antd";
import { useContext, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  FILE_TYPES,
  newMediaResource,
} from "../../../../helpers/fetchData/fetchMedia";
import { normFile } from "./utils/cargaMedia";
import LoadingIcon from "../../Loader/LoadingIcon";

export const CargaMedia = ({
  modal,
  setShowCargaMedia,
  defaultMediaType = "image",
  setReload,
  ambito,
}) => {
  const { messageApi, setSideDrawerConfig, instance } =
    useContext(GlobalContext);
  const [form] = Form.useForm();
  const [mediaType, setMediaType] = useState(defaultMediaType);

  const [loading, setLoading] = useState(false);

  const { Dragger } = Upload;

  const customRequest = (v) => {
    if (FILE_TYPES[mediaType].accept.includes(v.file.type)) {
      if (v.file.size < 1048576 * FILE_TYPES[mediaType].maxSize) {
        v.onSuccess();
      } else {
        messageApi.error(
          `El tamaño excede el limite ${FILE_TYPES[mediaType].maxSize}MB`
        );
        v.onError(
          `El tamaño excede el limite ${FILE_TYPES[mediaType].maxSize}MB`
        );
      }
    } else {
      messageApi.error("El formato no es válido");
      v.onError("El formato no es válido");
    }
  };

  const onFinish = async (values) => {
    if (!values.name) {
      return messageApi.error(
        "Es necesario que ingrese un nombre para el archivo"
      );
    }

    if (!values.file) {
      return messageApi.error("Es necesario que seleccione un archivo");
    }

    let formData = new FormData();

    formData.append(
      "file",
      values.file[0]?.originFileObj,
      values.file[0]?.name
    );

    formData.append("name", values.name);
    formData.append("mediaType", mediaType);
    formData.append("caption", values.caption || "");

    setLoading(true);

    newMediaResource(instance?.userData?.apiKey, formData)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Archivo guardado correctamente");

          const {
            publicURL,
            mediaType,
            generatedName,
            name,
            caption,
            originalName,
            mimetype,
            createdAt,
            _id,
          } = res?.data;

          if (modal) {
            setShowCargaMedia(false);
            if (setReload) {
              setReload(true);
            }

            return;
          }

          if (ambito === "plusContent") {
            setSideDrawerConfig({ visible: false, content: "" });
          }
        } else {
          messageApi.error("No se pudo guardar el archivo");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("No se pudo guardar el archivo");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="cargarMedia"
      style={{ padding: "24px" }}
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <Form.Item>
        <span className="form-title">Cargar archivos de media</span>
      </Form.Item>
      <Form.Item>
        <Radio.Group
          onChange={(v) => {
            setMediaType(v.target.value);
          }}
          className="radio-media"
          style={{
            width: "100%",
          }}
          buttonStyle="solid"
          value={mediaType}
        >
          <Radio.Button value="image">Imagen</Radio.Button>
          <Radio.Button value="video">Video</Radio.Button>
          <Radio.Button value="document">Documento</Radio.Button>
          <Radio.Button value="audio">Audio</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="file"
        rules={[{ required: true, message: "Seleccione un archivo" }]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Dragger
          name="file"
          customRequest={customRequest}
          maxCount={1}
          accept={FILE_TYPES[mediaType].accept}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click o arrastre un archivo aquí
          </p>
          <p className="ant-upload-hint">{`Soporta archivos en formato ${FILE_TYPES[mediaType].formatos}.`}</p>
          <p className="ant-upload-hint">{`Con un máximo de ${FILE_TYPES[mediaType].maxSize}MB.`}</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        name="name"
        label="Nombre del archivo"
        rules={[{ required: true, message: "Ingrese un nombre" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="caption" label="Descripción">
        <Input.TextArea />
      </Form.Item>
      <Form.Item style={{ marginBottom: "0px" }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              if (modal) {
                setShowCargaMedia(false);
              } else {
                setSideDrawerConfig({
                  visible: false,
                  content: "",
                });
              }
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
