import { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { GlobalContext } from '../../../../context/GlobalContext';
import { Tag } from '../../../tag/Tag';
import { getLaboral } from '../utils/getLaboral';
import { getDireccion } from '../utils/getDireccion';
import { getProvincia } from '../utils/getProvincia';
import { getTipoDireccion } from '../utils/getTipoDireccion';
import { getCumpleanios } from '../utils/getCumpleanios';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';
import { getAdditionalFields } from '../../../../../helpers/fetchData/fetchAdditionalFields';
import { DEFAULT_CONTACT_KEYS } from '../../../utils/defaultContactKeys';

export const ShowContact = () => {
  const { rightDrawerConfig, setRightDrawerConfig, instance, messageApi } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [datosContacto, setDatosContacto] = useState({});
  const [additionalFields, setAdditionalFields] = useState([]);

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoading
      );
    }
  }, [instance]);

  const { item } = rightDrawerConfig;

  const { addresses, birthday, emails, name, org, phones, tags } = item;

  const contactAdditionalFields = Object.keys(item).filter(
    (key) => !DEFAULT_CONTACT_KEYS.includes(key)
  );

  return (
    <div className='ShowContact'>
      {/* <p style={{ marginBottom: 16, fontWeight: 600 }}>Datos del Contacto</p> */}

      <div className='show-contact-container'>
        <span className='show-contact-name'>{`${name?.first_name || ''} ${
          name?.middle_name || ''
        } ${name?.last_name || ''}`}</span>

        <span className='show-contact-job'>{getLaboral(org)}</span>

        {tags?.length > 0 && <Etiquetas data={tags} />}

        <div className='show-contact-info-container'>
          {birthday && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>
                Fecha de nacimiento
              </span>
              <span className='show-contact-info'>
                {getCumpleanios(birthday)}
              </span>
            </div>
          )}

          <div className='show-contact-info-subcontainer'>
            <span className='show-contact-info-label'>Teléfono</span>
            <span className='show-contact-info'>
              {formatPhoneNumber(phones?.[0]?.phone)}
            </span>
          </div>

          {emails?.[0].email && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>
                Correo electrónico
              </span>
              <span className='show-contact-info'>{emails[0].email}</span>
            </div>
          )}

          {(addresses?.[0]?.street ||
            addresses?.[0]?.city ||
            addresses?.[0]?.zip) && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>
                Dirección del {getTipoDireccion(addresses?.[0]?.type)}
              </span>
              <span className='show-contact-info'>
                {getDireccion(addresses[0])}
              </span>
              <span className='show-contact-info'>
                {getProvincia(addresses[0])}
              </span>
            </div>
          )}

          {additionalFields?.length > 0 &&
            contactAdditionalFields?.length > 0 && (
              <div className='show-contact-info-subcontainer'>
                {contactAdditionalFields.map((field, index) => {
                  if (item[field]) {
                    return (
                      <>
                        <span className='show-contact-info-label'>{field}</span>
                        <span className='show-contact-info'>{item[field]}</span>
                        {index < contactAdditionalFields?.length - 1 &&
                          item[field] && <div className='divider' />}
                      </>
                    );
                  }
                  return null;
                })}
              </div>
            )}
        </div>
      </div>

      <div className='show-contact-close-btn'>
        <Button
          style={{
            flex: '1',
            marginBottom: 16
          }}
          type='primary'
          size='large'
          onClick={() =>
            setRightDrawerConfig({ visible: false, content: '', item: null })
          }
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
};

const Etiquetas = ({ data }) => {
  const { etiquetas } = useContext(GlobalContext);

  function getEtiquetas(tag) {
    return etiquetas.find((element) => element._id === tag);
  }

  return (
    <div className='celda-tags-contactos'>
      {data?.map((tag, index) => {
        const obj = getEtiquetas(tag);

        return (
          <Tag hex={obj?.color} nombre={obj?.name} key={obj?._id || index} />
        );
      })}
    </div>
  );
};
