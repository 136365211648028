import { TbUserOff } from "react-icons/tb";
import { getContactName } from "../campaigns/sendTemplates/utils/getContactName";
import { Empty, Spin, Tag } from "antd";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { findUser } from "./utils/chatFunctions";
import { renderAgent } from "./utils/renderAgent";
import { LoadingOutlined } from "@ant-design/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import LoadingIcon from '../../Loader/LoadingIcon';


export const TeamsTab = ({
  conversations,
  selectedConversation,
  setSelectedConversation,
  teams,
  busqueda,
  loadingChats,
}) => {
  const { instance, contactos, usuarios } = useContext(GlobalContext);

  const [openedKeys, setOpenedKeys] = useState([]);

  const formattedData = useMemo(() => {
    if (!instance?.userData?.userId || !teams || !conversations) {
      return [];
    }

    let equipos = [];
    conversations?.forEach((element) => {
      let contactName =
        contactos?.find(
          (contacto) => contacto.phones[0]?.phone === element.phoneNumber
        )?.name || {};

      if (element.teamId) {
        if (!equipos?.some((equipo) => equipo.teamId === element.teamId)) {
          equipos.push({
            teamId: element.teamId,
            chats: [{ ...element, name: contactName }],
          });
        } else {
          let index = equipos.findIndex(
            (equipo) => equipo.teamId === element.teamId
          );

          if (index >= 0) {
            equipos[index] = {
              ...equipos[index],
              chats: [
                ...equipos[index]?.chats,
                { ...element, name: contactName },
              ],
            };
          }
        }
      }
    });

    let array = [];

    teams.forEach((element) => {
      if (element.users?.includes(instance.userData.userId)) {
        let chats =
          equipos.find((equipo) => equipo.teamId === element._id)?.chats || [];
        array.push({
          ...element,
          chats: chats.map((chat) => {
            chat = {
              ...chat,
              name: getContactName(chat.name),
              type: "team",
            };

            return chat;
          }),
        });
      }
    });

    return array;
  }, [teams, instance, conversations]);

  const filteredData = useMemo(() => {
    let array = formattedData;

    if (busqueda) {
      let array = [];

      formattedData.forEach((team) => {
        if (team.name?.toLowerCase()?.includes(busqueda.toLowerCase())) {
          array.push(team);
        } else {
          if (
            team.chats?.some((chat) =>
              chat.name?.toLowerCase()?.includes(busqueda.toLowerCase())
            )
          ) {
            let obj = {
              ...team,
              chats: team.chats.filter((chat) =>
                chat.name?.toLowerCase()?.includes(busqueda.toLowerCase())
              ),
            };

            array.push(obj);
          }
        }
      });

      return array;
    }

    return array;
  }, [formattedData, busqueda]);

  const usuario = (contacto) => {
    if (contacto?.userId) {
      return renderAgent(usuarios, contacto.userId);
    }

    return null;
  };

  if (loadingChats)
    return (
      <span className="tabs-content-spin-wrapper">
        <LoadingIcon size="default" />
      </span>
    );

  return (
    <div className="chat-list-wrapper">
      {filteredData?.length >= 1 ? (
        filteredData.map((team, index) => {
          if (!openedKeys?.includes(team._id)) {
            return (
              <span
                className="columna"
                style={{ gap: "6px", flex: 1 }}
                key={team._id}
              >
                <span
                  className="horizontal pointer"
                  onClick={() => setOpenedKeys([team._id])}
                  style={{ width: "fit-content" }}
                >
                  <Tag
                    color="geekblue"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    {team?.name}
                  </Tag>
                  <span
                    style={{
                      height: 12,
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <FaChevronDown size={12} className="fade-in" />
                  </span>
                </span>
                {team.chats?.map((element, index) => {
                  return (
                    <span
                      className={
                        element.conversationStatus !== "blocked"
                          ? "chat-contact-wrapper fade-in"
                          : "chat-contact-wrapper blocked-conversation"
                      }
                      onClick={() => setSelectedConversation(element)}
                      key={index}
                      style={
                        selectedConversation?.phoneNumber ===
                        element.phoneNumber
                          ? {
                              border: "1px solid var(--primary-color)",
                            }
                          : {}
                      }
                    >
                      <span className="columna" style={{ gap: "6px" }}>
                        <span className="horizontal">
                          <p className="chat-contact-name">{element.name}</p>
                          <span style={{ marginLeft: "auto" }}>
                            {element.conversationStatus === "blocked" && (
                              <TbUserOff color="#FF6962" size={16} />
                            )}
                          </span>
                        </span>
                        <p className="chat-contact-display-message">
                          {
                            element.conversation?.[
                              element.conversation.length - 1
                            ]?.text?.body
                          }
                        </p>
                        <span className="horizontal gap8">
                          {usuario(element)}
                        </span>
                        {/* <span className="horizontal" style={{ fontSize: "14px" }}> */}

                        {/* 
                            {renderTimeToExpired(element.time)}
                            {renderLastTime(element.lastSeen)} */}
                        {/* </span> */}
                      </span>
                    </span>
                  );
                })}
              </span>
            );
          } else {
            return (
              <span className="columna" key={team._id}>
                <span
                  className="horizontal pointer"
                  onClick={() => setOpenedKeys([])}
                  style={{ width: "fit-content" }}
                >
                  <Tag color="geekblue" style={{ width: "fit-content" }}>
                    {team?.name}
                  </Tag>
                  <span
                    style={{
                      height: 12,
                      display: "grid",
                      placeItems: "center",
                    }}
                    className="fade-in"
                  >
                    <FaChevronUp size={12} />
                  </span>
                </span>
              </span>
            );
          }
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
