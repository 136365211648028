import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { ImWhatsapp } from "react-icons/im";
import { BiGitBranch, BiSupport } from "react-icons/bi";
import { FiArchive, FiClock, FiTag, FiXCircle } from "react-icons/fi";
import { BsSoundwave } from "react-icons/bs";
import {
  TbAuth2Fa,
  TbClockPause,
  TbExternalLink,
  TbMessage,
} from "react-icons/tb";
import {
  MdOutlineHttp,
  MdLockOutline,
  MdLockOpen,
  MdOutlineStickyNote2,
} from "react-icons/md";
import { GrCycle } from "react-icons/gr";
import { HiVariable } from "react-icons/hi";
import { manageWorkingNode } from "../utils/manageWorkingNode";
import { AiOutlineMail, AiOutlineShoppingCart } from "react-icons/ai";
import { SiOpenai } from "react-icons/si";
import { PiAddressBookBold, PiMathOperations } from "react-icons/pi";
import { FaLink } from "react-icons/fa6";
import { LiaRobotSolid } from "react-icons/lia";
import { NODE_TYPES } from "../nodeTypes";
import { useReactFlow } from "reactflow";

const SIZE = 32;

export const InitialForm = () => {
  const {
    nodoSeleccionado,
    setNodes,
    setNodoSeleccionado,
    setMostrarDrawer,
    nodes,
    edges,
    setEdges,
    nextNodeId,
    reactFlowWrapper,
  } = useContext(FlowContext);

  const { screenToFlowPosition } = useReactFlow();

  const handleSetType = (type) => {
    try {
      if (nodoSeleccionado?.id) {
        // Es un nodo ya creado por arrastre, hay que darle un type
        setNodes((prevState) => {
          let updatedNodes = [...prevState];
          let index = updatedNodes.findIndex(
            (node) => node.id === nodoSeleccionado.id
          );
          updatedNodes[index].type = type.id;
          updatedNodes[index].data.label = type.name;

          setNodoSeleccionado(updatedNodes[index]);
          return updatedNodes;
        });

        if (["note"].includes(type.id)) {
          //Borro el edge de la nota
          setEdges((prevState) => {
            const nodeId = nodoSeleccionado?.id;
            let array = prevState.filter((edge) => {
              if (
                edge.source === nodeId ||
                edge.target === nodeId ||
                edge.sourceHandle === nodeId
              ) {
                return false;
              }

              return true;
            });

            return array;
          });
        }
      } else {
        // Se va a crear un nodo desde el boton de agregar nodo.
        const id = nextNodeId.current.toString();

        let positionX =
          reactFlowWrapper.current.getBoundingClientRect().width / 2 || 0;

        let positionY =
          reactFlowWrapper.current.getBoundingClientRect().height / 10 || 0;

        const newNode = {
          id,
          position: screenToFlowPosition({
            x: positionX || 0,
            y: positionY || 0,
          }),
          zIndex: 99999,
          type: type.id,
          data: { label: type.name },
        };

        setNodoSeleccionado(newNode);
        setNodes((nds) => nds.concat(newNode));
      }

      if (["textToSpeech", "transcribeAudio"].includes(type.id)) {
        manageWorkingNode(
          nodes,
          setNodes,
          edges,
          setEdges,
          nodoSeleccionado,
          false
        );
      }

      if (["end", "isContact"].includes(type.id)) {
        setMostrarDrawer(false);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleIcon = (type) => {
    switch (type.id) {
      case "message":
        return (
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={SIZE} />
        );
      case "buttons":
        return (
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={SIZE} />
        );
      case "list":
        return (
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={SIZE} />
        );
      case "branch":
        return <BiGitBranch size={SIZE} />;
      case "budget":
        return (
          <AiOutlineShoppingCart
            style={{ color: "var(--dark-color)" }}
            size={SIZE}
          />
        );
      case "dateTime":
        return <FiClock style={{ color: "var(--dark-color)" }} size={SIZE} />;
      // case "textToSpeech":
      //   return (
      //     <RxSpeakerLoud style={{ color: "var(--dark-color)" }} size={SIZE} />
      //   );
      case "transcribeAudio":
        return <BsSoundwave size={SIZE} />;
      case "httpRequest":
        return (
          <MdOutlineHttp style={{ color: "var(--dark-color)" }} size={SIZE} />
        );
      case "iterableList":
        return <GrCycle style={{ color: "var(--dark-color)" }} size={SIZE} />;
      case "oneTimePassword":
        return <TbAuth2Fa style={{ color: "var(--dark-color)" }} size={SIZE} />;
      case "setDelay":
        return (
          <TbClockPause style={{ color: "var(--dark-color)" }} size={SIZE} />
        );
      case "sendTemplate":
        return <FiArchive style={{ color: "var(--dark-color)" }} size={SIZE} />;
      case "setVariables":
        return (
          <HiVariable style={{ color: "var(--dark-color)" }} size={SIZE} />
        );
      case "sendMultiProduct":
        return (
          <AiOutlineShoppingCart
            style={{ color: "var(--dark-color)" }}
            size={SIZE}
          />
        );
      case "sendSingleProduct":
        return (
          <AiOutlineShoppingCart
            style={{ color: "var(--dark-color)" }}
            size={SIZE}
          />
        );
      case "end":
        return <FiXCircle size={SIZE} style={{ color: "var(--dark-color)" }} />;
      case "encode":
        return (
          <MdLockOutline
            size={SIZE}
            style={{ color: "var(--dark-color)" }}
          />
        );
      case "decode":
        return (
          <MdLockOpen
            size={SIZE}
            style={{ color: "var(--dark-color)" }}
          />
        );
      case "cta_button":
        return (
          <TbExternalLink
            size={SIZE}
            style={{ color: "var(--whatsapp-color)" }}
          />
        );
      case "callFunction":
        return <SiOpenai size={SIZE} style={{ color: "var(--dark-color)" }} />;
      case "contactAgent":
        return <BiSupport size={SIZE} style={{ color: "var(--dark-color)" }} />;
      case "isContact":
        return (
          <PiAddressBookBold
            size={SIZE}
            style={{ color: "var(--dark-color)" }}
          />
        );
      case "formula":
        return (
          <PiMathOperations
            size={SIZE}
            style={{ color: "var(--dark-color)" }}
          />
        );
      case "sendMessageToChat":
        return <TbMessage size={SIZE} style={{ color: "var(--dark-color)" }} />;
      case "tagContact":
        return <FiTag size={SIZE} style={{ color: "var(--dark-color)" }} />;
      case "subscription":
        return <FaLink size={SIZE} style={{ color: "var(--dark-color)" }} />;
      case "goToFlow":
        return (
          <LiaRobotSolid size={SIZE} style={{ color: "var(--dark-color)" }} />
        );
      case "sendEmail":
        return (
          <AiOutlineMail size={SIZE} style={{ color: "var(--dark-color)" }} />
        );
      case "note":
        return (
          <MdOutlineStickyNote2
            size={SIZE}
            style={{ color: "var(--dark-color)" }}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <b style={{ fontSize: "18px" }}>Seleccione un tipo de nodo</b>
      <div className="types-container">
        {NODE_TYPES.map((type) => {
          return (
            <div
              key={type.id}
              className="type-item"
              onClick={() => handleSetType(type)}
            >
              {handleIcon(type)}
              <span style={{ marginTop: "8px", textAlign: "center" }}>
                {type.name}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};
