import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { COLORES_PIE } from '../columns';

export const ButtonsGraph = ({ data }) => {
  const formatter = (value, name) => {
    let color = 0;

    if (name !== 'Si') {
      color = 1;
    }
    return [
      <span style={{ color: COLORES_PIE[color] }}>{`${name}: ${value}`}</span>
    ];
  };

  return (
    <ResponsiveContainer width='99%' height='100%'>
      <PieChart
        margin={{
          top: 100,
          right: 100,
          bottom: 100,
          left: 100
        }}
      >
        <Pie
          data={
            data?.map((element) => {
              return {
                value: element.interactions?.length || 0,
                name: element.button
              };
            }) || []
          }
          dataKey='value'
          innerRadius={35}
          outerRadius={60}
          cursor='pointer'
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORES_PIE[index % COLORES_PIE.length]}
            />
          ))}
        </Pie>
        <Legend
          className='legendAnillo'
          iconType='circle'
          layout='horizontal'
          verticalAlign='bottom'
          wrapperStyle={{
            position: 'absolute',
            bottom: 12,
            left: 0,
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'center'
          }}
        />
        <Tooltip
          labelClassName='custom-tooltip-label'
          wrapperClassName='custom-tooltip-wrapper'
          formatter={formatter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
