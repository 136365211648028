import { useContext, useState } from "react";
import { Button, Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { FILE_TYPES } from "../../../../../../helpers/fetchData/fetchMedia";
import { normFile } from "../../../flows/utils/cargaMedia";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const UploadInstanceImage = ({ setShowModal }) => {
  const { messageApi, instance, setInstance } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { Dragger } = Upload;

  const customRequest = (v) => {
    if (FILE_TYPES.image.accept.includes(v.file.type)) {
      if (v.file.size < 1048576 * FILE_TYPES.image.maxSize) {
        v.onSuccess();
      } else {
        messageApi.error(
          `El tamaño excede el limite ${FILE_TYPES.image.maxSize}MB`
        );
        v.onError(`El tamaño excede el limite ${FILE_TYPES.image.maxSize}MB`);
      }
    } else {
      messageApi.error("El formato no es válido");
      v.onError("El formato no es válido");
    }
  };

  const handleCancelar = () => {
    setShowModal(false);
  };

  const onFinish = async (values) => {
    if (!values.file) {
      return messageApi.error("Es necesario que seleccione un archivo");
    }

    let myHeaders = new Headers();

    let token = instance?.userData?.apiKey;

    myHeaders.append("Authorization", token);

    let blob = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () =>
        resolve(new Blob([reader.result], { type: values.file[0]?.type }));
      reader.readAsArrayBuffer(values.file[0]?.originFileObj);
    });

    let formData = new FormData();
    formData.append("file", blob, values.file[0]?.name);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    setLoading(true);

    fetch(
      `${process.env.REACT_APP_API_PRODUCCION}/uploadProfilePicture`,
      requestOptions
    )
      .then((res) => res.text())
      .then((result) => {
        let object = JSON.parse(result);

        if (object.status === 200 && object.url) {
          messageApi.success("Imagen subida correctamente");
          setInstance((prevState) => {
            return { ...prevState, profilePicture_url: object.url };
          });
          setShowModal(false);
        } else {
          messageApi.error("No se pudo actualizar la imagen de perfil");
        }
      })
      .catch((error) => {
        messageApi.error("No se pudo actualizar la imagen de perfil");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="newImagen"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <Form.Item
        label={<b>Actualizar imagen del workspace</b>}
        name="file"
        rules={[{ required: true, message: "Seleccione un archivo" }]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={{ marginBottom: 75 }}
      >
        <Dragger
          name="file"
          customRequest={customRequest}
          maxCount={1}
          accept={FILE_TYPES.image.accept}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click o arrastre un archivo aquí
          </p>
          <p className="ant-upload-hint">{`Soporta archivos en formato ${FILE_TYPES.image.formatos}.`}</p>
          <p className="ant-upload-hint">{`Con un máximo de ${FILE_TYPES.image.maxSize}MB.`}</p>
        </Dragger>
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              handleCancelar();
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
