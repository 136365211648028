import MainLayout from "../ui/Layout/MainLayout"
import { CampaignData } from "../ui/Layout/campaigns/CampaignData"

export const CampaignsDataView = () => {
  return (
    <MainLayout>
      <CampaignData />
    </MainLayout>
  )
}
