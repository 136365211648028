import { Button, Form, Input, Select } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { SettingsContext } from "../../../../../../context/SettingsContext";
import { DIVISAS } from "../../catalog/utils/divisas";
import { newList } from "../../../../../../../helpers/fetchData/fetchProductsLists";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const NewList = () => {
  const { setConfigDrawerContent, configDrawerContent, messageApi, instance } =
    useContext(GlobalContext);

  const { catalogDetail, setCatalogDetail } = useContext(SettingsContext);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (v) => {
    let data = {
      name: v.name,
      id: v.id,
      currency: v.currency,
    };

    setLoading(true);
    newList(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Lista creada correctamente");

          let { _id } = res.data;

          if (_id) {
            setCatalogDetail((prevState) => {
              let object = { ...prevState };

              const list = {
                _id: _id,
                name: data.name,
                id: data.id,
                currency: data.currency,
              };

              let listas = [...object?.lists, list];

              object.lists = listas;

              return object;
            });
          }

          setConfigDrawerContent({ visible: false, content: "", item: null });
        } else {
          messageApi.error("Ocurrió un error al configurar una lista");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al configurar una lista");
      })
      .finally(() => setLoading(false));
  };

  //! DIVISA TOMAR LA QUE VIENE DE META.

  return (
    <Form
      form={form}
      layout="vertical"
      name="newList"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Nueva lista</p>
      <Form.Item
        label="Nombre"
        name="name"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Identificador"
        name="id"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Divisa"
        style={{ width: "50%" }}
        name="currency"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select options={DIVISAS} />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setConfigDrawerContent({
                visible: false,
                content: "",
                item: null,
              });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
