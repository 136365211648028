import axios from "axios";

export const validateInstanceName = async (nombre) => {
  try {
    let data = JSON.stringify({
      name: nombre,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/validateName`,
      headers: {
        Authorization: "asdasdas",
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
