import { Button, Checkbox, Form, Input, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { SendTemplateContext } from "../../../../../context/SendTemplateContext";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { updateNode } from "../../../flows/utils/updateNode";
import { getContactName } from "../utils/getContactName";
import { handleSchedules } from "../utils/handleSchedules";
import { Tag } from "../../../../tag/Tag";
import { ContactoCheckbox } from "./ContactoCheckbox";
import { ContactFilters } from "../../../../contactFilters/ContactFilters";

// const filteredContacts = (
//   contactos,
//   busqueda,
//   selectedTag,
// ) => {
//   if (contactos?.length < 1) return [];

//   let array = contactos.filter((contacto) => contacto.phones?.[0]?.phone);

//   if (busqueda) {
//     array = array.filter((contact) =>
//       getContactName(contact.name)
//         ?.toLowerCase()
//         ?.includes(busqueda.toLowerCase())
//     );
//   }

//   if (selectedTag) {
//     array = array.filter((contacto) => {
//       if (contacto?.tags && contacto.tags?.includes(selectedTag)) {
//         return true;
//       } else {
//         return false;
//       }
//     });
//   }

//   return array;
// };

export const ContactsForm = ({ edit }) => {
  const {
    setMostrarDrawer,
    nodoSeleccionado,
    // contactosSeleccionados,
    // setContactosSeleccionados,
    nodes,
    setNodes,
  } = useContext(SendTemplateContext);

  // const { contactos, etiquetas } = useContext(GlobalContext);

  // const [busqueda, setBusqueda] = useState("");
  // const [selectedTag, setSelectedTag] = useState(null);

  const [filters, setFilters] = useState({
    clients: [],
    contacts: [],
    tags: [],
    excludedClients: [],
    excludedContacts: [],
    excludedTags: [],
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // handleSchedules(contactosSeleccionados, setNodes, nodes, 500);

    values.filters = filters;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));

    setMostrarDrawer(false);
  };

  // const selectAll = (v) => {
  //   let ids = contactosData?.map((contacto) => contacto._id);

  //   if (v.target.checked) {
  //     setContactosSeleccionados(ids);
  //   } else {
  //     setContactosSeleccionados((prevState) => {
  //       let array = [...prevState];

  //       array = array?.filter((contacto) => !ids.includes(contacto));

  //       return array;
  //     });
  //   }
  // };

  // const tagsOptions = useMemo(() => {
  //   if (etiquetas?.length < 1) return [];

  //   return etiquetas.map((element) => {
  //     return {
  //       value: element._id,
  //       label: (
  //         <span
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             height: "100%",
  //             position: "relative",
  //           }}
  //         >
  //           <Tag hex={element.color} nombre={element.name} />
  //         </span>
  //       ),
  //     };
  //   });
  // }, [etiquetas]);

  useEffect(() => {
    if (nodoSeleccionado.data) {
      const { filters } = nodoSeleccionado.data;

      setFilters(filters);
    }
  }, [nodoSeleccionado]);

  // const contactosData = useMemo(() => {
  //   return filteredContacts(contactos, busqueda, selectedTag);
  // }, [contactos, busqueda, selectedTag]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <ContactFilters filters={filters} setFilters={setFilters} />
      {/* <span className="horizontal" style={{ marginBottom: 8 }}>
        <Input.Search
          placeholder="Buscar contactos..."
          value={busqueda}
          onChange={(v) => setBusqueda(v.target.value)}
        />
      </span>
      <Select
        options={tagsOptions || []}
        value={selectedTag}
        onChange={(v) => setSelectedTag(v)}
        placeholder="Filtre contactos por sus etiquetas"
        allowClear
        style={{ marginBottom: 16, marginTop: 8 }}
      />
      <span className="horizontal" style={{ marginBottom: 8 }}>
        <Checkbox
          onChange={(v) => selectAll(v)}
          checked={contactosData?.length === contactosSeleccionados?.length}
          style={{ fontWeight: 600 }}
        >
          Seleccione contactos
        </Checkbox>
      </span>
      {contactosData?.map((contacto, index) => {
        return (
          <ContactoCheckbox
            key={contacto?._id || index}
            contacto={contacto}
            contactosSeleccionados={contactosSeleccionados}
            setContactosSeleccionados={setContactosSeleccionados}
          />
        );
      })} */}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          {!edit && (
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
            >
              Guardar
            </Button>
          )}
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            block={edit}
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            {edit ? "Cerrar" : "Cancelar"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
