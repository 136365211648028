import { FaRegFilePowerpoint } from "react-icons/fa6";
import { FiShoppingCart, FiSliders, FiTag, FiUsers } from "react-icons/fi";
import { MdOutlineWorkspaces } from "react-icons/md";
import { AiOutlineApi, AiOutlinePlusSquare } from "react-icons/ai";
import { LuSquareSlash } from "react-icons/lu";
import { BiMessageError } from "react-icons/bi";
import { TbMessage } from "react-icons/tb";
import { accessDataFormat } from "../../../../../helpers/access";
import { LiaRobotSolid } from "react-icons/lia";
import { PiAddressBookBold } from "react-icons/pi";

const getItem = (label, key, icon, children, type) => {
  return {
    label,
    key,
    icon,
    children,
    type,
  };
};

export const settingsMenuItems = (isAllowed) => {
  return [
    getItem("Workspace", "sub1", null, [
      isAllowed(accessDataFormat("workspaces", "show")) &&
        getItem("Workspace", "workspace", <AiOutlineApi />),
      isAllowed(accessDataFormat("channels", "show")) &&
        getItem("Canales", "channels", <FiSliders />),
      isAllowed(accessDataFormat("users", "show")) &&
        getItem("Usuarios y roles", "users", <FiUsers />),
      isAllowed(accessDataFormat("teams", "show")) &&
        getItem("Equipos", "teams", <MdOutlineWorkspaces />),
      isAllowed(accessDataFormat("contacts", "show")) &&
        getItem("Contactos", "contacts", <PiAddressBookBold />),
      isAllowed(accessDataFormat("bots", "show")) &&
        getItem("Bots", "bots", <LiaRobotSolid />),
      isAllowed(accessDataFormat("chatSettings", "show")) &&
        getItem("Chat", "chat", <TbMessage />),
      isAllowed(accessDataFormat("tags", "show")) &&
        getItem("Etiquetas", "tags", <FiTag />),
      isAllowed(accessDataFormat("templates", "show")) &&
        getItem("Plantillas", "templates", <FaRegFilePowerpoint />),
      isAllowed(accessDataFormat("catalog", "show")) &&
        getItem("Productos y servicios", "catalog", <FiShoppingCart />),
      isAllowed(accessDataFormat("savedMessages", "show")) &&
        getItem(
          "Mensajes predeterminados",
          "saved-messages",
          <LuSquareSlash />
        ),
      isAllowed(accessDataFormat("errorMessages", "show")) &&
        getItem("Mensajes de error", "error-messages", <BiMessageError />),
      isAllowed(accessDataFormat("integrations", "show")) &&
        getItem("Integraciones", "integraciones", <AiOutlinePlusSquare />),
    ]),
    getItem("Brocoly", "sub2", null, [
      isAllowed(accessDataFormat("clients", "show")) &&
        getItem("Clientes", "clients", <FiUsers />),
    ]),
  ];
};

export const renderScope = (scope) => {
  let string = "";
  scope.forEach((scope) => {
    return (string += " " + scope);
  });

  string = string.trim();

  return string.replace(" ", " | ");
};

export const BUTTON_OPTIONS = [
  {
    title: "Botones de respuesta rápida",
    children: [
      {
        type: "MARKETING",
        title: "Desactivar marketing",
        subtitle: "Recomendado",
      },
      { type: "QUICK_REPLY", title: "Personalizado" },
    ],
  },
  {
    title: "Botones de llamada a la acción",
    children: [
      {
        type: "URL",
        title: "Ir al sitio web",
        subtitle: "2 botones como máximo",
      },
      {
        type: "PHONE_NUMBER",
        title: "Llamar al número de teléfono",
        subtitle: "1 botón como máximo",
      },
      {
        type: "COPY_CODE",
        title: "Copiar código de oferta",
        subtitle: "1 botón como máximo",
      },
    ],
  },
];
