import { Drawer, Spin } from "antd";
import { formatoTextHtml } from "../../../../../../helpers/utilidadesTemplates";
import { PreviewTemplate } from "../templates/PreviewTemplate";
import { LoadingOutlined } from "@ant-design/icons";
import { ChatTemplateForm } from "./ChatTemplateForm";
import { useContext, useEffect, useState } from "react";
import { getConfig } from "../../../../../../helpers/fetchData/fetchConfig";
import { GlobalContext } from "../../../../../context/GlobalContext";
import LoadingIcon from "../../../../Loader/LoadingIcon";

export const DefaultTemplate = ({ showForm, setShowForm }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [loadingConfig, setLoadingConfig] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingConfig(true);
      getConfig(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data?.config?.template) {
              setDefaultTemplate(res.data.config.template);
            }
          }
        })
        .catch(() =>
          messageApi.error("No se pudo obtener plantilla por defecto")
        )
        .finally(() => setLoadingConfig(false));
    }
  }, [instance]);

  return (
    <div>
      {loadingConfig ? (
        <span
          className="horizontal"
          style={{ justifyContent: "center", marginTop: 100 }}
        >
          <LoadingIcon size="large" />
        </span>
      ) : defaultTemplate?.templateVariables ? (
        <div className="columna gap16">
          <b>Plantilla predefinida</b>
          <PreviewTemplate
            ambito={`chat-config`}
            // inputText={inputText}
            bodyContent={formatoTextHtml(
              defaultTemplate.templateVariables.body?.value
            )}
            buttonsContent={defaultTemplate.templateVariables?.buttons || []}
            footerContent={defaultTemplate.templateVariables.footer?.value}
            headerContent={defaultTemplate.templateVariables.header?.value}
            headerMediaType={defaultTemplate.templateVariables.header?.type}
            headerType={
              defaultTemplate.templateVariables.header?.type?.toUpperCase() ===
              "TEXT"
                ? "text"
                : "media"
            }
            renderTemplateButtons={false}
            interactions={null}
            edges={[]}
            node={{}}
          />
          {/* {showAll && (
            <AllButtons buttonsContent={buttonsContent} setShowAll={setShowAll} />
          )} */}
        </div>
      ) : (
        <b>No hay plantilla predefinida</b>
      )}
      <Drawer open={showForm} placement="right" destroyOnClose width={450}>
        <ChatTemplateForm
          setShowForm={setShowForm}
          setDefaultTemplate={setDefaultTemplate}
        />
      </Drawer>
    </div>
  );
};
