import { Tag } from 'antd';
import { ImWhatsapp } from 'react-icons/im';
import { FlowsAcciones } from '../FlowsAcciones';
import { getCompletedPercentage } from './getCompletedPercentage';

export const columnsFlows = (navigate, setFlows, setLoading) => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (data, item) => (
        <span
          className='horizontal gap8'
          onClick={() => navigate(`/flows/edit?id=${item?._id}`)}
          style={{ cursor: 'pointer' }}
        >
          <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={20} />
          <b>{data}</b>
        </span>
      )
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (data) => (
        <span className='fila-triggers'>
          <Tag color={statusColor(data)}>
            {data === 'publish' ? 'Activo' : 'Borrador'}
          </Tag>
        </span>
      )
    },
    {
      title: 'Triggers',
      dataIndex: 'trigger',
      key: 'trigger',
      render: (data) => {
        return (
          <span className='fila-triggers'>
            {data.map((trigger, index) => {
              return (
                <Tag color='gold' key={index}>
                  {trigger}
                </Tag>
              );
            })}
          </span>
        );
      }
    },
    {
      title: 'Ejecuciones',
      dataIndex: 'executionCount',
      key: 'executionCount'
    },
    {
      title: '% de éxito',
      dataIndex: 'completedCount',
      key: 'completedCount',
      render: (_, record) =>
        `${getCompletedPercentage(record)}% (${record.completedCount})`
    },
    {
      title: '...',
      align: 'center',
      dataIndex: '',
      key: '',
      width: '80px',
      render: (data, item) => (
        <FlowsAcciones
          item={item}
          setFlows={setFlows}
          setLoading={setLoading}
        />
      )
    }
  ];
};

const statusColor = (data) => {
  if (data) {
    switch (data) {
      case 'draft':
        return '';
      case 'publish':
        return 'green';
      default:
        return '';
    }
  } else {
    return '';
  }
};
