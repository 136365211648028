import { useContext, useEffect, useMemo, useState } from "react";
import { translateBranches } from "./translateBranches";
import { FlowContext } from "../../../../context/FlowContext";
import {
  BROADCAST_VARIABLES,
  CONTACT_VARIABLES,
  CONTEXT_VARIABLES,
} from "./contextVariables";
import { isInterpolate } from "./httpRequest";
import { handleListIds } from "./handleListIds";

export const useTranslateNodes = (nodes, edges) => {
  const { flowVariables, type, flowAEditar } = useContext(FlowContext);

  const [triggers, setTriggers] = useState([]);
  const [errorInt, setErrorInt] = useState([]);
  const [steps, setSteps] = useState([]);
  const [flowVars, setFlowVars] = useState({});

  const variablesCompletas = useMemo(() => {
    let array = flowVariables?.map((element) => {
      return element.name;
    });

    CONTEXT_VARIABLES.forEach((element) => {
      array.push(element.name);
    });

    CONTACT_VARIABLES.forEach((element) => {
      array.push(element.value);
    });

    BROADCAST_VARIABLES.forEach((element) => {
      array.push(element.value);
    });

    return array;
  }, [flowVariables]);

  const orderedNodes = useMemo(() => {
    let array = [...nodes];

    const triggerNodeId = nodes.find(
      (element) => element.type === "trigger"
    ).id;

    if (triggerNodeId) {
      const firstNodeId = edges.find(
        (element) => element.source === triggerNodeId
      )?.target;

      const firstNode = nodes.find((element) => element.id === firstNodeId);

      if (firstNode) {
        array = nodes.filter((element) => element.id !== firstNodeId);

        array.unshift(firstNode);
      }
    }

    return array;
  }, [nodes]);

  useEffect(() => {
    let pasos = [];

    let hayEnd = false;

    // SE ITERA CADA NODO PARA CONSTRUIR EL JSON, POR CADA TIPO SE CHECKEA ERRORES.
    // DEVUELVE TRIGGERS, ERRORES, STEPS y FLOWVARS.

    let flowType = type || flowAEditar?.flowData?.type;

    resolveFlowVars(flowType);

    //* ordenar primer nodo.

    orderedNodes?.forEach((node) => {
      checkEdges(node);

      if (node.type === "note") {
        // No se traduce nada si es una nota. Al webhook no le interesa
        return;
      }

      if (node.type === "trigger") {
        if (node.data.triggers?.length > 0) {
          setTriggers(node.data.triggers);
        } else {
          let errorMsg = "No se definieron disparadores";
          addError(errorMsg);
        }
      } else {
        let result;

        if (node.type === "end") {
          if (!hayEnd) {
            result = translateNode(node);
          }
          hayEnd = true;
        } else {
          result = translateNode(node);
        }

        if (typeof result === "object") {
          pasos.push(result);
        }
      }
    });

    setSteps(pasos);
  }, [orderedNodes]);

  const translateNode = (node) => {
    switch (node.type) {
      case "message":
        return resolveMessage(node);
      case "list":
        return resolveList(node);
      case "end":
        return resolveEnd(node);
      case "buttons":
        return resolveButtons(node);
      case "budget":
        return resolveBudget(node);
      case "branch":
        return resolveBranch(node);
      case "cta_button":
        return resolveCtaButton(node);
      case "dateTime":
        return resolveTime(node);
      case "encode":
        return resolveEncode(node);
      case "decode":
        return resolveDecode(node);
      case "transcribeAudio":
        return resolveTranscribeAudio(node);
      case "httpRequest":
        return resolveHttpRequest(node);
      case "setDelay":
        return resolveSetDelay(node);
      case "saveResponse":
        return resolveSaveResponse(node);
      case "iterableList":
        return resolveIterableList(node);
      case "setVariables":
        return resolveSetVariables(node);
      case "sendTemplate":
        return resolveSendTemplate(node);
      // case "textToSpeech":
      //   return resolveTextToSpeech(node);
      case "workingNode":
        return resolveWorkingNode(node);
      case "oneTimePassword":
        return resolveOneTimePassword(node);
      case "sendMultiProduct":
        return resolveSendMultiProduct(node);
      case "sendSingleProduct":
        return resolveSendSingleProduct(node);
      case "callFunction":
        return resolveCallFunction(node);
      case "contactAgent":
        return resolveContactAgent(node);
      case "isContact":
        return resolveIsContact(node);
      case "formula":
        return resolveFormula(node);
      case "sendMessageToChat":
        return resolveSendMessageToChat(node);
      case "tagContact":
        return resolveTagContact(node);
      case "broadcast":
        // Dejar así para flows tipo campaña
        return;
      case "subscription":
        return resolveSubscription(node);
      case "goToFlow":
        return resolveGoToFlow(node);
      case "note":
        // No interesa
        break;
      case "sendEmail":
        return resolveSendEmail(node);
      default:
        let errorMsg = `El nodo ${node.id} no está configurado`;
        addError(errorMsg);
        break;
    }
  };

  const resolveSendEmail = (node) => {
    const { label, subject, body, receivers } = node.data;

    let tmp = {
      id: Number(node.id),
      auto: true,
      name: label,
      type: "sendEmail",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    let payload = {
      subject,
      body,
      receivers,
    };

    tmp.data.payload.push(payload);

    if (node?.data) {
      let { data } = node;

      getVariablesFromText(data.bodyText, node.id);
      getVariablesFromText(data.subjectText, node.id);

      tmp.name = data.label;
      tmp.auto = data.auto !== undefined ? data.auto : true;
    }

    return tmp;
  };

  const resolveGoToFlow = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "goToFlow",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (!node?.data?.flowId) {
      addError(`El nodo ${node.id} necesita un bot seleccionado`);
    } else {
      let payload = {
        flowId: node.data.flowId,
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveSubscription = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "subscription",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (!node?.data?.idEnvioMasivo) {
      addError(`El nodo ${node.id} necesita un envio masivo seleccionado`);
    } else {
      let payload = {
        idEnvioMasivo: node.data.idEnvioMasivo,
        addSubscription: node.data.addSubscription || false,
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveTagContact = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "tagContact",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (!node?.data?.tag) {
      addError(`El nodo ${node.id} necesita una etiqueta`);
    } else {
      let payload = {
        tag: node.data.tag,
        addTag: node.data.addTag || false,
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveSendMessageToChat = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "sendMessageToChat",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (!node?.data?.bodyText) {
      addError(`El nodo ${node.id} necesita contenido`);
    } else {
      getVariablesFromText(node.data?.bodyText, node.id);
      let payload = {
        message: node.data.bodyText,
        interpolate: isInterpolate(node.data.bodyText, node.id),
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveFormula = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "formula",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    checkVariable(node.data.variable?.name, node.id);

    let payload = {
      variable: node.data.variable,
      input: node.data.input,
      operation: node.data.operation,
    };

    tmp.data.payload.push(payload);

    return tmp;
  };

  const resolveIsContact = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "isContact",
      data: null,
      childId: resolveIsContactChildren(node),
    };

    return tmp;
  };

  const resolveIsContactChildren = (node) => {
    let obj = {};

    const nodeEdges = edges.filter((element) => element.source === node.id);

    nodeEdges.forEach((edge) => {
      if (edge.sourceHandle) {
        obj[edge.sourceHandle] = isEnd(edge);
      }
    });

    return obj;
  };

  const resolveContactAgent = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "contactAgent",
      data: {
        payload: [],
      },
      childId: resolveIsContactChildren(node),
    };

    const { data } = node;
    let payload = {
      team: data?.team || null,
      agent: data?.agent || null,
    };

    // payload.variable = data.variable;

    tmp.data.payload.push(payload);

    return tmp;
  };

  const resolveCallFunction = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "callFunction",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    const { variableAudio, variableText, prompt } = node.data;

    if (!variableAudio || !variableText || !prompt) {
      addError(`El nodo ${node.id} necesita que configure sus variables`);
    } else {
      tmp.variable = variableAudio;

      getVariablesFromText(prompt, node.id);

      checkVariable(variableAudio, node.id);

      checkVariable(variableText, node.id);

      tmp.data.payload = [
        {
          variable: {
            name: variableText,
            type: "string",
          },
          prompt: prompt,
        },
      ];
    }

    return tmp;
  };

  const resolveCtaButton = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "interactive",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.bodyText || (!data.button?.display_text && !data.button?.url)) {
        addError(`El nodo ${node.id} no tiene cuerpo, texto del boton o URL`);
      }

      tmp.data.payload = [];

      tmp.data.payload.push({
        message: {
          type: "cta_url",
        },
      });

      // HEADER TYPE para determinar que tipo de header es, media, text.. y resuelve cada caso
      let header = switchAnswerInteractive(data, node);
      if (header) {
        tmp.data.payload[0].message.header = header[0]?.message;
      }

      if (data.bodyText) {
        let body = {
          text: data.bodyText,
        };
        tmp.data.payload[0].message.body = body;
      }

      if (data.footerText) {
        let footer = {};
        footer.text = data.footerText;
        tmp.data.payload[0].message.footer = footer;
      }

      let action = {
        name: "cta_url",
        parameters: {
          display_text: data.button.display_text,
          url: data.button.url,
        },
      };

      tmp.data.payload[0].message.action = action;
    }

    return tmp;
  };

  const resolveDecode = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "decode",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.body && !data.bodyVars) {
        addError(`El nodo ${node.id} no tiene contenido`);
      }

      if (!data.variable?.name) {
        addError(`El nodo ${node.id} no tiene una variable de salida`);
      } else {
        checkVariable(data?.variable?.name, node.id);
      }

      let payload = {
        body: data.body,
        inputType: data.inputType,
        outputType: data.outputType,
        variable: data.variable,
        bodyVars: data.bodyVars ? data.bodyVars : null,
        secret: data.secret ? data.secret : null,
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveEncode = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "encode",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.body && !data.bodyVars) {
        addError(`El nodo ${node.id} no tiene contenido`);
      }

      if (!data.variable?.name) {
        addError(`El nodo ${node.id} no tiene una variable de salida`);
      } else {
        checkVariable(data?.variable?.name, node.id);
      }

      let payload = {
        body: data.body,
        inputType: data.inputType,
        outputType: data.outputType,
        variable: data.variable,
        bodyVars: data.bodyVars ? data.bodyVars : null,
        secret: data.secret ? data.secret : null,
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveBudget = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "budget",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.clientId) {
        addError(`El nodo ${node.id} no tiene una variable para cliente`);
      }

      if (!data.clientId) {
        addError(`El nodo ${node.id} no tiene una variable de salida`);
      }

      let payload = {
        clientId: data.clientId,
        listId: data.listId,
        products: data.products,
        phoneNumber: data.phoneNumber,
        message: data.message,
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveSendSingleProduct = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "sendSingleProduct",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.product || !data.catalog_id) {
        addError(`El nodo ${node.id} no tiene producto configurado`);
      }

      if (!data.bodyText) {
        addError(`El nodo ${node.id} no tiene cuerpo.`);
      }

      let payload = {
        interactive: {
          type: "product",
          body: {
            text: data.bodyText,
          },
          action: {
            catalog_id: data.catalog_id,
            product_retailer_id: data.product?.id?.toString(),
          },
        },
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveSendMultiProduct = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "sendMultiProduct",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.bodyText) {
        addError(`El nodo ${node.id} no tiene cuerpo.`);
      }

      if (data.listContent?.sections?.length < 1) {
        addError(`El nodo ${node.id} no tiene secciones configurada`);
      }

      let content = data.listContent?.sections?.map((seccion) => {
        return {
          title: seccion.title,
          product_items: seccion.product_items.map((producto) => {
            return {
              product_retailer_id: producto.product_retailer_id,
            };
          }),
        };
      });

      let payload = {
        interactive: {
          type: "product_list",
          header: {
            type: "text",
            text: data.headerText,
          },
          body: {
            text: data.bodyText,
          },
          action: {
            catalog_id: data.catalog_id,
            sections: content,
          },
        },
      };

      tmp.data.payload.push(payload);
    }

    return tmp;
  };

  const resolveSendTemplate = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "sendTemplate",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let { data } = node;

      if (!data.template.id) {
        addError(`Debe seleccionar un template en el nodo ${node.id}`);
      }

      tmp.data.payload.push({
        ...data.template,
        templateVars: data.templateVariables,
      });

      if (data?.variableName) {
        checkVariable(data?.variableName, node.id);
      }

      tmp.data.payload.push({
        variable: data.variableName,
      });
    }

    return tmp;
  };

  const resolveOneTimePassword = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "OTP",
      data: { payload: [] },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let data = node.data;

      if (data.characters && data.count) {
        tmp.data.payload.push({
          configuration: {
            length: data.count,
            chars: data.characters,
          },
        });
      } else {
        addError(`Configure caracteres y su cantidad para el nodo ${node.id}`);
      }

      if (data.variableName) {
        checkVariable(data?.variableName, node.id);

        tmp.data.payload.push({
          variable: { name: data.variableName, type: "string" },
        });
      } else {
        addError(`Ingrese una variable para el nodo ${node.id}`);
      }
    }

    return tmp;
  };

  const resolveWorkingNode = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "workingNode",
      data: {},
      childId: resolveEdges(node),
    };

    return tmp;
  };

  // const resolveTextToSpeech = (node) => {
  //   let tmp = {
  //     id: Number(node.id),
  //     auto: true,
  //     type: "textToSpeech",
  //     data: {
  //       payload: [],
  //     },
  //     childId: resolveEdges(node),
  //   };

  //   let obj = {};

  //   if (node.data) {
  //     let data = node.data;

  //     tmp.name = data.label;

  //     if (data.body) obj.body = data.body;

  //     if (data.voice && data.voice?.name) {
  //       obj.voice = data.voice;
  //     } else {
  //       addError(`Seleccione una opción de voz para el nodo ${node.id}`);
  //     }

  //     tmp.data.payload.push(obj);

  //     if (data.variableName) {
  //       checkVariable(data?.variableName, node.id);

  //       tmp.data.payload.push({ variable: data.variableName });
  //     }
  //   }

  //   return tmp;
  // };

  const resolveSetVariables = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "setVariables",
      data: {
        payload: [],
      },
      childId: resolveEdges(node),
    };

    if (node.data) {
      let data = node.data;

      tmp.name = data.label;

      tmp.data.payload = data.variables;
    }

    return tmp;
  };

  const resolveIterableList = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: false,
      type: "sendWhatsappMessage",
      data: {
        payload: [],
      },
      childId: resolveEdges(node),
    };

    if (node?.data) {
      let data = node.data;

      tmp.name = data.label;
      if (!data.auto && data.fallback) {
        tmp.fallback = data.fallback;
      }

      tmp.data.payload = [];

      tmp.data.payload.push({
        message: {
          type: "iterable_list",
        },
      });

      // HEADER TYPE para determinar que tipo de header es, media, text.. y resuelve cada caso
      let header = switchAnswerInteractive(data, node);
      if (header) {
        tmp.data.payload[0].message.header = header[0]?.message;
      }

      if (data.bodyText) {
        let body = {
          text: data.bodyText,
        };
        tmp.data.payload[0].message.body = body;
      }

      if (data.footerText) {
        let footer = {};
        footer.text = data.footerText;
        tmp.data.payload[0].message.footer = footer;
      }

      let action;

      if (data.listContent) {
        checkStringVariables(
          data.listContent.sections[0].rows[0].title,
          node.id
        );
        checkStringVariables(
          data.listContent.sections[0].rows[0].description,
          node.id
        );
        action = handleListIds(data.listContent);
      }

      tmp.data.payload[0].message.action = action;

      if (data.variable) {
        tmp.data.payload[0].variable = data.variable;

        if (data?.variable?.name) {
          checkVariable(data?.variable?.name, node.id);
        }

        if (data.variable?.name !== "flow_response") {
          tmp.childId = getNextStep(node.id);
        }
      } else {
        let errorMsg = `La lista iterable debe tener una variable de indice definida, nodo ${node.id}`;
        addError(errorMsg);
      }

      if (data.variableSeleccionada) {
        tmp.data.payload[0].variableBody = data.variableSeleccionada;
      } else {
        let errorMsg = `La lista iterable debe tener una variable iterable seleccionada, nodo ${node.id}`;
        addError(errorMsg);
      }
    }

    return tmp;
  };

  const resolveSaveResponse = (node) => {
    let tmp = {
      id: Number(node.id),
      auto: true,
      type: "saveResponse",
      data: {
        payload: [],
      },
      childId: resolveEdges(node),
    };

    if (node.data) {
      tmp.name = node.data.label;
      tmp.data.payload = {
        error: { ...node.data.error, fallback: node.data.fallback },
      };
    }

    return tmp;
  };

  const resolveSetDelay = (node) => {
    let tmp = {};
    tmp.id = Number(node.id);
    tmp.type = "setDelay";
    tmp.auto = true;
    tmp.childId = resolveEdges(node);

    if (!node?.data?.seconds) {
      let errorMsg = `En el nodo ${node.id} no se encontró configuración de segundos`;
      addError(errorMsg);
    }

    if (node.data) {
      tmp.data = {
        payload: [{ seconds: Number(node.data.seconds) }],
      };
    }

    return tmp;
  };

  const resolveHttpRequest = (node) => {
    let tmp = {};
    tmp.id = Number(node.id);
    tmp.type = "httpRequest";
    tmp.auto = true;

    if (node.data) {
      let data = node.data;

      if (!data.url || !data.method) {
        let errorMsg = `En el nodo ${node.id} no se encontró URL o método declarado`;
        addError(errorMsg);
      }

      getVariablesFromText(data.url, node?.id);

      let payload = [];
      if (data.variable) {
        if (data.variable?.name) {
          checkVariable(data?.variable?.name, node.id);
        }
        if (data.variable?.innerVars) {
          data.variable.innerVars.forEach((element) => {
            checkVariable(element.name, node.id);
          });
        }

        if (data.arrayHeaders) {
          data.arrayHeaders?.forEach((item) => {
            if (item?.type === "var") {
              checkVariable(item.value, node?.id);
            } else {
              getVariablesFromText(item.value, node?.id);
            }
          });
        }

        if (data.bodyVars) {
          data.bodyVars.forEach((item) => {
            if (item?.type === "var") {
              checkVariable(item.value, node?.id);
            } else {
              getVariablesFromText(item.value, node?.id);
            }
          });
        } else if (data.body) {
          getVariablesFromText(data.body, node.id);
        }

        if (data.method?.toLowerCase() === "post") {
          payload.push(
            {
              request: {
                headers: data.arrayHeaders,
                method: data.method,
                url: data.url,
                urlParams: data.urlParams || null,
                interpolate: isInterpolate(data.url, node.id),
                body: data.body
                  ? JSON.stringify(JSON.parse(data.body))
                  : data.bodyVars
                  ? data.bodyVars
                  : "",
                bodyType: data.bodyType,
              },
            },
            {
              variable: {
                name: data.variable.name,
                type: "string",
                innerVars: data.variable.innerVars,
                indexedVars: data.variable?.indexedVars,
                msgEmptyRes: data.msgEmptyRes || "",
              },
            }
          );
        } else {
          payload.push(
            {
              request: {
                headers: data.arrayHeaders,
                method: data.method,
                url: data.url,
                urlParams: data.urlParams || null,
                interpolate: isInterpolate(data.url, node.id),
              },
            },
            {
              variable: {
                name: data.variable.name,
                type: "string",
                innerVars: data.variable.innerVars,
                indexedVars: data.variable?.indexedVars,
                msgEmptyRes: data.msgEmptyRes || "",
              },
            }
          );
        }
      } else {
        let errorMsg = `El nodo ${node.id} debe guardar una variable`;
        addError(errorMsg);
      }

      tmp.name = data.label;
      tmp.fallback = null;

      tmp.childId = resolveEdges(node);
      tmp.fallback = data.fallback;

      tmp.data = {
        payload: payload,
      };
    }

    return tmp;
  };

  const resolveTranscribeAudio = (node) => {
    let tmp = {};
    tmp.id = Number(node.id);
    tmp.type = "transcribeMedia";
    tmp.auto = true;

    if (node.data) {
      let data = node.data;

      tmp.name = data.label;
      tmp.data = {};
      tmp.childId = resolveEdges(node);
      tmp.variable = data.variableAudio;

      if (data.variableAudio) {
        checkVariable(data.variableAudio, node.id);
      }

      if (data.variableText) {
        checkVariable(data.variableText, node.id);
      }

      if (!data.variableAudio) {
        let errorMsg = `Error: el nodo ${node.id} debe tener una variable de audio definida`;
        addError(errorMsg);
      } else {
        tmp.data.payload = [
          {
            service: data.service || "Google",
            model: data.modelType,
            variable: {
              name: data.variableText,
              type: "string",
            },
          },
        ];
      }
    }

    return tmp;
  };

  const resolveTime = (node) => {
    let tmp = {};

    tmp.id = Number(node.id);
    tmp.type = "dateTime";
    tmp.tz = "-3";
    tmp.auto = true;

    if (node.data) {
      tmp.name = node.data.label;
      tmp.data = {};
      tmp.data.payload = resolveTimeChildren(node.id, node.data.payload);
    }

    return tmp;
  };

  const isEnd = (edge) => {
    let target = edge.target;
    let nodeId = orderedNodes.findIndex((node) => node.id === Number(edge.source));
    let index = orderedNodes.findIndex((node) => node.id === target);

    if (index >= 0) {
      if (orderedNodes[index].type === "end") {
        return -1;
      } else {
        return Number(target);
      }
    } else {
      let errorMsg = `Error en el nodo ${nodeId}, configurar siguiente nodo o fin del flujo!`;
      addError(errorMsg);
    }
  };

  const resolveTimeChildren = (nodeId, cases) => {
    let filtroEdges = edges.filter((edge) => edge.source === nodeId);

    filtroEdges.forEach((edge) => {
      if (edge.sourceHandle === "default") {
        let condiciones = [...cases];

        let lastElement = condiciones[condiciones.length - 1];
        if (lastElement.value?.length !== 0) {
          cases.push({
            childId: isEnd(edge),
            value: [],
          });
        } else {
          cases[condiciones.length - 1].childId = isEnd(edge);
        }
      } else {
        cases[Number(edge.sourceHandle) - 1].childId = isEnd(edge);
      }
    });

    return cases;
  };

  const resolveEdges = (node) => {
    for (let i = 0; i < edges.length; i++) {
      if (
        edges[i].source === node.id &&
        edges[i].sourceHandle?.toLowerCase() === "nextstep"
      ) {
        return isEnd(edges[i]);
      }
    }
  };

  const resolveBranch = (node) => {
    let tmp = {};
    tmp.id = Number(node.id);
    tmp.type = "branch";
    tmp.auto = true;

    if (node.data) {
      tmp.name = node.data.label;
      if (node.data.variable) {
        if (node.data?.variable?.name) {
          checkVariable(node.data?.variable?.name, node.id);
        }
        tmp.variable = node.data.variable.name;
      } else {
        tmp.variable = "flow_response";
      }

      tmp.data = {};

      tmp.data.payload = [
        {
          cases: resolveBranchChildren(node.id, node.data.cases),
        },
      ];
    }

    return tmp;
  };

  const resolveBranchChildren = (nodeId, cases) => {
    let filtroEdges = edges.filter((edge) => edge.source === nodeId);

    filtroEdges.forEach((edge) => {
      let condiciones = [...cases];
      if (edge.sourceHandle === "default") {
        let lastElement = condiciones[condiciones.length - 1];
        if (lastElement.value || lastElement.value === 0) {
          cases.push({
            id: condiciones.length + 1,
            childId: isEnd(edge),
          });
        } else {
          cases[condiciones.length - 1].childId = isEnd(edge);
        }
      } else {
        let id;
        condiciones.forEach((element, index) => {
          if (element.id === Number(edge.sourceHandle)) {
            id = index;
          }
        });

        cases[Number(id)].childId = isEnd(edge);
      }
    });

    return cases;
  };

  const checkStringVariables = (text, nodeId) => {
    if (text) {
      // const regex = /\${[\w\[\].0-9]+}/g;
      const regex = /\${[^\s}]+}/g;
      let variables = text.match(regex) || [];
      variables = variables.map((element) => {
        return element.replace("${", "").replace("}", "");
      });

      variables.forEach((element) => {
        if (!variablesCompletas.includes(element)) {
          let errorMsg = `La variable ${element} del nodo ${nodeId} no se encuentra, su nombre puede haberse modificado o ya no existe`;
          addError(errorMsg);
        }
      });
    }
  };

  const resolveMessage = (node) => {
    let tmp = {};

    tmp.id = Number(node.id);
    tmp.type = "sendWhatsappMessage";
    tmp.interpolate = isInterpolate(node.data.bodyText, node.id);
    tmp.childId = resolveEdges(node);

    tmp.data = {};

    if (node?.data) {
      let { data } = node;

      if (data?.variable?.name) {
        checkVariable(data?.variable?.name, node.id);
      }

      getVariablesFromText(data.bodyText, node.id);

      tmp.name = data.label;
      tmp.auto = data.auto !== undefined ? data.auto : true;

      tmp.data.payload = switchAnswerMessage(data, node);
    }

    return tmp;
  };

  const switchAnswerInteractive = (data, node) => {
    if (data?.variable?.name) {
      checkVariable(data?.variable?.name, node.id);
    }

    // HEADER TYPE para determinar que tipo de header es, media, text.. y resuelve cada caso
    if (data.headerType) {
      let payload = [];
      if (data.headerType === "text") {
        if (!data.bodyText) {
          addError(`El nodo ${node.id} no tiene cuerpo.`);
        }

        if (!data.auto) {
          payload = [
            {
              message: {
                type: "text",
                text: data.headerText,
              },
              variable: data.variable,
            },
          ];
        } else {
          payload = [
            {
              message: {
                type: "text",
                text: data.headerText,
              },
            },
          ];
        }
      } else {
        if (data.url) {
          checkURL(data.url, node.id);
        } else if (data.id) {
          checkID(data.id, node.id);
        }

        switch (data.headerMediaType) {
          case "image":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "image",
                    image: {
                      link: data.url,
                    },
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "image",
                    image: {
                      id: data.id,
                    },
                  },
                },
              ];
            }
            break;
          case "audio":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "audio",
                    audio: {
                      link: data.url,
                    },
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "audio",
                    audio: {
                      id: data.id,
                    },
                  },
                },
              ];
            }
            break;
          case "video":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "video",
                    video: {
                      link: data.url,
                    },
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "video",
                    video: {
                      id: data.id,
                    },
                  },
                },
              ];
            }
            break;
          case "document":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "document",
                    document: {
                      link: data.url,
                    },
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "document",
                    document: {
                      id: data.id,
                    },
                  },
                },
              ];
            }
            break;
          case "location":
            payload = [
              {
                message: {
                  type: "location",
                  location: {
                    latitude: data.location?.latitude,
                    longitude: data.location?.longitude,
                    name: data.location?.name,
                    address: data.location?.address,
                  },
                },
              },
            ];
            break;
          default:
            let errorMsg = `El tipo de MEDIA seleccionado en el nodo ${node.id} no es correcto.`;
            addError(errorMsg);
            payload = [];
        }
      }

      return payload;
    } else {
      addError(`El nodo ${node.id} no está configurado.`);
      return null;
    }
  };

  const addError = (message) => {
    setErrorInt((prevState) => {
      if (prevState.includes(message)) {
        return [...prevState];
      } else {
        return [...prevState, message];
      }
    });
  };

  const isURLValid = (text, nodeId) => {
    let flag = /^(https?|ftp):\/\//.test(text);

    if (!flag) {
      let errorMsg = `La URL del nodo ${nodeId} debe comenzar con http:// || https:// || ftp://`;
      addError(errorMsg);
    }
  };

  const checkID = (id, nodeId) => {
    let flag = /^[0-9]+$/.test(id);

    if (!flag) {
      let errorMsg = `La ID del nodo ${nodeId} debe ser solo números`;
      addError(errorMsg);
    }
  };

  const checkURL = (text, nodeId) => {
    if (isInterpolate(text, nodeId)) {
      isURLValid(text, nodeId);
    }
  };

  const switchAnswerMessage = (data, node) => {
    //MENSAJES
    if (data.headerType) {
      let payload = [];
      if (data.headerType === "text") {
        if (!data.bodyText) {
          addError(`El nodo ${node.id} no tiene cuerpo.`);
        }

        if (!data.auto) {
          payload = [
            {
              message: {
                type: "text",
                text: data.bodyText,
              },
            },
          ];
        } else {
          payload = [
            {
              message: {
                type: "text",
                text: data.bodyText,
              },
            },
          ];
        }
      } else {
        if (data.url) {
          checkURL(data.url, node.id);
        } else if (data.id) {
          // checkID(data.id, node.id);
        }
        switch (data.headerMediaType) {
          case "image":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "image",
                    link: data.url,
                    caption: data.caption,
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "image",
                    id: data.id,
                    caption: data.caption,
                  },
                },
              ];
            }
            break;
          case "audio":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "audio",
                    link: data.url,
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "audio",
                    id: data.id,
                  },
                },
              ];
            }
            break;
          case "video":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "video",
                    link: data.url,
                    caption: data.caption,
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "video",
                    id: data.id,
                    caption: data.caption,
                  },
                },
              ];
            }
            break;
          case "document":
            if (data.url !== undefined) {
              payload = [
                {
                  message: {
                    type: "document",
                    link: data.url,
                    filename: data.caption,
                  },
                },
              ];
            } else {
              payload = [
                {
                  message: {
                    type: "document",
                    id: data.id,
                    filename: data.caption,
                  },
                },
              ];
            }
            break;
          case "location":
            payload = [
              {
                message: {
                  type: "location",
                  location: {
                    latitude: data.location?.latitude,
                    longitude: data.location?.longitude,
                    name: data.location?.name,
                    address: data.location?.address,
                  },
                },
              },
            ];
            break;
          default:
            payload = [];
        }
      }

      if (data.variable) {
        // Agregar variable al payload
        if (payload[0]) {
          payload[0].variable = data.variable;
        }
      }

      return payload;
    } else {
      addError(`El nodo ${node.id} no está configurado.`);
    }
  };

  const resolveEnd = (node) => {
    let tmp = {
      name: "Fin del flujo",
      id: -1,
      type: "endFlow",
      data: {},
    };

    return tmp;
  };

  const getNextStep = (nodeId) => {
    let array = edges.filter((edge) => {
      if (edge.source === nodeId && edge.sourceHandle === "nextstep") {
        return true;
      } else {
        return false;
      }
    });

    if (array.length > 0) {
      return Number(array[0].target);
    } else {
      let errorMsg = `No se pudo determinar el paso siguiente del nodo ${nodeId}`;
      addError(errorMsg);
    }
  };

  // const replaceTag = (texto) => {
  //   if (texto) {
  //     const regexPattern = /<span class='custom-tag'>(.*?)<\/span>/g;
  //     return texto.replace(regexPattern, "${$1}");
  //   }

  //   return "";
  // };

  const resolveButtons = (node) => {
    let tmp = {};
    tmp.id = Number(node.id);
    tmp.type = "sendWhatsappMessage";
    tmp.childId = `branch_${node.id}`;
    tmp.interpolate = isInterpolate(node.data?.bodyText, node.id);
    tmp.data = {};

    if (node?.data) {
      let data = node.data;

      tmp.name = data.label;
      tmp.auto = false;
      if (!data.auto && data.fallback) {
        tmp.fallback = data.fallback;
      }

      tmp.data.payload = [];

      tmp.data.payload.push({
        message: {
          type: "button",
        },
      });

      // HEADER TYPE para determinar que tipo de header es, media, text.. y resuelve cada caso

      let header = switchAnswerInteractive(data, node);
      if (header) {
        tmp.data.payload[0].message.header = header[0]?.message;
      }

      if (data.bodyText) {
        let body = {
          text: data.bodyText,
        };
        tmp.data.payload[0].message.body = body;
      }

      if (data.footerText) {
        let footer = {};
        footer.text = data.footerText;
        tmp.data.payload[0].message.footer = footer;
      }

      let action = { buttons: [] };

      if (data.buttonsContent) {
        if (data.buttonsContent.length > 0) {
          data.buttonsContent.forEach((text, index) => {
            action.buttons.push({
              type: "reply",
              reply: {
                id: String(index + 1),
                title: text,
              },
            });
          });
        }
      }

      tmp.data.payload[0].message.action = action;

      if (data.variable) {
        if (data?.variable?.name) {
          checkVariable(data?.variable?.name, node.id);
        }

        tmp.data.payload[0].variable = data.variable;
        if (node.data.variable.name !== "flow_response") {
          tmp.childId = getNextStep(node.id);
        }
      }
    }

    return tmp;
  };

  const resolveList = (node) => {
    let tmp = {};
    tmp.id = Number(node.id);
    tmp.type = "sendWhatsappMessage";
    tmp.interpolate = isInterpolate(node.data?.bodyText, node.id);
    tmp.childId = `branch_${node.id}`;
    tmp.data = {};

    if (node?.data) {
      let data = node.data;

      tmp.name = data.label;
      tmp.auto = false;
      if (!data.auto && data.fallback) {
        tmp.fallback = data.fallback;
      }

      tmp.data.payload = [];

      tmp.data.payload.push({
        message: {
          type: "list",
        },
      });

      // HEADER TYPE para determinar que tipo de header es, media, text.. y resuelve cada caso
      let header = switchAnswerInteractive(data, node);
      if (header) {
        tmp.data.payload[0].message.header = header[0]?.message;
      }

      if (data.bodyText) {
        let body = {
          text: data.bodyText,
        };
        tmp.data.payload[0].message.body = body;
      } else {
        addError(`El nodo ${node.id} no tiene cuerpo.`);
      }

      if (data.footerText) {
        let footer = {};
        footer.text = data.footerText;
        tmp.data.payload[0].message.footer = footer;
      }

      if (data.listContent?.sections?.length < 1) {
        addError(`El nodo ${node.id} no tiene secciones configurada`);
      }

      let action;

      if (data.listContent) {
        action = handleListIds(data.listContent);
      }

      tmp.data.payload[0].message.action = action;

      if (data.variable?.name !== "" && data.variable?.name !== undefined) {
        checkVariable(data.variable.name, node.id);

        tmp.data.payload[0].variable = {
          name: data.variable.name,
          type: data.variable.type,
          error: data.error,
        };

        if (node.data.variable.name !== "flow_response") {
          tmp.childId = getNextStep(node.id);
        }
      }
    }

    return tmp;
  };

  const checkEdges = (node) => {
    let handleCount = node.data?.handleCount ? node.data.handleCount : 1;

    // Checkear edges por cada nodo, excepto estos que no necesitan edges
    if (!["endFlow", "end", "note"].includes(node.type)) {
      let filteredEdges = edges.filter(
        (edge) => Number(edge.source) === Number(node.id)
      );

      if (filteredEdges.length < handleCount) {
        let errorMsg = `Error en el nodo ${node.id}, conectar nodo/s siguiente/s o fin del flujo!`;
        addError(errorMsg);
      }
    }
  };

  const resolveFlowVars = (flowType) => {
    let branch = [];

    orderedNodes.forEach((node) => {
      if (["buttons", "list"].includes(node.type)) {
        branch.push(translateBranches(node, orderedNodes, edges));
      }
    });

    let variables = flowVariables?.map((variable) => {
      return {
        name: variable.name,
        type: variable.type,
        value: "",
      };
    });

    if (flowType === "broadcast") {
      variables.push({
        name: "broadcast_text",
        type: "string",
        value: "",
      });

      variables.push({
        name: "broadcast_index",
        type: "number",
        value: null,
      });
    }

    setFlowVars({
      flowId: null,
      number: null,
      variables: variables,
      branch: branch,
    });
  };

  const checkVariable = (variableName, nodeId) => {
    if (!variablesCompletas?.includes(variableName)) {
      let errorMsg = `La variable ${variableName} usada en el nodo ${nodeId} no se encuentra, esto puede deberse a que se modificó el nombre o fue borrada. Intente guardando el nodo otra vez`;
      addError(errorMsg);
    }
  };

  const getVariablesFromText = (text, nodeId) => {
    const regex = /\${[^\s}]+}/g;
    let matches = text?.match(regex) || [];

    if (matches.length > 0) {
      let variableNames = [];

      matches.forEach((element) => {
        const match = element.match(/\${([^\s}]+)}/);
        if (match && match?.[1]) {
          variableNames.push(match[1]);
        }
      });

      variableNames?.forEach((element) => {
        checkVariable(element, nodeId);
      });
    }
  };

  return { errorInt, steps, triggers, flowVars };
};
