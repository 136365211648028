import { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { Tabs } from 'antd';
import { ExtrasCalendar } from './ExtrasCalendar';
import { TicketsTab } from './tabs/TicketsTab';
import { NotesTab } from './tabs/NotesTab';
import { MediaTab } from './tabs/MediaTab';
import { ExtrasBuscador } from './ExtrasBuscador';
import './extras.css';
import { ExtrasTags } from './ExtrasTags';
import { useMedia } from './hooks/useMedia';
import { useFilteredData } from './hooks/useFilteredData';
import { useCounter } from './hooks/useCounter';

const dataPrueba = {
  messages: [
    {
      _id: 'a',
      teamId: '65b8f08c4fefd19711ab8182',
      userId: '65e87c719584d3fbe376661f',
      messages: [
        {
          _id: 'a1',
          userType: 'agent',
          type: 'text',
          timestamp: 1715174005000 // 08/05/24 10:13
        },
        {
          _id: 'a2',
          userType: 'user',
          type: 'text',
          timestamp: 1715174205000 // 08/05/24 10:16
        },
        {
          _id: 'a3',
          userType: 'agent',
          type: 'document',
          timestamp: 1715174305000 // 08/05/24 10:18
        },
        {
          _id: 'a4',
          userType: 'user',
          type: 'audio',
          timestamp: 1715174505000 // 08/05/24 10:21
        }
      ],
      createdAt: 1715174005000, // 08/05/24 10:13
      closeDate: 1715175482000 // 08/05/24 10:38
    },
    {
      _id: 'b',
      teamId: '65b8f08c4fefd19711ab8182',
      userId: '658c4d4a9d99f571d96f371c',
      messages: [
        {
          _id: 'b1',
          userType: 'user',
          type: 'text',
          timestamp: 1715192586000 // 08/05/24 15:23
        },
        {
          _id: 'b2',
          userType: 'agent',
          type: 'image',
          timestamp: 1715193272000 // 08/05/24 15:34
        },
        {
          _id: 'b3',
          systemType: 'reassign',
          userFrom: '65e87c719584d3fbe376661f',
          userTo: '6593f320ae58dfe2f762690b',
          userType: 'agent',
          type: 'text',
          timestamp: 1715193328000 // 08/05/24 15:35
        },
        {
          _id: 'b4',
          userType: 'user',
          type: 'text',
          timestamp: 1715193547000 // 08/05/24 15:39
        },
        {
          _id: 'b5',
          systemType: 'reassign',
          userFrom: '6593f320ae58dfe2f762690b',
          userTo: '658c4d4a9d99f571d96f371c',
          userType: 'agent',
          type: 'text',
          timestamp: 1715193619000 // 08/05/24 15:40
        }
      ],
      createdAt: 1715192578000, // 08/05/24 15:22
      closeDate: 1715193767000 // 08/05/24 15:42
    },
    {
      _id: 'c',
      teamId: '65b8f08c4fefd19711ab8182',
      userId: '65e87c719584d3fbe376661f',
      messages: [
        {
          _id: 'c1',
          userType: 'user',
          type: 'text',
          timestamp: 1716813763000 // 27/05/24 9:42
        },
        {
          _id: 'c2',
          userType: 'agent',
          type: 'video',
          timestamp: 1716814763000 // 27/05/24 9:59
        },
        {
          _id: 'c3',
          userType: 'user',
          type: 'image',
          timestamp: 1716814963000 // 27/05/24 10:02
        }
      ],
      createdAt: 1716813763000, // 27/05/24 9:42
      closeDate: 1716816340000 // 27/05/24 10:25
    },
    {
      _id: 'd',
      teamId: '65b8f08c4fefd19711ab8182',
      userId: '65e87c719584d3fbe376661f',
      messages: [
        {
          _id: 'd1',
          userType: 'agent',
          type: 'text',
          timestamp: 1716903137000 // 28/05/24 10:32
        },
        {
          _id: 'd2',
          userType: 'agent',
          type: 'image',
          timestamp: 1716903189000 // 28/05/24 10:33
        },
        {
          _id: 'd3',
          userType: 'user',
          type: 'text',
          timestamp: 1716903514000 // 28/05/24 10:38
        }
      ],
      createdAt: 1716903122000, // 28/05/24 10:32
      closeDate: null
    }
  ],
  notes: [
    {
      _id: 1,
      timestamp: 1715174430544, // 08/05/24 10:20
      text: { body: 'Nota 1' }
    },
    {
      _id: 2,
      timestamp: 1715175461600, // 08/05/24 10:37
      text: { body: 'Nota 2' }
    },
    {
      _id: 3,
      timestamp: 1716815963000, // 27/05/24 10:19
      text: { body: 'Nota 3' }
    }
  ]
};

export const ExtrasColumn = ({
  chatFormattedData,
  selectedContact,
  selectedConversation,
  ticketTypes,
  closingTypes,
  loading
}) => {
  const [filtros, setFiltros] = useState(['tickets', 'notes', 'media']); // Filtros del buscador
  const [selectedDate, setSelectedDate] = useState('todo'); // Fecha seleccionada en el calendario
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [tab, setTab] = useState('tickets');

  const { contactos } = useContext(GlobalContext);

  // Almacena los datos del contacto para que la columna solo se muestre cuando hay una conversación o un contacto seleccionado:
  const contacto = useMemo(() => {
    const phone = selectedContact?.phoneNumber || selectedConversation?.phone;
    return phone
      ? contactos?.find((contacto) => contacto.phones?.[0]?.phone === phone)
      : null;
  }, [selectedContact, selectedConversation, contactos]);

  useEffect(() => {
    setTickets(chatFormattedData?.messages);
  }, [chatFormattedData]);

  const media = useMedia(tickets);

  const { filteredTickets, filteredNotes, filteredMedia } = useFilteredData(
    { messages: tickets, notes: chatFormattedData?.notes, media },
    selectedDate,
    selectedTicket
  );

  const { contador, tipoContador } = useCounter(
    filteredTickets,
    filteredNotes,
    filteredMedia,
    tab
  );

  const itemsTab = [
    {
      key: 'tickets',
      label: 'Tickets',
      children: (
        <TicketsTab
          tickets={filteredTickets}
          setSelectedTicket={setSelectedTicket}
          setSelectedDate={setSelectedDate}
          ticketTypes={ticketTypes}
          closingTypes={closingTypes}
          handleTabChange={handleTabChange}
          loading={loading}
        />
      )
    },
    {
      key: 'notas',
      label: 'Notas',
      children: <NotesTab notes={filteredNotes} loading={loading} />
    },
    {
      key: 'archivos',
      label: 'Media',
      children: <MediaTab media={filteredMedia} loading={loading} />
    }
  ];

  // Si se selecciona un día (o "Todo"), se reestablece el filtro por ticket seleccionado:
  useEffect(() => {
    setSelectedTicket(null);
  }, [selectedDate]);

  function handleTabChange(newTab) {
    if (newTab === 'tickets') {
      setSelectedTicket(null);
      setSelectedDate('todo');
    }
    setTab(newTab);
  }

  return (
    <div className='ExtrasColumn'>
      {contacto?._id && (
        <>
          <ExtrasBuscador filtros={filtros} setFiltros={setFiltros} />
          <ExtrasTags contacto={contacto} />
          <ExtrasCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            chatFormattedData={chatFormattedData}
          />
          <Tabs
            activeKey={tab}
            items={itemsTab}
            tabBarExtraContent={
              <span className='extras-contador'>{`${contador} ${tipoContador}`}</span>
            }
            onChange={handleTabChange}
          />
        </>
      )}
    </div>
  );
};
