import { Handle, Position } from "reactflow";
import { isSelectedClass } from "../../../../../../helpers/isSelected";
import { useContext } from "react";
import { SlActionRedo } from "react-icons/sl";
import { LiaRobotSolid } from "react-icons/lia";
import { Tag } from "../../../../tag/Tag";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { TbTrash } from "react-icons/tb";
import { useBorrarNode } from "../utils/useBorrarNode";
import { EnviosMasivosContext } from "../../../../../context/EnviosMasivosContext";

export const ButtonNode = (node) => {
  const { etiquetas } = useContext(GlobalContext);
  const { setNodoSeleccionado, setMostrarDrawer, flows } =
    useContext(EnviosMasivosContext);

  const handleClick = () => {
    setNodoSeleccionado(node);
    setMostrarDrawer(true);
  };

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const getFlowName = () => {
    if (flows?.length < 1) {
      return "";
    } else {
      return (
        flows.find((element) => element._id === node.data.flow)?.name || ""
      );
    }
  };

  const getTagName = (id) => {
    if (etiquetas?.length < 1) {
      return <></>;
    } else {
      let tag = etiquetas.find((element) => element._id === id);

      if (tag?.name && tag?.color) {
        return (
          <span className="horizontal gap8" key={id}>
            <Tag hex={tag.color} nombre={tag.name} />
          </span>
        );
      } else {
        return <></>;
      }
    }
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => handleClick()}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header-send-template">
        <div>
          <SlActionRedo size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">Relaccionar</span>
          <span className="mensaje-nodo">Botón</span>
        </div>
      </div>
      <div className="columna" style={{ marginTop: 8, gap: 12 }}>
        {node?.data?.flow && (
          <span className="horizontal gap8">
            <LiaRobotSolid size={20} />
            {getFlowName()}
          </span>
        )}
        {node?.data?.tag?.map((tag) => {
          return getTagName(tag);
        })}
      </div>
    </div>
  );
};
