import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

const COLORES_PIE = [["#FF1717", "#FFBC17", "#2EA85C"], ["#d8d8d8"]];

export const PieGraph = ({ data, type, contactosCount = 0 }) => {
  const getFormattedData = () => {
    let array = [];

    let valor = contactosCount - data[type];

    if (valor < 0) {
      valor = 0;
    }

    if (data) {
      return [
        { name: type, value: data[type] },
        { name: "total", value: valor },
      ];
    }

    return array;
  };

  // const formatter = (value, name, props) => {
  //   return [
  //     `${Number(value)?.toLocaleString("de-DE", {
  //       maximumFractionDigits: 0,
  //     })} (${((value / data.sent) * 100).toLocaleString("de-DE", {
  //       maximumFractionDigits: 0,
  //     })}%)`,
  //     name.toUpperCase(),
  //   ];
  // };

  const getNivelColor = (index) => {
    if (index > 0) {
      return COLORES_PIE[1][0];
    }

    let count = data[type];

    let porcentaje = count / contactosCount;

    let color = 2;

    if (porcentaje <= 0.33) {
      color = 0;
    } else if (porcentaje <= 0.66) {
      color = 1;
    }

    if (porcentaje) return COLORES_PIE[0][color];
  };

  const customLabel = (props) => {
    let porcentaje =
      (props.payload[0]?.payload?.value / contactosCount) * 100 || 0;

    if (porcentaje > 100) {
      porcentaje = 100;
    }

    return (
      <span
        style={{
          fontSize: 11,
        }}
      >
        {`${porcentaje.toLocaleString("de-DE", { maximumFractionDigits: 0 })}%`}
      </span>
    );
  };

  return (
    <ResponsiveContainer width="99%" height="100%">
      <PieChart
        margin={{
          top: 30,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Pie
          data={getFormattedData()}
          dataKey="value"
          innerRadius={17}
          outerRadius={24}
          cursor="pointer"
          paddingAngle={2}
        >
          {getFormattedData().map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getNivelColor(index)} />
          ))}
        </Pie>
        <Legend
          className="legendAnillo"
          layout="horizontal"
          verticalAlign="bottom"
          wrapperStyle={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          content={customLabel}
        />
        {/* <Tooltip
          labelClassName="custom-tooltip-label"
          wrapperClassName="custom-tooltip-wrapper"
          formatter={formatter}
        /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};
