export const handleFlowVariables = (
  setFlowVariables,
  id,
  variable,
  messageApi,
  setNodes,
  setEdges
) => {
  if (variable.name === "flow_response") {
    return true;
  }

  setFlowVariables((prevState) => {
    let array = [...prevState];

    let flag = false;

    //
    let nodeVar = array.filter((element) => {
      if (element.idNodo === id && !element.kind) {
        return true;
      } else {
        return false;
      }
    });

    array = array.filter((element) => {
      if (element.idNodo !== id) {
        return true;
      } else {
        if (element.kind) {
          return true;
        } else {
          return false;
        }
      }
    });

    if (nodeVar.length > 0) {
      if (nodeVar[0].type !== variable.type) {
        setNodes((prevState) => {
          let nodos = [...prevState];

          nodos.map((node) => {
            let prevVar = node.data?.variable?.name;

            if (prevVar) {
              if (prevVar === nodeVar[0].name && node.type === "branch") {
                let index = nodos.findIndex(
                  (element) => element.id === node.id
                );

                if (index >= 0) {
                  nodos[index].data.variable = {
                    ...nodos[index].data.variable,
                    name: variable.name,
                    type: variable.type,
                  };
                  nodos[index].data.cases = [];
                  nodos[index].data.handleCount = 0;
                }

                setEdges((prevState) => {
                  let filtro = prevState.filter(
                    (element) => element.source !== node.id
                  );
                  return filtro;
                });
              }
            }
          });

          return nodos;
        });
      }
    }

    let tmpVariable = {
      idNodo: id,
      name: variable.name,
      type: variable.type,
    };

    let mismoNombre = array.filter((element) => element.name === variable.name);

    if (mismoNombre.length > 0) {
      flag = true;
    }

    if (flag) {
      messageApi.error(
        "No se pudo guardar la variable, intente con otro nombre"
      );

      return prevState;
    } else {
      array.push(tmpVariable);

      return array;
    }
  });

  if (variable?.type === "geo") {
    let innerGeoVars = ["latitude", "longitude", "name", "address"];

    innerGeoVars = innerGeoVars.map((element) => {
      return {
        idNodo: id,
        name: `${variable.name}.${element}`,
        type: `geo_${element}`,
      };
    });

    setFlowVariables((prevState) => {
      let array = [...prevState, ...innerGeoVars];

      return array;
    });
  }

  return true;
};
