import { Empty, Pagination, Spin } from 'antd';
import LoadingIcon from '../../Loader/LoadingIcon';
import { CardBot } from './cardBot/CardBot';
import { useEffect, useState } from 'react';

export const FlowsMainCards = ({
  filteredFlows,
  setFlows,
  loading,
  setLoading,
  currentPage,
  setCurrentPage,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function getPageSize() {
    if (screenWidth > 1500) {
      return 15;
    } else if (screenWidth > 1300) {
      return 12;
    } else {
      return 9;
    }
  }

  // Función para obtener los flows de la página actual
  function getCurrentPageFlows() {
    const startIndex = (currentPage - 1) * getPageSize();
    const endIndex = startIndex + getPageSize();
    return filteredFlows.slice(startIndex, endIndex);
  }

  return (
    <div>
      <Spin spinning={loading} indicator={<LoadingIcon size="large" />}>
        {filteredFlows.length > 0 ? (
          <>
            <div className="cards-bots-container">
              {getCurrentPageFlows()?.map((flow) => (
                <CardBot
                  flow={flow}
                  setFlows={setFlows}
                  setLoading={setLoading}
                  key={flow?._id}
                />
              ))}
            </div>
          </>
        ) : (
          <Empty
            className="empty-bots-list"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Spin>

      {filteredFlows.length > 0 && (
        <Pagination
          className='bots-pagination'
          size='small'
          pageSize={getPageSize()}
          current={currentPage}
          total={filteredFlows.length}
          onChange={(page) => setCurrentPage(page)}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} de ${total} flows`
          }
        />
      )}
    </div>
  );
};
