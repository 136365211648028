import { Input, Tabs, Button, Spin, Popover, Select, Tag, Tooltip } from "antd";
import "./chat.css";
import "./responsive.css";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  LoadingOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  findTeam,
  setChatsData,
  updateNotSeenMessages,
} from "./utils/chatFunctions";

import { ExtrasColumn } from "./extrasColumn/ExtrasColumn";
import io from "socket.io-client";
import {
  closeChat,
  getAvailableAgents,
  getChats,
  getConversation,
  markMessageAsRead,
  takeNotAssigned,
  transferToBot,
  updateUserAssigned,
} from "../../../../helpers/fetchData/fetchChats";
import {
  formatChatData,
  updateLiveConversation,
  updateConversations,
  readLastMessage,
  isExpired,
} from "./utils/chatFunctions";
import { getConfig } from "../../../../helpers/fetchData/fetchConfig";
import { getTeams } from "../../../../helpers/fetchData/fetchTeams";
import { getContactName } from "../campaigns/sendTemplates/utils/getContactName";
import { IoChevronBack } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { handleConversationStage } from "./utils/handleConversationStage";
import { manageNotification } from "./utils/manageNotification";
import { BiConversation } from "react-icons/bi";
import { MyConversationsTab } from "./MyConversationsTab";
import { MdOutlineCropFree, MdOutlineWorkspaces } from "react-icons/md";
import { TeamsTab } from "./TeamsTab";
import { ContactsTab } from "./ContactsTab";
import { NotAssignedTab } from "./NotAssignedTab";
import { PiAddressBookBold } from "react-icons/pi";
import { Interactions } from "./Interactions";
import { getTickets } from "../../../../helpers/fetchData/fetchTickets";
import { getClosingTypes } from "../../../../helpers/fetchData/fetchClosingTypes";
import { Tag as CustomTag } from "../../tag/Tag";
import { DownOutlined } from "@ant-design/icons";
import LoadingIcon from "../../Loader/LoadingIcon";
import { getFlows } from "../../../../helpers/fetchData/fetchFlows";
import { ImWhatsapp } from "react-icons/im";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: false,
  timeout: 20000,
  transports: ["polling", "websocket"],
});

export const Chat = () => {
  const {
    contactos,
    setContactos,
    isUserActive,
    messageApi,
    instance,
    application,
    usuarios,
    notificationApi,
  } = useContext(GlobalContext);

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [notAssignedTickets, setNotAssignedTickets] = useState([]);
  const [teams, setTeams] = useState([]);
  const [myTeams, setMyTeams] = useState([]);

  const [isNota, setIsNota] = useState(false);
  const [isConversationExpired, setIsConversationExpired] = useState(false);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [loadingChats, setLoadingChats] = useState(true);
  const [loadingCerrar, setLoadingCerrar] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [loadingAsignar, setLoadingAsignar] = useState(false);
  const [selectedConversationData, setSelectedConversationData] =
    useState(null);
  const [loadingConversation, setLoadingConversation] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingAvailableAgents, setLoadingAvailableAgents] = useState(false);
  const [availableAgents, setAvailableAgents] = useState([]);

  const [liveConversation, setLiveConversation] = useState([]);

  const [defaultTemplate, setDefaultTemplate] = useState(null);

  const [activeTabKey, setActiveTabKey] = useState("conversations");

  const [busqueda, setBusqueda] = useState("");

  const [showReasignar, setShowReasignar] = useState(false);
  const [selectedReasignar, setSelectedReasignar] = useState(null);
  const [loadingReasignar, setLoadingReasignar] = useState(false);

  const [showCerrarTicket, setShowCerrarTicket] = useState(false);

  const [ticketTypes, setTicketTypes] = useState([]);
  const [loadingTicketTypes, setLoadingTicketTypes] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState(null);

  const [closingTypes, setClosingTypes] = useState([]);
  const [loadingClosingTypes, setLoadingClosingTypes] = useState(false);
  const [selectedClosingType, setSelectedClosingType] = useState(null);

  const [showDerivar, setShowDerivar] = useState(false);
  const [flows, setFlows] = useState([]);
  const [selectedDerivar, setSelectedDerivar] = useState(null);
  const [loadingDerivar, setLoadingDerivar] = useState(false);
  const [loadingFlows, setLoadingFlows] = useState(false);

  const handleReasignar = () => {
    if (!selectedConversation?._id) {
      messageApi.info("Es necesario que seleccione un ticket");
      return;
    }

    if (!selectedReasignar) {
      messageApi.info("Es necesario que seleccione un agente");
      return;
    }

    const data = {
      chatId: selectedConversation?._id,
      userId: selectedReasignar,
    };

    setLoadingReasignar(true);
    updateUserAssigned(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          setConversations((prevState) => {
            return prevState.filter((chat) => chat._id !== data.chatId);
          });

          setSelectedConversation(null);
          setSelectedContact(null);

          setShowReasignar(false);
          messageApi.success("Ticket reasignado correctamente");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoadingReasignar(false));
  };

  const handleCancelarCerrar = () => {
    setShowCerrarTicket(false);
    setSelectedClosingType(null);
    setSelectedTicketType(null);
  };

  const handleCerrar = () => {
    if (selectedConversation?._id) {
      if (!selectedTicketType) {
        messageApi.info("Seleccione un tipo de ticket");
        return;
      }
      if (!selectedClosingType) {
        messageApi.info("Seleccione un tipo de cierre");
        return;
      }

      const data = {
        chatId: selectedConversation?._id,
        ticketTypeId: selectedTicketType,
        closingTypeId: selectedClosingType,
      };

      setLoadingCerrar(true);
      closeChat(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            setConversations((prevState) => {
              let array = [...prevState];

              array = array.filter(
                (chat) => chat._id !== selectedConversation?._id
              );

              return array;
            });
            setShowCerrarTicket(false);
            setSelectedConversation(null);
            setSelectedContact(null);
            setSelectedTicketType(null);
            setSelectedClosingType(null);

            messageApi.success("Ticket cerrado correctamente");
          } else {
            messageApi.error("Ocurrió un error al cerrar un ticket");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al cerrar un ticket");
        })
        .finally(() => setLoadingCerrar(false));
    }
  };

  const handleAsignar = () => {
    setLoadingAsignar(true);
    const data = {
      userId: instance.userData?.userId,
      chatId: selectedConversation?._id,
    };

    takeNotAssigned(instance.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200 && res.data?._id) {
          messageApi.success("Ticket asignado correctamente");

          //! Mover a funcion
          let contactName = getContactName(
            contactos?.find(
              (contacto) => contacto.phones?.[0]?.phone === res.data.phoneNumber
            )?.name || {}
          );

          const obj = {
            ...res.data,
            ...data,
            name: contactName,
            conversation:
              res.data.conversation || selectedConversation?.conversation || [],
          };

          setConversations((prevState) => {
            let array = [...prevState];

            array.unshift(obj);

            return array;
          });

          setSelectedConversation(obj);

          setNotAssignedTickets((prevState) => {
            return prevState.filter((element) => element._id !== res.data._id);
          });

          setActiveTabKey("conversations");
        } else {
          messageApi.error("Ocurrió un error al asignar un ticket");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al asignar un ticket");
      })
      .finally(() => setLoadingAsignar(false));
  };

  const chatFormattedData = useMemo(() => {
    return formatChatData(selectedConversationData) || [];
  }, [selectedConversationData]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTicketTypes(true);

      getTickets(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            setTicketTypes(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
          messageApi.error("Error al cargar la tabla de tipos de ticket");
        })
        .finally(() => setLoadingTicketTypes(false));

      setLoadingClosingTypes(true);
      getClosingTypes(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            setClosingTypes(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
          messageApi.error("Error al cargar la tabla de tipos de ticket");
        })
        .finally(() => setLoadingClosingTypes(false));

      getFlows(setFlows, messageApi, setLoadingFlows, instance);
    }
  }, [instance]);

  useEffect(() => {
    readLastMessage(chatFormattedData, application);
  }, [chatFormattedData, application]);

  useEffect(() => {
    setSelectedConversationData({});
    setIsConversationExpired(false);
    setIsNota(false);
    setLiveConversation([]);
  }, [selectedConversation]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      messageApi.success("Conexión establecida");
    }

    function onDisconnect(err) {
      setIsConnected(false);
      // messageApi.error("Se desconectó");
      console.log("error conexion", err);

      if (instance?.userData?.apiKey && application?.[0]) {
        socket.auth = {
          token: instance.userData.apiKey,
          instanceName: instance.name,
          metaToken: application[0].token,
          phoneNumberId: application[0].lines?.[0]?.phoneNumberId,
        };

        socket.connect();
      }
    }

    // socket.on escucha eventos
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  useEffect(() => {
    // Escucha cada notificación que llega.
    const readNotification = (notification) => {
      manageNotification(
        notification,
        selectedConversation,
        setNotAssignedTickets,
        setConversations,
        setLiveConversation,
        chatFormattedData,
        instance,
        setSelectedConversation,
        setSelectedContact,
        notificationApi,
        contactos
      );
    };

    socket.on("notification", readNotification);

    return () => {
      socket.off("notification", readNotification);
    };
  }, [selectedConversation, contactos, instance, chatFormattedData, contactos]);

  useEffect(() => {
    if (instance?.userData?.apiKey && application?.[0]) {
      socket.auth = {
        token: instance.userData.apiKey,
        instanceName: instance.name,
        metaToken: application[0].token,
        phoneNumberId: application[0].lines?.[0]?.phoneNumberId,
      };

      socket.connect();

      return () => {
        socket.disconnect();
      };
    }
  }, [instance, application]);

  useEffect(() => {
    if (
      (selectedConversation?.phoneNumber || selectedContact?.phoneNumber) &&
      instance?.userData?.apiKey
    ) {
      let phoneNumber =
        selectedConversation?.phoneNumber || selectedContact?.phoneNumber;

      setLoadingConversation(true);

      getConversation(instance.userData.apiKey, phoneNumber)
        .then((res) => {
          if (res?.status === 200) {
            setSelectedConversationData(res.data);
            updateNotSeenMessages(selectedConversation);
            setLiveConversation([]);
          } else {
            messageApi.error(
              "Ocurrió un error al obtener información de la conversación"
            );
            setSelectedConversationData({});
          }
        })
        .catch((err) => {
          console.log(err);
          messageApi.error(
            "Ocurrió un error al obtener información de la conversación"
          );
          setSelectedConversationData({});
        })
        .finally(() => setLoadingConversation(false));
    } else {
      setSelectedConversationData({});
    }
  }, [selectedConversation, selectedContact, instance]);

  useEffect(() => {
    if (instance?.userData?.apiKey && isConnected) {
      if (conversations.length < 1) {
        setLoadingChats(true);
        getChats(instance.userData.apiKey)
          .then((res) => {
            if (res?.status === 200) {
              setChatsData(res.data, setConversations, setNotAssignedTickets);
            } else {
              messageApi.error("No se pudo obtener conversaciones");
            }
          })
          .catch((err) => {
            console.log(err);
            messageApi.error("No se pudo obtener conversaciones");
          })
          .finally(() => setLoadingChats(false));

        setLoadingConfig(true);
        getConfig(instance.userData.apiKey)
          .then((res) => {
            if (res?.status === 200) {
              if (res.data?.config?.template) {
                setDefaultTemplate(res.data.config.template);
              }
            }
          })
          .catch(() =>
            messageApi.error("No se pudo obtener plantilla por defecto")
          )
          .finally(() => setLoadingConfig(false));
      }
    }
  }, [instance, isConnected]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTeams(true);
      getTeams(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res?.data)) {
              setTeams(res.data);

              const array = [];

              res.data.forEach((team) => {
                if (team.users.includes(instance.userData.userId)) {
                  array.push({ _id: team._id, name: team.name });
                }
              });

              setMyTeams(array);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener equipos");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener equipos")
        )
        .finally(() => setLoadingTeams(false));
    }
  }, [instance]);

  useEffect(() => {
    // Escucha cada mensaje que llega.
    const receiveMessage = (message) => {
      message = message?.message;

      if (!loadingConversation) {
        if (selectedConversation?._id === message?.chatId) {
          updateLiveConversation(
            message,
            setLiveConversation,
            chatFormattedData?.messages
          );

          if (message.userType !== "agent" && message?.id) {
            markMessageAsRead(message?.id, application);
          }

          updateNotSeenMessages(selectedConversation);
        }
      }

      updateConversations(message, setConversations, setNotAssignedTickets);
    };

    socket.on("sendMessage", receiveMessage);

    return () => {
      socket.off("sendMessage", receiveMessage);
    };
  }, [
    selectedConversation,
    conversations,
    loadingConversation,
    chatFormattedData,
  ]);

  const conversationExpired = useMemo(() => {
    if (
      isExpired(
        chatFormattedData?.expirationTimestamp,
        selectedConversation,
        isConversationExpired
      )
    ) {
      return true;
    }

    return false;
  }, [selectedConversation, chatFormattedData, isConversationExpired]);

  const conversationStage = useMemo(() => {
    return handleConversationStage(
      selectedConversation,
      selectedContact,
      instance,
      isNota,
      conversationExpired,
      liveConversation,
      chatFormattedData
    );
  }, [
    selectedConversation,
    isNota,
    conversationExpired,
    liveConversation,
    instance,
    chatFormattedData,
    selectedConversationData,
    selectedContact,
  ]);

  useEffect(() => {
    // Al seleccionar un contacto busca si hay algun ticket abierto
    if (selectedContact?._id) {
      if (conversations.length > 0) {
        let ticket = conversations.find((element) => {
          if (
            element.phoneNumber === selectedContact.phoneNumber &&
            element.userId === instance?.userData?.userId
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (ticket) {
          ticket = {
            ...selectedContact,
            ...ticket,
            userId: instance?.userData?.userId,
          };
          setSelectedConversation(ticket);
          setActiveTabKey("conversations");
        } else {
          setSelectedConversation(null);
        }
      }
    }
  }, [selectedContact]);

  useEffect(() => {
    if (instance?.userData?.apiKey && showReasignar) {
      setLoadingAvailableAgents(true);
      getAvailableAgents(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setAvailableAgents(res.data);
          } else {
            messageApi.info("Ocurrió un error al obtener agentes disponibles");
            setAvailableAgents([]);
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.info("Ocurrió un error al obtener agentes disponibles");
          setAvailableAgents([]);
        })
        .finally(() => setLoadingAvailableAgents(false));
    }
  }, [instance, showReasignar]);

  useEffect(() => {
    setSelectedReasignar(null);
  }, [showReasignar]);

  const optionsFlows = useMemo(() => {
    if (flows?.length > 0) {
      return flows
        .filter((flow) => flow.type === "trigger")
        .map((flow) => ({
          label: (
            <span className="horizontal gap8">
              <ImWhatsapp
                style={{ color: "var(--whatsapp-color)" }}
                size={20}
              />
              <b>{flow.name}</b>
            </span>
          ),
          value: flow._id,
          cleanLabel: flow.name,
        }));
    }

    return [];
  }, [flows]);

  const handleDerivar = () => {
    if (!selectedConversation?._id) {
      messageApi.info("Es necesario que seleccione un ticket");
      return;
    }

    if (!selectedDerivar) {
      messageApi.info("Es necesario que seleccione un bot");
      return;
    }

    const data = {
      chatId: selectedConversation?._id,
      flowId: selectedDerivar,
    };

    setLoadingDerivar(true);
    transferToBot(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          setConversations((prevState) => {
            return prevState.filter((chat) => chat._id !== data.chatId);
          });

          setSelectedConversation(null);
          setSelectedContact(null);
          setSelectedDerivar(null);

          setShowDerivar(false);
          messageApi.success("Ticket derivado correctamente");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoadingDerivar(false));
  };

  if (!isConnected) {
    return (
      <span
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className="horizontal gap12">
          <b>Estableciendo conexión...</b>
          <LoadingIcon size="default" />
        </span>
      </span>
    );
  }

  return (
    <div className="main-wrapper" style={{ padding: "0px" }}>
      <span className="inactive-user-alert fade-in">
        {/* No tiene equipo */}
        {teams?.length > 0 && myTeams?.length < 1 && (
          <span className="chat-alert-item">
            <p>No perteneces a ningun grupo</p>
          </span>
        )}
        {/* Agente no disponible */}
        {!isUserActive && (
          <span className="chat-alert-item">
            <GoDotFill size={18} className="inactive-glow blink" />
            <p>No disponible</p>
          </span>
        )}
      </span>

      <div className="main-content">
        <div className="chats-list-column">
          <span
            className="horizontal"
            style={{
              padding: 8,
              height: "56px",
            }}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Buscar..."
              value={busqueda}
              onChange={(v) => setBusqueda(v.target.value)}
            />
          </span>
          <Tabs
            size="large"
            items={[
              {
                key: "conversations",
                label: (
                  <span>
                    <Tooltip title="Mis conversaciones">
                      <span>
                        <BiConversation size={20} />
                      </span>
                    </Tooltip>
                  </span>
                ),
                children: (
                  <MyConversationsTab
                    conversations={conversations}
                    selectedConversation={selectedConversation}
                    setSelectedConversation={setSelectedConversation}
                    teams={teams}
                    busqueda={busqueda}
                    loadingChats={loadingChats}
                  />
                ),
              },
              {
                key: "teams",
                label: (
                  <span>
                    <Tooltip title="Mis equipos">
                      <span>
                        <MdOutlineWorkspaces size={20} />
                      </span>
                    </Tooltip>
                  </span>
                ),
                children: (
                  <TeamsTab
                    conversations={conversations}
                    selectedConversation={selectedConversation}
                    setSelectedConversation={setSelectedConversation}
                    teams={teams}
                    busqueda={busqueda}
                    loadingChats={loadingChats}
                  />
                ),
              },
              {
                key: "contacts",
                label: (
                  <Tooltip title="Contactos">
                    <span>
                      <PiAddressBookBold size={20} />
                    </span>
                  </Tooltip>
                ),
                children: (
                  <ContactsTab
                    conversations={conversations}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    teams={teams}
                    busqueda={busqueda}
                    activeTabKey={activeTabKey}
                  />
                ),
              },
              {
                key: "notAssigned",
                label: (
                  <span>
                    <Tooltip title="Sin asignar">
                      <span>
                        <MdOutlineCropFree size={20} />
                      </span>
                    </Tooltip>
                  </span>
                ),
                children: (
                  <NotAssignedTab
                    data={notAssignedTickets}
                    selectedConversation={selectedConversation}
                    setSelectedConversation={setSelectedConversation}
                    busqueda={busqueda}
                    loadingChats={loadingChats}
                  />
                ),
              },
            ]}
            className="chat-tab-wrapper"
            activeKey={activeTabKey}
            onChange={(v) => {
              setActiveTabKey(v);
              setSelectedConversation(null);
              setSelectedContact(null);
            }}
            style={{ width: "100%" }}
          />
        </div>
        <div className="selected-chat-column">
          <span className="chat-contact-wrapper-title fade-in">
            {(selectedConversation?._id || selectedContact?._id) && (
              <span className="horizontal gap12" style={{ width: "100%" }}>
                <IoChevronBack
                  size={24}
                  color="#252525"
                  className="pointer"
                  onClick={() => {
                    setSelectedConversation(null);
                    setSelectedContact(null);
                  }}
                />
                <span className="columna" style={{ gap: "6px" }}>
                  <p className="chat-contact-name">
                    {selectedConversation?.name || selectedContact?.name}
                  </p>
                  <p
                    className="chat-contact-display-message"
                    id="header-numero-contacto"
                  >
                    {selectedConversation?.phoneNumber ||
                      selectedContact?.phoneNumber}
                  </p>
                </span>
                <span
                  className="horizontal"
                  style={{
                    marginLeft: "auto",
                    color: "#252525",
                    gap: "4px",
                    fontSize: "18px",
                  }}
                >
                  <span className="horizontal" id="opciones-chats">
                    {selectedConversation?.status === "OPEN" &&
                      conversationStage !== "notMine" && (
                        <Popover
                          open={showCerrarTicket}
                          content={
                            <span
                              className="columna gap16"
                              style={{ maxWidth: 280 }}
                            >
                              <span className="columna gap4">
                                <p>Tipo de ticket</p>
                                <Select
                                  options={ticketTypes?.map((item) => {
                                    return {
                                      label: (
                                        <CustomTag
                                          nombre={item.name}
                                          hex={item.color}
                                        />
                                      ),
                                      value: item._id,
                                    };
                                  })}
                                  value={selectedTicketType}
                                  onChange={(v) => setSelectedTicketType(v)}
                                  //loading={loadingTicketTypes}
                                  suffixIcon={
                                    loadingTicketTypes ? (
                                      <LoadingIcon size="small" />
                                    ) : (
                                      <DownOutlined />
                                    )
                                  }
                                  style={{ width: 250 }}
                                  placeholder="Seleccione un tipo de ticket"
                                />
                              </span>
                              <span className="columna gap4">
                                <p>Tipo de cierre</p>
                                <Select
                                  options={closingTypes?.map((item) => {
                                    return {
                                      label: (
                                        <CustomTag
                                          nombre={item.name}
                                          hex={item.color}
                                        />
                                      ),
                                      value: item._id,
                                    };
                                  })}
                                  value={selectedClosingType}
                                  onChange={(v) => setSelectedClosingType(v)}
                                  //loading={loadingTicketTypes}
                                  suffixIcon={
                                    loadingTicketTypes ? (
                                      <LoadingIcon size="small" />
                                    ) : (
                                      <DownOutlined />
                                    )
                                  }
                                  style={{ width: 250 }}
                                  placeholder="Seleccione un tipo de cierre"
                                />
                              </span>
                              <span className="horizontal">
                                <Button
                                  type="primary"
                                  className="btn-guardar"
                                  style={{ opacity: loadingCerrar ? 0.65 : 1 }}
                                  onClick={handleCerrar}
                                  // loading={loadingCerrar}
                                  icon={
                                    loadingCerrar ? (
                                      <LoadingIcon
                                        size="small"
                                        color="#FFFFFF"
                                      />
                                    ) : null
                                  }
                                >
                                  Confirmar
                                </Button>
                                <Button
                                  type="secondary"
                                  className="btn-cancelar"
                                  onClick={handleCancelarCerrar}
                                >
                                  Cancelar
                                </Button>
                              </span>
                            </span>
                          }
                        >
                          <Button
                            type="primary"
                            onClick={() => setShowCerrarTicket(true)}
                            className="horizontal gap0"
                          >
                            Cerrar ticket
                          </Button>
                        </Popover>
                      )}
                    {selectedConversation?.status?.toUpperCase() ===
                      "NOT ASSIGNED" && (
                      <Button
                        type="primary"
                        onClick={handleAsignar}
                        //loading={loadingAsignar}
                        icon={
                          loadingAsignar ? (
                            <LoadingIcon size="small" color="#FFFFFF" />
                          ) : null
                        }
                        style={{ opacity: loadingAsignar ? 0.65 : 1 }}
                        className="horizontal gap0"
                      >
                        Tomar
                      </Button>
                    )}
                    {selectedConversation?.userId ===
                      instance?.userData?.userId && (
                      <Popover
                        placement="bottomRight"
                        open={showReasignar}
                        content={
                          <div
                            className="columna gap16"
                            style={{ width: "100%" }}
                          >
                            <b>Reasignar este ticket a</b>
                            <Select
                              style={{ width: "100%", minWidth: 250 }}
                              value={selectedReasignar}
                              onChange={(v) => setSelectedReasignar(v)}
                              placeholder="Seleccione un agente"
                              options={usuarios
                                ?.filter(
                                  (user) =>
                                    user._id !== instance?.userData?.userId
                                )
                                ?.filter((user) => {
                                  return availableAgents?.includes(user._id);
                                })
                                ?.map((usuario) => {
                                  const teamName = findTeam(
                                    instance?.userData?.userId,
                                    teams
                                  );

                                  return {
                                    label: (
                                      <span className="horizontal gap8">
                                        {usuario.name}
                                        {teamName && (
                                          <Tag color="geekblue">{teamName}</Tag>
                                        )}
                                      </span>
                                    ),
                                    value: usuario._id,
                                  };
                                })}
                            />
                            <div
                              className="botones-wrapper-content gap8"
                              style={{ width: "100%" }}
                            >
                              <Button
                                type="primary"
                                className="btn-guardar"
                                onClick={handleReasignar}
                                //loading={loadingReasignar}
                                icon={
                                  loadingReasignar ? (
                                    <LoadingIcon size="small" color="#FFFFFF" />
                                  ) : null
                                }
                                style={{ opacity: loadingReasignar ? 0.65 : 1 }}
                              >
                                Confirmar
                              </Button>
                              <Button
                                className="btn-cancelar"
                                type="secondary"
                                onClick={() => setShowReasignar(false)}
                              >
                                Cancelar
                              </Button>
                            </div>
                          </div>
                        }
                      >
                        <Button onClick={() => setShowReasignar(true)}>
                          Reasignar
                        </Button>
                      </Popover>
                    )}

                    {selectedConversation?.userId ===
                      instance?.userData?.userId && (
                      <Popover
                        placement="bottomRight"
                        open={showDerivar}
                        content={
                          <div
                            className="columna gap16"
                            style={{ width: "100%" }}
                          >
                            <b>Derivar a bot</b>
                            <Select
                              showSearch
                              style={{ width: "100%", minWidth: 250 }}
                              value={selectedDerivar}
                              onChange={(v) => setSelectedDerivar(v)}
                              placeholder="Seleccione un bot"
                              options={optionsFlows}
                              filterOption={(input, option) =>
                                option.cleanLabel
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                            <div
                              className="botones-wrapper-content gap8"
                              style={{ width: "100%" }}
                            >
                              <Button
                                type="primary"
                                className="btn-guardar"
                                onClick={handleDerivar}
                                icon={
                                  loadingDerivar ? (
                                    <LoadingIcon size="small" color="#FFFFFF" />
                                  ) : null
                                }
                                style={{ opacity: loadingDerivar ? 0.65 : 1 }}
                              >
                                Confirmar
                              </Button>
                              <Button
                                className="btn-cancelar"
                                type="secondary"
                                onClick={() => setShowDerivar(false)}
                              >
                                Cancelar
                              </Button>
                            </div>
                          </div>
                        }
                      >
                        <Button
                          shape="circle"
                          icon={<MoreOutlined />}
                          onClick={() => setShowDerivar(true)}
                        />
                      </Popover>
                    )}
                  </span>
                </span>
              </span>
            )}
          </span>
          <Interactions
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            selectedContact={selectedContact}
            chatFormattedData={chatFormattedData}
            liveConversation={liveConversation}
            setLiveConversation={setLiveConversation}
            conversationStage={conversationStage}
            loadingConversation={loadingConversation}
            setConversations={setConversations}
            setActiveTabKey={setActiveTabKey}
            myTeams={myTeams}
            socket={socket}
            isNota={isNota}
            setIsNota={setIsNota}
            defaultTemplate={defaultTemplate}
            setNotAssignedTickets={setNotAssignedTickets}
            setIsConversationExpired={setIsConversationExpired}
            setSelectedConversationData={setSelectedConversationData}
            conversationExpired={conversationExpired}
            ticketTypes={ticketTypes}
            closingTypes={closingTypes}
          />
        </div>
        {/* <ExtrasColumn
            selectedConversation={selectedConversation}
            selectedContact={selectedContact}
            chatFormattedData={chatFormattedData}
            ticketTypes={ticketTypes}
            closingTypes={closingTypes}
            teams={teams}
          /> */}
        <ExtrasColumn
          chatFormattedData={chatFormattedData}
          selectedContact={selectedContact}
          selectedConversation={selectedConversation}
          ticketTypes={ticketTypes}
          closingTypes={closingTypes}
          loading={loadingConversation}
        />
      </div>
    </div>
  );
};
