import { Input } from 'antd';
import { useState } from 'react';

export const Buscador = ({
  element,
  data,
  setFilteredData,
  searchKey,
  maxWidth = 250
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filteredData = data.filter((item) =>
      item[searchKey].toLowerCase().includes(value)
    );

    setFilteredData(filteredData);
  };

  

  return (
    <Input.Search
      style={{ maxWidth }}
      placeholder={`Buscar ${element}...`}
      value={searchTerm}
      onChange={handleChange}
      allowClear
    />
  );
};
