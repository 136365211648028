import { Button, Form, Input, Select } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { BsSoundwave } from 'react-icons/bs';
import { updateNode } from '../utils/updateNode';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { BoxInfo } from '../../../box-info/BoxInfo';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import { agregarVariableWhatsapp } from '../../../../../helpers/utilidadesTemplates';
import { HeaderForm } from './componentsForm/HeaderForm';

export const CallFunctionForm = () => {
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    flowVariables,
    setFlowVariables,
    setEdges
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [nombreNodo, setNombreNodo] = useState('');

  const [variableSeleccionada, setVariableSeleccionada] = useState('');
  const [transcribeName, setTranscribeName] = useState('001');
  const [prompt, setPrompt] = useState('');

  const options = useMemo(() => {
    if (flowVariables?.length > 0) {
      let array = flowVariables?.filter((variable) => {
        if (
          variable.type === 'string' &&
          !variable?.name?.toLowerCase()?.includes('[idx]')
        ) {
          return true;
        } else {
          return false;
        }
      });

      return array.map((variable) => {
        return {
          value: variable.name,
          label: variable.name
        };
      });
    } else {
      return [];
    }
  }, [flowVariables]);

  const onVariableChange = (text) => {
    setVariableSeleccionada(text);

    let variableText;
    if (text) {
      variableText = text.substring(text.indexOf('_') + 1);
    } else {
      variableText = '';
    }

    setTranscribeName(variableText);
  };

  const onFinish = (values) => {
    values.label = nombreNodo;

    if (!variableSeleccionada) {
      messageApi.info('Debe seleccionar una variable');
      return;
    }

    if (!prompt || prompt?.trim()?.length < 1) {
      messageApi.info('Ingrese un texto');
      return;
    }

    values.variableAudio = variableSeleccionada;

    values.prompt = prompt;

    let variableText;

    if (!transcribeName) {
      variableText = `gpt_001`;
    } else {
      variableText = `gpt_${transcribeName}`;
    }

    values.variableText = variableText;

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      {
        name: variableText,
        type: 'string'
      },
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  const decorarTexto = (wrappingString, emoji, variable) => {
    let textArea = document.getElementById('promptText');

    if (textArea) {
      let s = textArea.selectionStart;
      let e = textArea.selectionEnd;

      let oldValue = textArea.value;

      if (
        wrappingString !== '' &&
        wrappingString !== '${' &&
        wrappingString !== 'emoji'
      ) {
        let newValue =
          oldValue.slice(0, s) +
          wrappingString +
          oldValue.slice(s, e) +
          wrappingString +
          oldValue.slice(e, oldValue.length);
        textArea.value = newValue;
      }

      if (wrappingString === '${') {
        textArea.value = agregarVariableWhatsapp(
          textArea.value,
          variable,
          'promptText'
        );
      }

      if (wrappingString === 'emoji') {
        textArea.value = oldValue + emoji.emoji;
      }

      setPrompt(textArea.value);
    }
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      setNombreNodo(nodoSeleccionado.data.label);

      let data = nodoSeleccionado.data;

      let variableText;
      if (data.variableText) {
        let variable = data.variableText;
        variableText = variable.substring(variable.indexOf('_') + 1);
      } else {
        variableText = '';
      }

      setTranscribeName(variableText);

      setVariableSeleccionada(data?.variableAudio || null);

      setPrompt(data?.prompt || '');
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formCallFunction'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<BsSoundwave size={32} />}
      />

      <Form.Item
        label={
          <span className='horizontal gap8'>
            <p>Instrucciones (Prompt)</p>
            <BoxInfo
              message={'Ingrese instrucciones de lo que necesite devolver'}
              hideMode
            />
          </span>
        }
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <span className='columna gap4'>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            value={prompt}
            onChange={(v) => setPrompt(v.target.value)}
            id='promptText'
          />
          <span>
            <BotonAgregarVariable
              nodeId={nodoSeleccionado.id}
              decorarTexto={decorarTexto}
            />
          </span>
        </span>
      </Form.Item>
      <Form.Item
        label={
          <span className='horizontal gap8'>
            <p>Seleccionar variable input</p>
            <BoxInfo message='Mensaje que recibirá Chat GPT' hideMode />
          </span>
        }
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select
          options={options}
          onChange={(v) => {
            onVariableChange(v);
          }}
          value={variableSeleccionada}
          showSearch
          filterOption={(input, option) =>
            (option.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        label={
          <span className='horizontal gap8'>
            <p>Nombre de la variable de salida</p>
            <BoxInfo
              message='Variable que contendrá la respuesta de Chat GPT'
              hideMode
            />
          </span>
        }
      >
        <Input addonBefore='gpt_' value={transcribeName} disabled={true} />
      </Form.Item>

      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
