import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Controls,
  useEdgesState,
  useNodesState,
  addEdge,
  useReactFlow
} from 'reactflow';
import { nodeTypes } from './nodeTypes';
import { Button, Drawer, Form, Input, Modal } from 'antd';
import { EnviosMasivosContext } from '../../../../context/EnviosMasivosContext';
import { TemplateForm } from './form/TemplateForm';
import { ButtonForm } from './form/ButtonForm';
import { GlobalContext } from '../../../../context/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { CronForm } from './form/CronForm';
import { getEnvioData } from './utils/getEnvioData';
import {
  newEnvioMasivo,
  updateEnvioMasivo
} from '../../../../../helpers/fetchData/fetchEnviosMasivos';
import { drawEnvioNodes } from './utils/drawEnvioNodes';
import LoadingIcon from '../../../Loader/LoadingIcon';
import { WarningModal } from '../../../modals/WarningModal';

export const EnviosBuilder = ({ edit = false }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const navigate = useNavigate();

  const location = useLocation();

  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const nextNodeId = useRef('3');
  const idHandle = useRef(null);

  const [mostrarDrawer, setMostrarDrawer] = useState(false);
  const [nodoSeleccionado, setNodoSeleccionado] = useState({});
  const [contactosSeleccionados, setContactosSeleccionados] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const [templateName, setTemplateName] = useState('');

  const [showConfirmar, setShowConfirmar] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onConnect = useCallback((params) => {
    let parametros = { ...params };

    setEdges((eds) => addEdge(parametros, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId, handleId }) => {
    connectingNodeId.current = nodeId;
    idHandle.current = handleId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = nextNodeId.current.toString();

        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX - left,
            y: event.clientY - top - 60
          }),
          type: 'button'
        };

        setNodes((nds) => nds.concat(newNode));

        let newEdge = {
          id,
          source: connectingNodeId.current,
          target: id
        };

        if (idHandle) {
          newEdge = {
            id,
            source: connectingNodeId.current,
            target: id,
            sourceHandle: idHandle.current
          };
        }

        setEdges((eds) => eds.concat(newEdge));

        setNodoSeleccionado(newNode);
        setMostrarDrawer(true);
      }
    },
    [screenToFlowPosition]
  );

  const initialNodes = [
    {
      id: '0',
      position: { x: 0, y: 0 },
      type: 'cron',
      data: { label: 'Frecuencia', deletable: false }
    },
    {
      id: '1',
      position: { x: 250, y: 0 },
      type: 'template',
      data: { label: 'Plantilla', deletable: false }
    }
  ];

  const initialEdges = [{ id: '1', source: '0', target: '1' }];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const handleFormContent = () => {
    const { type } = nodoSeleccionado;

    switch (type) {
      case 'template':
        return <TemplateForm edit={edit} />;
      case 'button':
        return <ButtonForm edit={edit} />;
      case 'cron':
        return <CronForm edit={edit} />;
      default:
        return <p>Tipo no soportado...</p>;
    }
  };

  const saveEnvio = (values) => {
    let name = values.name.trim() || '';

    if (name.length < 3) {
      messageApi.info('Ingrese un nombre con al menos 3 caracteres');
      return;
    }

    const { data, error } = getEnvioData(nodes, edges, messageApi, name);

    if (error) {
      messageApi.error('No se puede guardar el envío');
      return;
    }

    setLoading(true);
    if (!edit) {
      newEnvioMasivo(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Envío masivo guardado correctamente');
            setShowConfirmar(false);
            navigate('/mensajes/envios');
          } else {
            messageApi.error('Ocurrió un error al guardar el envio');
          }
        })
        .catch((error) =>
          messageApi.error('Ocurrió un error al guardar el envio')
        )
        .finally(() => setLoading(false));
    } else {
      delete data.contacts;

      const search = location.search;
      const original = new URLSearchParams(search).get('payload');

      if (original) {
        let envio = JSON.parse(Base64.decode(original));

        if (envio?._id) {
          updateEnvioMasivo(instance?.userData?.apiKey, envio._id, data)
            .then((res) => {
              if (res?.status === 200) {
                messageApi.success('Envío masivo guardado correctamente');
                setShowConfirmar(false);
                navigate('/mensajes/envios');
              } else {
                messageApi.error('Ocurrió un error al guardar el envio');
              }
            })
            .catch((error) => {
              messageApi.error('Ocurrió un error al guardar el envio');
            })
            .finally(() => setLoading(false));
        }
      }
    }
  };

  const onLoad = (reactFlowInstance) => {
    setTimeout(() => {
      reactFlowInstance.fitView({ padding: 0.5 });
    }, 200);
  };

  useEffect(() => {
    if (edit && instance?.userData?.apiKey) {
      try {
        const search = location.search;
        const data = new URLSearchParams(search).get('payload');

        if (data) {
          let envio = JSON.parse(Base64.decode(data));

          if (envio) {
            setTemplateName(envio.name);
            drawEnvioNodes(envio, setNodes, setEdges);
          }
        }
      } catch (error) {
        console.log(error);
        messageApi.error('No se pudo obtener data de la campaña');
      }
    }
  }, [instance]);

  useEffect(() => {
    if (Array.isArray(nodes)) {
      let number = Number(nodes[nodes.length - 1]?.id);
      number += 1;
      nextNodeId.current = number.toString();
    }
  }, [nodes]);

  function handleBackClick() {
    // setIsModalOpen(true);
    navigate('/mensajes/envios');
  }

  /* function handleModalOk() {
    setIsModalOpen(false);
    navigate('/mensajes/envios');
  } */

  return (
    <EnviosMasivosContext.Provider
      value={{
        setMostrarDrawer,
        nodoSeleccionado,
        setNodoSeleccionado,
        contactosSeleccionados,
        setContactosSeleccionados,
        nodes,
        setNodes,
        edges,
        setEdges
      }}
    >
      <div className='main-wrapper-flow' ref={reactFlowWrapper}>
        <div className='flow-header'>
          <span>
            <span>{templateName ? templateName : 'Envío masivo'}</span>
          </span>
          <div className='panel-flow'>
            <Button
              type='secondary'
              className='btn-cancelar'
              style={{ width: 'fit-content' }}
              onClick={handleBackClick}
            >
              Volver
            </Button>
            <Button
              type='primary'
              className='btn-agregar'
              onClick={() => {
                setLoading(false);
                setShowConfirmar(true);
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
        <Drawer open={mostrarDrawer} width={450} destroyOnClose={true}>
          {handleFormContent()}
        </Drawer>
        <Modal
          open={showConfirmar}
          footer={null}
          // width={showDataTable.visible ? "85%" : 500}
          width={450}
          destroyOnClose
          style={{ maxHeight: '80%', overflowY: 'auto', left: 35 }}
          closeIcon={false}
        >
          <Form
            form={form}
            name='send-template'
            layout='vertical'
            onFinish={(v) => saveEnvio(v)}
            requiredMark={false}
          >
            <Form.Item style={{ marginBottom: 8 }}>
              <b>Guardar envío masivo</b>
            </Form.Item>
            <Form.Item
              label='Nombre'
              name='name'
              rules={[{ required: true, message: 'Ingrese un nombre' }]}
              initialValue={edit && templateName ? templateName : ''}
            >
              <Input placeholder='Ingrese un nombre' />
            </Form.Item>
            <div className='horizontal'>
              <Button
                htmlType='submit'
                type='primary'
                className='btn-guardar'
                //loading={loading}
                icon={
                  loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null
                }
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                onClick={() => setShowConfirmar(false)}
                type='secondary'
                className='btn-cancelar'
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          nodesConnectable={true}
          fitView={true}
          onInit={onLoad}
          fitViewOptions={{ padding: 0.5 }}
          nodeTypes={nodeTypes}
          connectionLineType='bezier'
          deleteKeyCode={null}
        >
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>
      {/* <WarningModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onOk={handleModalOk}
      /> */}
    </EnviosMasivosContext.Provider>
  );
};
